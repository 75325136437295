import React, { useState, useEffect } from 'react';

import Shimmer from './Shimmer';

import { Container, CategoryItem } from './style';

import { toast } from 'react-toastify';
import TagsCategory from '../../../../models/tagsCategory';
import { listTagsCategories } from '../../../../services/TagsCategories';

interface IMenuCategories {
  onClick: (categoryId: string) => void;
  selectedCategory: string;
}

const MenuCategories: React.FC<IMenuCategories> = ({
  onClick,
  selectedCategory,
}) => {
  const [categories, setCategories] = useState([] as TagsCategory[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const categories = await listTagsCategories({ is_active: true });

        if (categories && categories.length) {
          setCategories(categories);
        }
      } catch (e) {
        toast.error('Erro ao carregar as trilhas disponíveis.');
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <Container>
      <h3>Categorias</h3>

      {isLoading ? (
        <Shimmer />
      ) : (
        <>
          {categories &&
            categories.map((category, index: number) => (
              <CategoryItem
                key={index}
                onClick={() => onClick(category.id)}
                className={`${selectedCategory === category.id && 'selected'}`}
              >
                {category.title}
              </CategoryItem>
            ))}
        </>
      )}
    </Container>
  );
};

export default MenuCategories;
