import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AiOutlineClockCircle, AiOutlineUser } from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { secondsToTimeText } from '../../helpers/time';
import ICourse from '../../models/course';
import Lesson from '../../models/lesson';
import Module from '../../models/module';
import CourseContent from '../../pages/Course/components/CourseContent';
import { CourseModules } from '../../pages/Course/components/CourseModules';
import RecommendedCourses from '../../pages/Course/components/RecommendedCourses';
import CourseContext from './Context';
import {
  BookmarkButton,
  CourseAuthor,
  CourseAuthorDurationAndBookmarkContainer,
  CourseContainer,
  CourseContentContainer,
  CourseDuration,
  CourseInfoAndProgress,
  CourseLowerContent,
  CourseModulesAndUserReviewContainer,
  CourseProgress,
  CourseProgressBarContainer,
  CourseProgressContainer,
  CourseTag,
  CourseTitle,
  LoadingContainer,
  AuthorAndCourseDuration,
  CourseExamButtonContainer,
  CourseExamButton,
  DepositionsContent,
  ModalContainer,
  FormWrapper,
  FormContainer,
  FormGrid,
  TextWrapper,
  FormFlex,
  TextContainer,
} from './styles';
import {
  getCourse as getCourseService,
  toogleBookmark as toogleBookmarkService,
  startCourse as startCourseService,
  finishCourse as finishCourseService,
  startPreview as startPreviewService,
  finishPreview as finishPreviewService,
  updateLessonProgress as updateLessonProgressService,
  updatePreviewProgress as updatePreviewProgressService,
} from 'services/course';
import {
  startModule as startModuleService,
  finishModule as finishModuleService,
} from 'services/module';
import {
  startLesson as startLessonService,
  finishLesson as finishLessonService,
} from '../../services/lesson';
import getErrorMessage from '../../helpers/get-error-message';
import { toast } from 'react-toastify';
import CheckIcon from 'assets/svgs/wavy-check.svg';
import { getExamAttemptData, getExamData } from 'services/exam';
import {
  CertificateData,
  createCertificate,
  getAllCertificates,
  getCourseApproval,
  setTrailCertificate,
  showCertificate,
} from 'services/certificate';
import CourseCertificate from 'components/CourseCertificate/indes';
import Dropdown from 'components/Dropdown';
import { Certificate } from 'crypto';
import { DefaultModal } from 'components/DefaultModal';
import { IoMdClose } from 'react-icons/io';
import { SubmitButton } from './components/CourseContent/components/Annotations/style';
import { InputContent } from 'components/Inputs/DefaultInput/style';
import DefaultInput from 'components/Inputs/DefaultInput/DefaultInput';
import { BiLeftArrowAlt } from 'react-icons/bi';
import checkEmptyString from 'helpers/check-empty-string';
import {
  acceptTerm,
  createAcceptTermForm,
  getAcceptTermById,
  getCheckAcceptTerm,
} from 'services/acceptTerms';
import Swal from 'sweetalert2';
import { LessonAnnotationsContainer } from './components/CourseContent/components/LessonAnnotations/styles';

export interface CourseExtendedWindow extends Window {
  actualCourseId: string;
  actualModuleId: string;
  actualLessonId: string;
  actualExamId: string;
}

declare let window: CourseExtendedWindow;

interface CourseParams {
  courseId: string;
  moduleId: string;
  lessonId: string;
}

interface CourseAndModuleExam {
  examId: string;
  isApproved: boolean;
}

interface AcceptanceTerm {
  id: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
}

const Course: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { courseId, moduleId, lessonId } = useParams<CourseParams>();

  const [userAnsweredTerm, setUserAnsweredTerm] = useState(false);
  const [companyDepartament, setCompanyDepartament] = useState('');
  const [bond, setBond] = useState('');
  const [indicationName, setIndicationName] = useState('');
  const [unit, setUnit] = useState('');
  const [relationship, setRelationship] = useState('');
  const [indicationCompany, setIndicationCompany] = useState('');
  const [position, setPosition] = useState('');
  const [description, setDescription] = useState('');
  const [term, setTerm] = useState<AcceptanceTerm>();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termIsChecked, setTermIsChecked] = useState('sim');
  const [course, setCourse] = useState({} as ICourse);
  const [showReviewBox, setShowReviewBox] = useState(
    !Object.keys(course.review || {}).length,
  );
  const [selectedLessonModule, setSelectedLessonModule] = useState(
    {} as Module,
  );
  const [selectedLesson, setSelectedLesson] = useState({} as Lesson);
  const [certificate, setCertificate] = useState<CertificateData | null>(null);
  const [validateCertificate, setValidateCertificate] = useState(false);
  const [moduleList, setModuleList] = useState<Module[]>([]);
  const [contentList, setContentList] = useState<Lesson[]>([]);

  const redirectURLOnOpen = useCallback(() => {
    if (!contentList.length || !moduleList.length) return;

    const firstNonWatchedContent =
      contentList.find(content => !content.alreadyFinished) || contentList[0];
    const selectedModule =
      moduleList.find(
        module => module.id === firstNonWatchedContent.courseId,
      ) || moduleList[0];

    history.push(
      `/course/${firstNonWatchedContent.courseId}/module/${firstNonWatchedContent.moduleId}/lesson/${firstNonWatchedContent.id}`,
    );

    setSelectedLessonModule(selectedModule);
    setSelectedLesson(firstNonWatchedContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList.length]);

  const tryToCreateCertificate = useCallback(async () => {
    let certificateData = null as CertificateData | null;
    try {
      certificateData = await setTrailCertificate(courseId);
      setCertificate(certificateData);
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      console.log(errorMessage);
    }
  }, [certificate, courseId, course]);

  const getCertificates = useCallback(async () => {
    const localCetificate = await getAllCertificates();
    const certificateOfCourse = localCetificate.filter(
      cert => cert.reference_id === courseId,
    );

    if (certificateOfCourse.length !== 0) {
      setCertificate(certificateOfCourse[0]);
    }
  }, [courseId]);

  const checkApproval = useCallback(async (courseId: string) => {
    const exams = await getCourseApproval(courseId);
    const isApproved = exams.some(exam => exam.isApproved);

    if (isApproved && !certificate) {
      tryToCreateCertificate();
      getCertificates();
    }
  }, [certificate, getCertificates, tryToCreateCertificate]);

  useEffect(() => {
    if (course.id) {
      checkApproval(course.id);
    }
    
    if (
      certificate === null &&
      course.alreadyFinished &&
      !course.exam.exam_id &&
      userAnsweredTerm
    ) {
      tryToCreateCertificate();
      getCertificates();
    }
  }, [
    certificate,
    course,
    getCertificates,
    tryToCreateCertificate,
    userAnsweredTerm,
    checkApproval
  ]);

  const getCourse = async () => {
    const localCourse = await getCourseService(courseId);

    setCourse(localCourse);
    setModuleList(localCourse.modules);
    setContentList(localCourse.modules.map(module => module.lessons).flat());
  };

  useEffect(() => {
    getCertificates();
  }, [getCertificates]);

  const pushLessonUrl = (
    courseId: string,
    moduleId?: string,
    lessonId?: string,
  ) => {
    const baseUrl = `/course/${courseId}`;
    history.push(`${baseUrl}/module/${moduleId}/lesson/${lessonId}`);
  };

  const startLesson = async (isPreview?: boolean) => {
    if (!!isPreview && !course.previewData) {
      await startPreviewService(courseId);
      return;
    }

    const localSelectedLesson = selectedLesson as Lesson;

    if (!selectedLessonModule.alreadyStarted) {
      try {
        selectedLessonModule.alreadyStarted = true;

        await startModuleService(
          localSelectedLesson.courseId,
          localSelectedLesson.moduleId,
        );
      } catch (error) {
        selectedLessonModule.alreadyStarted = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar o módulo. ' + errorMessage);
      }
    }

    if (!localSelectedLesson.alreadyStarted) {
      try {
        localSelectedLesson.alreadyStarted = true;
        await startLessonService(
          localSelectedLesson.courseId,
          localSelectedLesson.moduleId,
          localSelectedLesson.id,
        );
      } catch (error) {
        localSelectedLesson.alreadyStarted = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar a aula. ' + errorMessage);
      }
    }

    setCourse({ ...course });
  };

  const finishLesson = async (
    justModuleAndLesson?: boolean,
    isPreview?: boolean,
  ) => {
    if (!!isPreview && !(selectedLesson as Lesson).id) {
      await finishPreviewService(courseId);
      return;
    }

    const localSelectedLesson = selectedLesson as Lesson;

    if (!justModuleAndLesson) {
      if (!localSelectedLesson.alreadyFinished) {
        try {
          localSelectedLesson.alreadyFinished = true;
          await finishLessonService(
            localSelectedLesson.courseId,
            localSelectedLesson.moduleId,
            localSelectedLesson.id,
          );

          if (!selectedLesson.isLastLesson) {
            enableNextLesson();
          }
        } catch (error) {
          localSelectedLesson.alreadyFinished = false;
          const errorMessage = getErrorMessage(error);
          toast.error('Houve um erro ao finalizar a aula. ' + errorMessage);
        }
      }
    }

    if (
      selectedLessonModule &&
      !selectedLessonModule.alreadyFinished &&
      (selectedLessonModule.lessons || [])
        .filter(les => les.type !== 'CERTIFICATE')
        .every(lesson => (lesson as Lesson).alreadyFinished)
    ) {
      try {
        selectedLessonModule.alreadyFinished = true;
        await finishModuleService(
          localSelectedLesson.courseId || courseId,
          localSelectedLesson.moduleId,
        );

        enableNextLesson();
      } catch (error) {
        selectedLessonModule.alreadyFinished = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao finalizar o módulo. ' + errorMessage);
      }
    }

    if (
      !course.alreadyFinished &&
      course.modules.every(module => module.alreadyFinished)
    ) {
      try {
        course.alreadyFinished = true;

        await finishCourseService(localSelectedLesson.courseId);
      } catch (error) {
        course.alreadyFinished = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao finalizar o curso. ' + errorMessage);
      }
    }

    setCourse({ ...course });
  };

  const findActualLesson = (lessons: Lesson[]): Lesson => {
    const foundLesson = lessons.find(
      lesson =>
        lesson.courseId === courseId &&
        lesson.moduleId === moduleId &&
        lesson.id === lessonId,
    )!;
    return foundLesson;
  };

  const getNextLesson = useCallback(
    (actualLesson?: Lesson) => {
      const allLessons =
        ((course.modules || []).map(module => module.lessons) || []).flat() ||
        [];

      let localSelectedLesson = {} as Lesson;

      if (!actualLesson) {
        localSelectedLesson = findActualLesson(allLessons);
      } else {
        localSelectedLesson = actualLesson;
      }

      const indexOfSelectedLesson = allLessons.indexOf(localSelectedLesson!);

      return allLessons[
        indexOfSelectedLesson === allLessons.length - 1
          ? 0
          : indexOfSelectedLesson + 1
      ];
    },
    [selectedLesson],
  );

  const enableNextLesson = () => {
    let nextLesson = undefined as Lesson | undefined;

    nextLesson = getNextLesson(nextLesson);
    nextLesson.isLocked = false;
  };

  const goToNextLesson = () => {
    const nextLesson = getNextLesson();
    const lesson = nextLesson as Lesson;
    pushLessonUrl(lesson.courseId, lesson.moduleId, lesson.id);
  };

  const toogleBookmark = async () => {
    try {
      await toogleBookmarkService(courseId, course.addedToList);
      course.addedToList = !course.addedToList;
      setCourse({ ...course });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(
        `Erro ao ${
          course.addedToList ? 'remover dos' : 'adicionar aos'
        } favoritos. ${errorMessage}`,
      );
    }
  };

  const changeLesson = () => {
    const localSelectedLessonModule = course.modules.find(
      module => module.id === moduleId,
    );
    if (
      localSelectedLessonModule &&
      localSelectedLessonModule.lessons &&
      localSelectedLessonModule.lessons.length
    ) {
      const localSelectedLesson = localSelectedLessonModule.lessons.find(
        lesson => lesson.id === lessonId,
      );
      if (localSelectedLesson) {
        setSelectedLessonModule(localSelectedLessonModule);
        setSelectedLesson(localSelectedLesson);
      }
    }
  };

  const startCourse = async () => {
    if (
      !course.alreadyStarted ||
      (!course.alreadyStarted && selectedLesson.type.toUpperCase() === 'SCORM')
    ) {
      try {
        await startCourseService(course.id);

        course.alreadyStarted = true;
        const firstLesson = ((course.modules || [])
          .map(module => module.lessons || [])
          .flat() || [])[0];
        firstLesson.isLocked = false;

        setCourse(c => ({ ...c }));
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar o curso. ' + errorMessage);
      }
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null, isPreview?: boolean) => {
      if (!isPreview) {
        await updateLessonProgressService(
          courseId,
          selectedLessonModule.id,
          (selectedLesson as Lesson).id,
          time,
        );

        return;
      }

      await updatePreviewProgressService(courseId, time);
    },
    [courseId, (selectedLesson as Lesson).id, selectedLessonModule.id],
  );

  const gotoExam = () => {
    history.push(`/exam/${course.exam.exam_id}/course/${course.id}`);
  };

  const hasExam = useMemo(() => {
    return !!course.exam?.exam_id && !!course.exam?.enabled ? true : false;
  }, [course]);

  useEffect(() => {
    if (
      course &&
      course.id &&
      (selectedLesson.moduleId !== moduleId || selectedLesson.id !== lessonId)
    ) {
      changeLesson();
    }
  }, [location.pathname, course]);

  useEffect(() => {
    window.actualCourseId = selectedLesson.courseId;
    window.actualModuleId = selectedLesson.moduleId;
    window.actualLessonId = selectedLesson.id;

    if (selectedLesson.id === 'ee53dcad-5123-4c6e-a373-c5cf3e397aca' && !selectedLesson.alreadyStarted) {
      startLesson().then(() => finishLesson());
    }

    if (
      selectedLesson.id === 'ee53dcad-5123-4c6e-a373-c5cf3e397aca'
      && selectedLesson.alreadyStarted
      && !selectedLesson.alreadyFinished
    ) {
      finishLesson();
    }
  }, [selectedLesson]);

  useEffect(() => {
    getCourse();
  }, [courseId]);

  useEffect(() => {
    redirectURLOnOpen();
  }, [redirectURLOnOpen]);

  const courseProgress = useMemo(() => {
    if (course && course.modules && course.modules.length) {
      const allLessons = course.modules
        .map(module => module.lessons || [])
        .flat();
      const completedLessons = allLessons.filter(
        lesson => lesson.alreadyFinished,
      );

      return (completedLessons.length * 100) / allLessons.length;
    }

    return 0;
  }, [course]);

  const handleAcceptTerm = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (course.info.term_id) {
        await acceptTerm(course.info.term_id, {
          trail_id: courseId,
          has_conflict: false,
        });
        setModalIsOpen(false);
        setUserAnsweredTerm(true);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Sua escolha será armazenada em nossos bancos de dados.',
        icon: 'success',
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: 'Houve um erro ao não aceitar o termo',
        text: errorMessage,
        icon: 'error',
      });
    }
  };

  const handleCreateAcceptTermForm = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(companyDepartament)) {
        throw new Error('Informe uma empresa/departamento válido.');
      }

      if (checkEmptyString(bond)) {
        throw new Error('Informe um vínculo válido.');
      }

      if (checkEmptyString(indicationName)) {
        throw new Error('Informe um nome válido.');
      }

      if (checkEmptyString(unit)) {
        throw new Error('Informe uma unidade válida.');
      }

      if (checkEmptyString(relationship)) {
        throw new Error('Informe um relacionamento válido.');
      }

      if (checkEmptyString(indicationCompany)) {
        throw new Error('Informe uma indicação válida.');
      }

      if (checkEmptyString(position)) {
        throw new Error('Informe uma cargo/posição válida.');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válido.');
      }

      await createAcceptTermForm({
        company_department: companyDepartament,
        indication_company: indicationCompany,
        indication_name: indicationName,
        bond,
        unit,
        relationship,
        position,
        description,
        date: new Date().toDateString(),
        has_sign: true,
      });

      if (course.info.term_id) {
        await acceptTerm(course.info.term_id, {
          trail_id: courseId,
          has_conflict: true,
        });

        setModalIsOpen(false);
        setUserAnsweredTerm(true);
      }

      Swal.fire({
        title: 'Sucesso!',
        text: 'Formulário preenchido com sucesso!',
        icon: 'success',
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: 'Houve um erro ao aceitar o termo.',
        text: errorMessage,
        icon: 'error',
      });
    }
  };

  const handleGetAcceptTermById = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (course.info?.term_id) {
      const response = await getAcceptTermById(course.info?.term_id);
      setTerm(response);
    }
  };

  useEffect(() => {
    handleGetAcceptTermById();
  }, [course]);

  const handleCheckAcceptTerm = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (course.alreadyFinished) {
      if (course.info?.term_id) {
        try {
          await getCheckAcceptTerm(courseId, course.info?.term_id);
          setModalIsOpen(false);
          setUserAnsweredTerm(true);
        } catch (err) {
          setModalIsOpen(true);
          setUserAnsweredTerm(false);
        }
      } else {
        setUserAnsweredTerm(true);
      }
    } else {
      setModalIsOpen(false);
    }
  };

  useEffect(() => {
    handleCheckAcceptTerm();
  }, [course]);

  return course && course.id ? (
    <CourseContext.Provider
      value={{
        course,
        setCourse,
        selectedLessonModule,
        selectedLesson,
        startCourse,
        startLesson,
        finishLesson,
        goToNextLesson,
        updateContentProgress,
      }}
    >
      <CourseContainer>
        <CourseContentContainer className="content">
          <CourseTag>
            {course.tags && course.tags.length ? course.tags.join(', ') : ''}
          </CourseTag>
          <CourseTitle>{course.title}</CourseTitle>
          <CourseInfoAndProgress>
            <CourseAuthorDurationAndBookmarkContainer>
              <AuthorAndCourseDuration>
                {course.authorName && (
                  <CourseAuthor>
                    <AiOutlineUser size={18} color="#0054A6" />
                    <span>Autor: {course.authorName}</span>
                  </CourseAuthor>
                )}

                <CourseDuration>
                  <AiOutlineClockCircle size={18} color="#0054A6" />
                  <span>
                    Duração: {secondsToTimeText(course.duration) || 'N/A'}
                  </span>
                </CourseDuration>
              </AuthorAndCourseDuration>
              <BookmarkButton
                onClick={toogleBookmark}
                className={course.addedToList ? 'added' : ''}
              >
                <BsBookmark size={16} />
                {course.addedToList ? 'Favoritado ' : 'Favoritar'}
              </BookmarkButton>
            </CourseAuthorDurationAndBookmarkContainer>
            {course.alreadyStarted && (
              <CourseProgressContainer>
                <strong>Progresso:</strong>
                <CourseProgressBarContainer>
                  <CourseProgress>
                    <span style={{ width: `${courseProgress}%` }}></span>
                  </CourseProgress>
                  <strong>{(courseProgress || 0).toFixed(2)}%</strong>
                </CourseProgressBarContainer>
              </CourseProgressContainer>
            )}
          </CourseInfoAndProgress>
          <CourseLowerContent>
            <CourseContent />
            <CourseModulesAndUserReviewContainer>
              <CourseModules />

              {hasExam && course.alreadyFinished && !certificate && (
                <Dropdown title={'Prova'}>
                  <CourseExamButtonContainer>
                    <CourseExamButton onClick={gotoExam}>
                      <span>Fazer Prova</span>
                    </CourseExamButton>
                  </CourseExamButtonContainer>
                </Dropdown>
              )}
              {certificate && userAnsweredTerm ? (
                <Dropdown title={'Certificado'}>
                  {' '}
                  <CourseCertificate course={course} />{' '}
                </Dropdown>
              ) : (
                <></>
              )}
              {course.alreadyFinished && showReviewBox && userAnsweredTerm && (
                <DepositionsContent>
                  <div>
                    <strong>Você concluiu:</strong>{' '}
                    <strong>{course.title}</strong>
                  </div>
                  <div>Seu depoimento é muito importante!</div>

                  <div className="buttons">
                    <button
                      type="button"
                      onClick={() =>
                        history.push('/depositions', {
                          type: 'trail',
                          id: course.id,
                          title: course.title,
                        })
                      }
                    >
                      <img src={CheckIcon} alt="Check" />
                      Enviar depoimento
                    </button>
                    <button onClick={() => setShowReviewBox(false)}>
                      Não quero avaliar
                    </button>
                  </div>
                </DepositionsContent>
              )}
            </CourseModulesAndUserReviewContainer>
          </CourseLowerContent>
          <RecommendedCourses />
        </CourseContentContainer>

        <DefaultModal
          modalIsOpen={modalIsOpen}
          setIsOpen={() => setModalIsOpen(true)}
          closeModal={() => setModalIsOpen(false)}
        >
          <ModalContainer termIsChecked={termIsChecked === 'sim'}>
            <TextContainer>
              <div className="modal-title">Termo de Ciência e Acordo</div>

              <div className="modal-description">{term?.description}</div>

              <div className="modal-question">
                Existe alguma situação que deva ser esclarecida em relação a
                conflitos de interesses ou validação do Departamento de
                Compliance?
              </div>

              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="letter"
                    value="c"
                    checked={termIsChecked === 'sim'}
                    onChange={() => setTermIsChecked('sim')}
                  />{' '}
                  Sim
                </label>

                <label>
                  <input
                    type="radio"
                    name="letter"
                    value="c"
                    checked={termIsChecked === 'não'}
                    onChange={() => setTermIsChecked('não')}
                  />{' '}
                  Não
                </label>
              </div>
            </TextContainer>

            {termIsChecked.match('sim') && (
              <FormContainer>
                <TextWrapper className="no-margin-top">
                  <h1 className="title">1.</h1>
                  <p className="description">
                    Esta declaração é voltada para os colaboradores que tenham
                    alguma situação que possa sugerir Conflito de Interesse com
                    os negócios da D'Or Consultoria, como também situações que
                    necessitem de validação do Departamento de Compliance, de
                    acordo com as regras dispostas neste Código.
                  </p>
                </TextWrapper>

                <FormFlex className="column">
                  <FormFlex>
                    <FormWrapper className="half">
                      <label>Empresa</label>
                      <input
                        placeholder="Insira a empresa ou departamento"
                        type="text"
                        value={companyDepartament}
                        onChange={e => setCompanyDepartament(e.target.value)}
                      />
                    </FormWrapper>

                    <FormWrapper className="half">
                      <label>Vínculo com a D'OR Consultoria</label>
                      <input
                        placeholder="Insira o vínculo com D'OR Consultoria"
                        type="text"
                        value={bond}
                        onChange={e => setBond(e.target.value)}
                      />
                    </FormWrapper>
                  </FormFlex>

                  <FormWrapper className="full">
                    <label>Cargo/Posição</label>
                    <input
                      placeholder="Insira o seu cargo ou posição"
                      type="text"
                      value={position}
                      onChange={e => setPosition(e.target.value)}
                    />
                  </FormWrapper>
                </FormFlex>

                <TextWrapper>
                  <h1 className="title">2.</h1>
                  <p className="description">
                    Indique pessoas com as quais possui estreito relacionamento
                    que sejam sócias, administradores, executivas,
                    negociadores,representantes comerciais ou que ocupem
                    posições com poder de decisão em empresa fornecedora,
                    prestadora de serviços, parceira ou concorrente da D'Or
                    Consultoria:
                  </p>
                </TextWrapper>

                <FormGrid>
                  <FormWrapper>
                    <label>Nome completo</label>
                    <input
                      placeholder="Insira o seu nome completo"
                      type="text"
                      value={indicationName}
                      onChange={e => setIndicationName(e.target.value)}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <label>Unidade</label>
                    <input
                      placeholder="Insira a sua unidade"
                      type="text"
                      value={unit}
                      onChange={e => setUnit(e.target.value)}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <label>Nível de relacionamento</label>
                    <input
                      placeholder="Insira o nível de relacionamento"
                      type="text"
                      value={relationship}
                      onChange={e => setRelationship(e.target.value)}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <label>Departamento</label>
                    <input
                      placeholder="Insira a sua empresa ou departamento"
                      type="text"
                      value={indicationCompany}
                      onChange={e => setIndicationCompany(e.target.value)}
                    />
                  </FormWrapper>
                </FormGrid>

                <TextWrapper>
                  <h1 className="title">3.</h1>
                  <p className="description">
                    Situação(ões) que necessite(m) de validação:
                  </p>
                </TextWrapper>

                <FormWrapper>
                  <label>Descrição da situação</label>
                  <textarea
                    placeholder="Insira a descrição da situação"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormWrapper>
              </FormContainer>
            )}

            <span className="buttons-wrapper">
              <SubmitButton
                onClick={() => history.push('/home')}
                className="back-button"
              >
                Voltar para home
              </SubmitButton>
              {termIsChecked.match('sim') ? (
                <SubmitButton
                  onClick={handleCreateAcceptTermForm}
                  className="submit-button"
                >
                  Aceito
                </SubmitButton>
              ) : (
                <SubmitButton
                  onClick={handleAcceptTerm}
                  className="submit-button"
                >
                  Aceito
                </SubmitButton>
              )}
            </span>
          </ModalContainer>
        </DefaultModal>
      </CourseContainer>
    </CourseContext.Provider>
  ) : (
    <LoadingContainer>
      <div className="spinner"></div>
    </LoadingContainer>
  );
};

export default Course;
