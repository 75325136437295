import axios from 'axios';
import { toast } from 'react-toastify';
import httpClient from '../../http-client';

interface IFormSignIn {
  email: string;
  password: string;
}

const signIn = async ({ email, password }: IFormSignIn): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/login-api`, {
        email,
        password,
      })
      .then(response => {
        window.localStorage.setItem(
          'dorconsultoria-api-token',
          response.data.api_token,
        );
        window.localStorage.setItem(
          'dorconsultoria-user',
          JSON.stringify(response.data.user),
        );
      });
    return Promise.resolve();
  } catch (err) {
    if (err.response.data.message === 'Incorrect username or password.') {
      toast.error(
        'O E-mail e senha informados estão incorretos ou não existem.',
      );
    } else {
      toast.error(err.response.data.message);
    }

    return Promise.reject();
  }
};

const login = async () => {
  let token = '';
  const tokens = getAccessToken();
  if (tokens) {
    const { access_token, id_token } = tokens;
    const response = (
      await httpClient.post('/cognito/login', { access_token, id_token })
    ).data;
    token = response.api_token;
    window.localStorage.setItem('dorconsultoria-api-token', token);
  }
};

const getAccessToken = () => {
  const urlHash = window.location.hash;
  const findAccessToken = urlHash.search('access_token=');
  const findIdToken = urlHash.search('&id_token=');
  const findTokenType = urlHash.search('&token_type=');
  if (!findAccessToken && !findIdToken && !findTokenType) {
    return;
  }
  const accessTokenTotalLength = findAccessToken + 13;
  const idTokenTotalLength = findIdToken + 10;
  const access_token = urlHash.substring(accessTokenTotalLength, findIdToken);
  const id_token = urlHash.substring(idTokenTotalLength, findTokenType);

  return { access_token, id_token };
};

export { signIn, login };
