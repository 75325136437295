import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;

  div.content {
    padding-left: 100px;
  }

  @media (max-width: 768px) {
    div.content {
      padding: 0 16px;
    }
  }
`;

export const MainHeader = styled.div`
  background-color: #fff;
  padding: 62px 0;

  @media (max-width: 768px) {
    padding: 32px 10px 0px;
  }
`;

export const Body = styled.div`
  margin-top: 4px;
  span {
    text-align: center;
    font-size: var(--font-large);
    color: var(--gray-darker);
  }
  @media (max-width: 768px) {
    margin-top: 40px;
    margin-left: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 44px;
  color: var(--primary-color);
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const SubTitle = styled.p`
  color: var(--gray-darker);
  font-size: 22px;
  margin-top: 24px;

  @media (max-width: 768px) {
    font-size: var(--font-default);
  }
`;
