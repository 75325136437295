import axios from 'axios';
import { toast } from 'react-toastify';

interface IFormSignUp {
  name: string;
  email: string;
  accessKey: string;
  password: string;
}

const signUp = async ({
  accessKey,
  email,
  name,
  password,
}: IFormSignUp): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/signup`, {
        name,
        email,
        password,
        access_key: accessKey,
      })
      .then(response => {});

    return Promise.resolve();
  } catch (err) {
    switch (err.response.data.message) {
      case 'Chave não encontrada':
        toast.error('Chave de Acesso inválida!');
        break;

      default:
        toast.error(err.response.data.message);
        break;
    }

    return Promise.reject();
  }
};

export { signUp };
