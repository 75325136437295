import styled from 'styled-components';

const RecommendedCoursesContainer = styled.div`
  width: calc(100vw - 40px);
  max-width: 1270px;
  margin-top: 60px;

  & .slick-slide {
    padding: 0 5px;
  }

  button.slick-arrow {
    width: 36px;
    height: 36px;
    top: -50px;

    &.slick-prev {
      right: 78px;
    }

    svg {
      width: 16px;

      polyline {
        stroke: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    /* min-width: 600px; */
  }
`;

const RecommendedCoursesTitle = styled.h3`
  font-size: 2em;
  margin: 16px 8px;

  ::after {
    content: '';
    border: solid 4px #0054A6;
    width: 100px;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RecommendedCoursesCardContainer = styled.div`
    min-width: 600px;
`

export { RecommendedCoursesContainer, RecommendedCoursesTitle, RecommendedCoursesCardContainer };
