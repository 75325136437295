import styled from 'styled-components';

export const Container = styled.div`
  max-width: 227px;
  height: 266px;
  background-color: var(--backgroundWhite);
  box-shadow: 1px 1px 14px 2px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0px;
  top: 30px;
  padding: 28px;
  z-index: 2;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
  }

  &::before {
    content: '';
    border-style: solid;
    border-color: #fff transparent;
    border-width: 0px 8px 8px 8px;
    position: absolute;
    top:0;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    z-index: 10;
  }

  h3 {
    margin-bottom: 20px;
    color: var(--primary-color);
  }

  p {
    margin-bottom: 30px;
    font-size: var(--font-medium);
    cursor: pointer;
    &:hover{
      color: var(--primary-color);
    }
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;
