import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiFillLike,
  AiOutlineUser,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import CourseImage from 'assets/banner-image.png';

import { toast } from 'react-toastify';

import {
  Container,
  Coursefooter,
  CourseTime,
  CourseLikes,
  BookmarkButton,
} from './style';
import { addContentBookmark, removeContentBookmark } from 'services/bookmark';
import { secondsToTimeText } from 'helpers/time';
import ProgressBarToCardItem from './ProgressBarToCardItem';
import Trail from 'models/course';

interface ICourseProps {
  course: Trail;
  onAddToList?: () => void;
  showProgressBar?: boolean;
}

const CourseCardItem: React.FC<ICourseProps> = ({
  course,
  onAddToList,
  showProgressBar = false,
}) => {
  const history = useHistory();
  const [addedToList, setAddedToList] = useState(course.addedToList);

  const goToCourse = () => {
    history.push(`/course/${course.id}`);
    window.scrollTo({
      top: 160,
      behavior: 'smooth',
    });
  };

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToList = !course.addedToList;

        if (shouldAddToList) {
          await addContentBookmark(course.id);
        } else {
          await removeContentBookmark(course.id);
        }

        course.addedToList = shouldAddToList;
        setAddedToList(course.addedToList);

        if (!!onAddToList) {
          onAddToList();
        }
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  return (
    <Container onClick={goToCourse}>
      <div>
        <img src={course.thumbnailUrl || CourseImage} alt="Course" />

        <BookmarkButton onClick={e => addToList(e)}>
          <BsBookmark
            size={24}
            color="#FFF"
            className={`${addedToList && 'favorite'}`}
          />
        </BookmarkButton>
      </div>

      <div className="text">
        <span className="category">{course.categoryName}</span>

        <h4>{course.title}</h4>

        {course.authorName && (
          <div className="align">
            <AiOutlineUser />
            <h3>Autor: {course.authorName}</h3>
          </div>
        )}

        <Coursefooter>
          <CourseTime>
            <AiOutlineClockCircle />
            <span>{`${secondsToTimeText(course.duration)}`}</span>
          </CourseTime>
          <span className="divider" />
          <CourseLikes>
            {course.userLiked ? (
              <AiFillLike color="#555" />
            ) : (
              <AiOutlineLike color="#555" />
            )}

            <span>
              {course.numberOfLikes &&
                `${course.numberOfLikes} ${
                  course.numberOfLikes === 1 ? 'curtida' : 'curtidas'
                }`}
            </span>
          </CourseLikes>
        </Coursefooter>
        {showProgressBar && (
          <ProgressBarToCardItem
            contentView={Number(course.progress ? course.progress : 0)}
            duration={Number(course.duration)}
          />
        )}
      </div>
    </Container>
  );
};

export default CourseCardItem;
