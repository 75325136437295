import Annotation from '../../models/annotation';
import AnnotationFromApi from '../../models/from-api/annotation';

const convertToAnnotation = (annotation: AnnotationFromApi): Annotation => {
  return {
    id: annotation.annotation_id,
    title: annotation.title,
    description: annotation.description,
    date: annotation.created_at ? new Date(annotation.created_at) : new Date(),
  };
};

export default convertToAnnotation;
