import React from 'react';
import Routes from '../../routes';
import { ContentContainer } from './style';

const Content: React.FC = () => {
  return (
    <ContentContainer>
      <Routes />
    </ContentContainer>
  );
};

export default Content;
