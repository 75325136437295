import React, { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import useSearch from 'hooks/useSearch';

import {
  Container,
  SearchResultTitle,
  SearchResultsList,
  ShimmerContent,
  SubtitleContainer,
} from './style';
import GridShimmer from 'components/GridShimmer';
import TrailCard from 'components/TrailCard';
import ImgResponsive from 'assets/svgs/MagnifyingGlass.svg';

const Search: React.FC = () => {
  const { searchResults, searchParam, isSearching, trails } = useSearch();

  const getAllResults = useMemo(() => {
    let trailsCount = 0;

    if (trails?.length) {
      trailsCount = trails.length;
    } else {
      trailsCount = 0;
    }

    return trailsCount;
  }, [trails]);

  return (
    <Container className="content">
      <SearchResultTitle>
        <FiSearch color="var(--secondary-color)" />
        <p>
          Sua busca por <span>{`"${searchParam}"`}</span>{' '}
          {getAllResults > 0
            ? `retornou ${getAllResults} resultado${
                getAllResults > 1 ? 's' : ''
              }:`
            : 'não retornou resultado.'}
        </p>
      </SearchResultTitle>

      {getAllResults === 0 && (
        <SubtitleContainer>
          <span className="subtitle">
            Verifique a ortografia ou tente palavras-chave diferentes.
          </span>

          <img src={ImgResponsive} alt="search" />
        </SubtitleContainer>
      )}

      {isSearching ? (
        <ShimmerContent>
          <GridShimmer />
        </ShimmerContent>
      ) : (
        <>
          {searchResults ? (
            <SearchResultsList>
              <div className="list">
                {trails &&
                  trails.map(trail => (
                    <TrailCard key={trail.id} trail={trail} />
                  ))}
              </div>
            </SearchResultsList>
          ) : (
            <div />
          )}
        </>
      )}
    </Container>
  );
};

export default Search;
