import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Container, ProfileLinks, ExitButton } from './style';

const ProfileDropdown: React.FC = () => {
  const history = useHistory();

  const logout = () => {
    window.localStorage.removeItem('dorconsultoria-api-token');
    window.localStorage.removeItem('dorconsultoria-user');
    window.localStorage.removeItem('dorconsultoria-isSSO');
    history.push('/');
    document.location.reload();
  };

  return (
    <Container>
      <ProfileLinks
        to={{
          pathname: '/profile',
          state: {
            menuData: false,
          },
        }}
      >
        Meus dados
      </ProfileLinks>

      <ProfileLinks to="/ongoing-courses">Em andamento</ProfileLinks>

      <ProfileLinks to="/favorite-courses">Favoritos</ProfileLinks>

      <ProfileLinks to="/certificates">Meus Certificados</ProfileLinks>

      <ExitButton type="button" onClick={logout}>
        <FiLogOut size={8} color="#1565C0" /> Sair
      </ExitButton>
    </Container>
  );
};

export default ProfileDropdown;
