import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { FiPlay } from 'react-icons/fi';

import {
  CourseModuleContainer,
  CourseModuleUpperContent,
  ModuleTitle,
} from './styles';
interface dropdowninterface {
  title: string;
}

const Dropdown: React.FC<dropdowninterface> = ({ title, children }) => {
  const [opened, setOpened] = useState(false);

  const toogleOpened = () => {
    setOpened(!opened);
  };

  return (
    <CourseModuleContainer>
      <CourseModuleUpperContent
        onClick={toogleOpened}
        className={opened ? 'opened' : 'closed'}
      >
        <ModuleTitle>{title}</ModuleTitle>
        {!opened && <AiOutlineDown />}
      </CourseModuleUpperContent>
      {opened && children}
    </CourseModuleContainer>
  );
};

export default Dropdown;
