import React from 'react';
import Footer from '../../Footer';

import { Container } from '../../Container';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <Container>
      {children}
      <Footer />
    </Container>
  );
};

export default AuthLayout;
