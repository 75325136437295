import styled from 'styled-components';

const PlayScormContainer = styled.div`
  width: 100%;
  height: 495px;
  position: relative;
  .iframe-scorm {
    width: 100%;
    height: 495px;
  }
`;

export { PlayScormContainer };
