import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 260px;
  height: 100%;
  flex-direction: column;
  margin: 0 32px 50px 0;

  .image {
    width: 100%;
    height: 140px;
    margin-bottom: 8px;
  }

  .title {
    width: 240px;
    height: 32px;
    margin-bottom: 8px;
    border-radius: 2px;
  }

  .info {
    width: 180px;
    height: 20px;
    border-radius: 2px;
  }
`;
