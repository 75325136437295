import LessonFromApi from '../../models/from-api/lesson';
import LessonUserFromApi from '../../models/from-api/lesson-user';
import ModuleFromApi from '../../models/from-api/module';
import ModuleUserFromApi from '../../models/from-api/module-user';
import Module from '../../models/module';
import convertToLesson from './convert-lesson';

const getContentUser = (
  content: LessonFromApi,
  moduleUser?: ModuleUserFromApi,
): LessonUserFromApi => {
  if (moduleUser && moduleUser.contents && moduleUser.contents.length) {
    const lessonUser = moduleUser.contents.find(
      modUser => modUser.content_id === content.content_id,
    );
    if (lessonUser) {
      return lessonUser;
    }
  }

  return {} as LessonUserFromApi;
};

const convertToModule = (
  module: ModuleFromApi,
  courseId: string,
  isFirst: boolean,
  moduleUser?: ModuleUserFromApi,
  isLastModule?: boolean,
): Module => {
  return {
    id: module.course_id,
    number: module.position,
    description: module.description,
    position: module.position,
    title: module.title,
    alreadyStarted: !!moduleUser && !!moduleUser.start_at,
    alreadyFinished: !!moduleUser && !!moduleUser.finish_at,
    lessons:
      module.contents && module.contents.length
        ? module.contents
            .sort((contentA, contentB) =>
              contentA.position > contentB.position ? 1 : -1,
            )
            .map((content, index) =>
              convertToLesson(
                courseId,
                module.course_id,
                content,
                index === 0,
                index === module.contents.length - 1,
                getContentUser(content, moduleUser),
                isLastModule,
              ),
            )
        : [],
    isFirstOfCourse: isFirst,
    exam: module.exam_id
  };
};

export default convertToModule;
