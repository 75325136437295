import CourseFromApi from '../../models/from-api/course';
import Course from '../../models/course';
import CourseUserFromApi from '../../models/from-api/course-user';
import convertToModule from './convert-module';
import ModuleUserFromApi from '../../models/from-api/module-user';
import Module from '../../models/module';
import ModuleFromApi from '../../models/from-api/module';
import { getExamData } from 'services/exam';
import ExamFromApi from 'models/from-api/exam';
import { userInfo } from 'os';

const getModuleUser = (
  module: ModuleFromApi,
  courseUser?: CourseUserFromApi,
): ModuleUserFromApi => {
  if (courseUser && courseUser.courses && courseUser.courses.length) {
    const moduleUser = courseUser.courses
      .filter(c => !!c)
      .find(modUser => modUser.course_id === module.course_id);
    if (moduleUser) {
      return moduleUser;
    }
  }

  return {} as ModuleUserFromApi;
};

const convertToCourse = async (
  course: CourseFromApi,
  courseUser?: CourseUserFromApi,
): Promise<Course> => {
  return {
    id: course.trail_id,
    thumbnailUrl: course.thumbnail,
    title: course.name,
    description: course.description,
    addedToList: course.book_mark,
    alreadyStarted: !!courseUser && !!courseUser.start_at,
    alreadyFinished: !!courseUser && !!courseUser.finish_at,
    userRate:
      !!courseUser && !!courseUser.review ? courseUser.review.stars : undefined,
    tags: course.tags,
    isActive: course.is_active,
    exam: course.exam ? await getExamData(course.exam) : ({} as ExamFromApi),
    duration: +course.duration,
    authorName:
      course.author && course.author.length
        ? course.author.map(a => a.name).join(', ')
        : '',
    categoryId: course.category ? course.category.id : course.category_id || '',
    categoryName: course.category
      ? course.category.title
      : course.category_name,
    modules:
      course.courses && course.courses.length
        ? course.courses
            .sort((courseA, courseB) =>
              courseA.position > courseB.position ? 1 : -1,
            )
            .map((module, index) =>
              convertToModule(
                module,
                course.trail_id,
                index === 0,
                getModuleUser(module, courseUser),
                module.course_id === (course.courses || [{}])[0].course_id,
              ),
            )
        : ([] as Module[]),
    referenceUrl: course.video_reference || undefined,
    previewData: course.video_preview
      ? {
          contentView: course.video_preview.content_view,
          started: !!course.video_preview.start_at,
          finished: !!course.video_preview.finish_at,
        }
      : { contentView: 0, started: false, finished: false },
    average: course.review_average,
    progress: +(course.progress * 100).toFixed(2),
    creationDate: course.created_at ? new Date(course.created_at) : new Date(),
    introImage: course.info ? course.info.intro_image : undefined,
    info: course.info,
    userLiked: course.likes?.user_like,
    numberOfLikes: course.likes?.count || course.count_likes || 0,
    isHighlighted: course.info?.is_highlighted || false,
    isRecommended: course.info?.is_recommended || false,
    review: courseUser?.review,
  };
};

export default convertToCourse;
