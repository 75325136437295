import styled from 'styled-components';

const CourseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PlayerContainer = styled.div`
  width: 100%;
  height: 495px;
  background: #333;

  @media (max-width: 1200px) {
    height: 100%;
  }

  @media (max-width: 960px) {
    height: 100%;
  }

  @media (max-width: 768px) {
    height: 100%;
  }

  @media (max-width: 550px) {
    height: 100%;
  }
`;

const CourseTabsAndRating = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px var(--border-gray);

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const CourseTabsHeaders = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 10px;
`;

const CourseTabsContent = styled.div`
  min-height: 80px;
`;

const CourseTabHeaderContainer = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 1em;
  cursor: pointer;
  padding: 10px 25px;
  border-bottom: solid 3px transparent;

  &.selected {
    border-bottom: solid 3px #0054a6;
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }
`;

const CourseLikeContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > span {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: var(--font-regular);
    color: var(--regular-gray);

    button {
      cursor: pointer;
      border: none;
      background: none;
      display: flex;
      align-items: center;
    }
  }
`;

export {
  CourseContentContainer,
  PlayerContainer,
  CourseTabsAndRating,
  CourseTabsHeaders,
  CourseTabsContent,
  CourseTabHeaderContainer,
  CourseLikeContainer,
};
