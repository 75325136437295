import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import getErrorMessage from '../../../../../../helpers/get-error-message';
import CourseContext from '../../../../Context';
import {
  AnnotationActions,
  AnnotationCardContainer,
  AnnotationDate,
  AnnotationDescription,
  AnnotationTitle,
  AnnotationTitleAndActions,
  LessonAnnotationsContainer,
  LessonAnnotationsForm,
  LessonAnnotationsList,
} from './styles';
import {
  getAnnotations as getAnnotationsService,
  createAnnotation as createAnnotationService,
  updateAnnotation as updateAnnotationService,
  deleteAnnotation as deleteAnnotationService,
} from '../../../../../../services/annotations';
import Annotation from '../../../../../../models/annotation';
import { FiEdit } from 'react-icons/fi';
import { BsTrash } from 'react-icons/bs';
import checkEmptyString from '../../../../../../helpers/check-empty-string';

interface AnnotationCardProps {
  annotation: Annotation;
  onDelete: (annotationId: string) => Promise<void>;
  onEdit: (annotation: Annotation) => void;
}

const AnnotationCard: React.FC<AnnotationCardProps> = ({
  annotation,
  onDelete,
  onEdit,
}) => {
  return (
    <AnnotationCardContainer>
      <AnnotationTitleAndActions>
        <AnnotationTitle>{annotation.title}</AnnotationTitle>
        <AnnotationActions>
          <button onClick={() => onEdit(annotation)} type="button">
            <FiEdit size={18} color="var(--dark-gray)" />
          </button>
          <button onClick={() => onDelete(annotation.id!)} type="button">
            <BsTrash size={18} color="var(--dark-gray)" />
          </button>
        </AnnotationActions>
      </AnnotationTitleAndActions>
      <AnnotationDate>
        {new Intl.DateTimeFormat('pt-BR').format(annotation.date)}
      </AnnotationDate>
      <AnnotationDescription>{annotation.description}</AnnotationDescription>
    </AnnotationCardContainer>
  );
};

const LessonAnnotations: React.FC = () => {
  const { selectedLesson } = useContext(CourseContext);
  const [idOfEdittingAnnotation, setIdOfEdittingAnnotation] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [annotations, setAnnotations] = useState([] as Annotation[]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (idOfEdittingAnnotation) {
      updateAnnotation();
    } else {
      createAnnotation();
    }
  };

  const getAnnotations = async () => {
    const localAnnotations = await getAnnotationsService({
      trail_id: selectedLesson.courseId,
      content_id: selectedLesson.id,
    });
    setAnnotations(localAnnotations);
  };

  const createAnnotation = async () => {
    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a anotação!');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para a anotação!');
      }
      await createAnnotationService(
        { title, description },
        selectedLesson.courseId,
        selectedLesson.id,
      );
      toast.success('Anotação criada  com sucesso!');
      await getAnnotations();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const editAnnotation = (annotation: Annotation) => {
    setIdOfEdittingAnnotation(annotation.id!);
    setTitle(annotation.title);
    setDescription(annotation.description);
  };

  const cancelEdit = () => {
    setIdOfEdittingAnnotation('');
    setTitle('');
    setDescription('');
  };

  const updateAnnotation = async () => {
    try {
      if (checkEmptyString(title)) {
        throw new Error('Informe um título válido para a anotação!');
      }

      if (checkEmptyString(description)) {
        throw new Error('Informe uma descrição válida para a anotação!');
      }
      await updateAnnotationService(idOfEdittingAnnotation, {
        title,
        description,
      });
      cancelEdit();
      toast.success('Anotação editada  com sucesso!');
      await getAnnotations();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  const deleteAnnotation = async (annotationId: string) => {
    try {
      await deleteAnnotationService(annotationId);
      await getAnnotations();
      toast.success('Anotação removida com sucesso!');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error('Houve um erro ao excluir a anotação. ' + errorMessage);
    }
  };

  useEffect(() => {
    getAnnotations();
  }, [selectedLesson.courseId, selectedLesson.id]);

  return (
    <LessonAnnotationsContainer>
      <LessonAnnotationsForm onSubmit={handleSubmit}>
        <p>Crie uma anotação:</p>

        <input
          type="text"
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Titulo"
        />
        <textarea
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder="Escreva Aqui..."
        ></textarea>

        <div className="actions">
          {idOfEdittingAnnotation && (
            <button type="button" onClick={cancelEdit}>
              Cancelar
            </button>
          )}
          <button type="submit">
            {idOfEdittingAnnotation ? 'Editar' : 'Criar'} Nota
          </button>
        </div>
      </LessonAnnotationsForm>
      <LessonAnnotationsList>
        {!idOfEdittingAnnotation && annotations && annotations.length ? (
          annotations.map(ann => (
            <AnnotationCard
              key={ann.id}
              annotation={ann}
              onDelete={deleteAnnotation}
              onEdit={editAnnotation}
            />
          ))
        ) : (
          <></>
        )}
      </LessonAnnotationsList>
    </LessonAnnotationsContainer>
  );
};

export default LessonAnnotations;
