import React, { useState } from 'react';
import { AiOutlinePlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { Container, Content, Question, Answer } from './style';
import useWindowSize from 'hooks/useWindowSize';

interface QuestionAndAnswerProps {
  id: string;
  question: string;
  answer: string;
}

const QuestionAndAnswer: React.FC<QuestionAndAnswerProps> = ({
  id,
  question,
  answer,
}) => {
  const [collapse, setCollapse] = useState(false);

  const changeCollapse = () => {
    setCollapse(!collapse);
  };

  const widthPage: Array<number> = useWindowSize();
  const widthMobile = widthPage[0] <= 768;

  const colors = {
    blue: '#0054a6',
    yellow: '#ffab00',
  };

  return (
    <Container key={id}>
      {collapse ? (
        <AiFillMinusCircle
          onClick={changeCollapse}
          size={26}
          color={widthMobile ? `${colors.blue}` : `${colors.yellow}`}
        />
      ) : (
        <AiOutlinePlusCircle onClick={changeCollapse} size={26} />
      )}
      <Content>
        <Question>{question}</Question>
        {collapse ? <Answer>{answer}</Answer> : <></>}
      </Content>
    </Container>
  );
};

export default QuestionAndAnswer;
