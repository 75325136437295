import styled from 'styled-components';

export const Container = styled.div`
  div {
    width: 200px;
    height: 25px;
    border-radius: 6px;
    margin-top: 40px;
  }
`;
