import styled from 'styled-components';

const LoadingContainer = styled.div`
  width: 100%;
  overflow: hidden;

  & > div {
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--primary-color);
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }
`;

export { LoadingContainer };
