import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';

import { Container } from './style';

interface IBackButton {
  customFunc?: () => void;
}

const BackButton: React.FC<IBackButton> = ({ customFunc }) => {
  const history = useHistory();

  return (
    <Container
      onClick={() => {
        if (customFunc) {
          customFunc();
        } else {
          history.goBack();
          window.scrollTo(-100, 0);
        }
      }}
    >
      <IoArrowBackOutline size={14} color="#0054A6" />
      <span>Voltar</span>
    </Container>
  );
};

export default BackButton;
