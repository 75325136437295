import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  label {
    color: var(--regular-gray);
  }
  
  input[type='checkbox'] {
    width: 16px;
    height: 16px;
   }
`;
