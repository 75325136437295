import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CategoryCardContainer,
  CourseCategoriesContainer,
  CourseCategoriesContent,
} from './styles';
import Category from '../../models/category';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Slider from 'react-slick';
import { courseCategorySliderSettings } from '../../config/sliderSettings';
import { getCategories } from 'services/category';
import HeaderLogo from 'assets/logo.svg';
import ModalSkeleton from 'pages/Home/components/Skeleton/Cards';
import Loading from 'components/Loading';
import { toast } from 'react-toastify';

interface CategoryCardProps {
  category: Category;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
  const history = useHistory();
  const goToCategory = () => {
    history.push(`/courses/${category.id}`);
  };
  if (category.logo) {
    return (
      <CategoryCardContainer color={category.info.color} onClick={goToCategory}>
        <img src={category.logo} alt="Categorias" />
        <h3>{category.title}</h3>
      </CategoryCardContainer>
    );
  }
  return (
    <CategoryCardContainer color={category.info.color} onClick={goToCategory}>
      <img src={HeaderLogo} alt="Categorias" />
      <h3>{category.title}</h3>
    </CategoryCardContainer>
  );
};

const CourseCategories: React.FC = () => {
  const [categories, setCategories] = useState([] as Category[]);

  const isLoading = useMemo(() => {
    return (!categories || !categories.length);
  }, [categories]);

  useEffect(() => {
    (async () => {
      try {
        if(isLoading) {
          const categoryResponse = await getCategories();
          setCategories(categoryResponse);
        }
      } catch (e) {
        toast.error('Erro ao carregar as categorias disponíveis.');
      }
    })();
  }, [isLoading]);

  return !isLoading ? (
    <CourseCategoriesContainer>
      <div className="content">
        <h1>Todos os cursos</h1>
        <h2>Categorias</h2>

        <CourseCategoriesContent>
          {isLoading ? (
                <ModalSkeleton />
          ) : categories && categories.length ? (
            <Slider
              {...courseCategorySliderSettings}
              prevArrow={<IoIosArrowBack size={30} color="white" />}
              nextArrow={<IoIosArrowForward size={30} color="white" />}
            >
              {categories.map(cat => (
                <CategoryCard key={cat.id} category={cat} />
                ))
              }
            </Slider>
          ) : (
            <>Não foram encontradas categorias de curso!</>
          )}
        </CourseCategoriesContent>
      </div>
    </CourseCategoriesContainer>
  ) : (
    <Loading size={160} />
  );
};

export default CourseCategories;
