import styled from 'styled-components';

export const Container = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;

  div.return-btn {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    padding-top: 20px;
    padding-left: 20px;
  }

  div.container-btn {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 10px 0 15px 0;
  }

  > button {
    text-transform: uppercase;
    height: 64px;
    margin-bottom: 70px;
  }

  img {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h3 {
    color: var(--secondary-color);
    font-size: var(--font-largest);
  }

  p {
    color: var(--medium-gray);
    text-align: center;
    font-weight: 100;
    margin-top: 22px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 16px;

    h3 {
      text-align: center;
    }

    p {
      margin-bottom: 30px;
    }

    div.container-btn {
      flex-direction: column;
    }
    > button {
      height: 64px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0;
    }
  }
`;

export const SelectTypeButton = styled.div`
  width: 320px;
  height: 160px;
  display: flex;
  border-radius: 12px;
  text-align: center;
  align-items: center;
  font-size: var(--font-large-medium);
  text-transform: uppercase;
  font-weight: 700;
  color: var(--secondary-color);
  padding: 0 50px;
  justify-content: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
  @media (max-width: 768px) {
    max-width: 300px;
  }
`;
