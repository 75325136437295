import styled from 'styled-components';

const CourseModuleContainer = styled.div``;

const CourseModuleUpperContent = styled.div`
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  gap: 15px;

  &.opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 1.2em;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModuleTitle = styled.span`
  flex-grow: 1;
`;

export { CourseModuleContainer, CourseModuleUpperContent, ModuleTitle };
