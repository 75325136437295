import React, { useEffect, useState } from 'react';

import MenuCategories from './components/MenuTrilhas';
import TrailCard from 'components/TrailCard';
import PreFooter from 'components/PreFooter';

import { getCourses } from 'services/course';

import { Container, Content, TrailsContent, ButtonTrail } from './style';

import { toast } from 'react-toastify';
import GridShimmer from '../../components/GridShimmer';
import { BsChevronCompactDown } from 'react-icons/bs';

import categoryIcon from 'assets/svgs/categoryIcon.svg';

import DefaultDropdown from 'components/DefaultDropdown';
import Course from 'models/course';

const Trails: React.FC = () => {
  const [trails, setTrails] = useState([] as Course[]);
  const [currentCategoryId, setCurrentCategoryId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let categoryFilter = {};

        if (currentCategoryId && currentCategoryId !== '') {
          categoryFilter = { tag_category: currentCategoryId };
        }

        const trails = await getCourses(categoryFilter);
        if (trails) {
          setTrails(trails);
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos disponíveis.');
      }
      setIsLoading(false);
    })();
  }, [currentCategoryId]);

  return (
    <>
      <Container>
        <div className="content">
          <h2>Todas as Trilhas</h2>
          <div>
            <ButtonTrail onClick={() => setDropdown(value => !value)}>
              <img src={categoryIcon} alt="Icone de categoria" />
              Categorias
              <BsChevronCompactDown color={'#1565C0'} />
            </ButtonTrail>
            {dropdown && (
              <DefaultDropdown
                dropdown={dropdown}
                onClick={categoryId => setCurrentCategoryId(categoryId)}
                selectedCategory={currentCategoryId}
                onClose={() => setDropdown(false)}
              />
            )}
          </div>
          <Content>
            <MenuCategories
              onClick={categoryId => setCurrentCategoryId(categoryId)}
              selectedCategory={currentCategoryId}
            />

            <TrailsContent>
              {isLoading ? (
                <div>
                  <GridShimmer />
                </div>
              ) : (
                <>
                  {trails && trails.length ? (
                    trails.map(trail => (
                      <TrailCard key={trail.id} trail={trail} />
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontrados Cursos nessa categoria.
                    </h4>
                  )}
                </>
              )}
            </TrailsContent>
          </Content>
        </div>
      </Container>
      <PreFooter />
    </>
  );
};

export default Trails;
