import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;

  @media (max-width: 768px) {
    footer {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 80vw;
  margin: 0 auto;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: 80vw;
    height: calc(100vh - 100px);
    flex-direction: column;
    margin-bottom: 40px;
    overflow: hidden;
  }
`;

export const BrandContent = styled.div`
  width: 50%;
  height: 84%;

  img {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 36px;

    img {
      width: 100px;
      height: 100px;
      margin-bottom: -20px;
    }
  }
`;

export const OptionsContent = styled.div`
  height: 85%;
  width: 50%;
  flex-direction: column;
  margin-left: 80px;
  button {
    height: 5em;
    width: 70%;
    margin: 32px 0 16px 0;
  }
  p {
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    margin-left: -1%;
    a {
      text-decoration: none;
      color: var(--secondary-color);
      &:hover {
        color: var(--secondary-color-hover);
      }
    }
  }
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    margin-left: 0;
    button {
      height: 3em;
      width: 80%;
      margin-top: 16px;
    }
    p {
      padding: 0 16px;
      font-size: 16px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: var(--font-title);
  color: var(--primary-color);

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const Description = styled.h3`
  max-width: 390px;
  line-height: 28px;
  font-size: var(--font-default);
  font-weight: 100;
  color: var(--medium-gray);
  margin: 22px 0px 32px;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 0;
  }
`;
