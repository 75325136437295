import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import SliderNextButton from 'components/SliderButtons/SliderNextButton';
import SliderPrevButton from 'components/SliderButtons/SliderPrevButton';
import CourseCardItem from 'components/CourseCardItem';

import { coursesSliderSettings } from 'config/sliderSettings';

import { Container } from './style';

import { toast } from 'react-toastify';
import ModalSkeleton from '../Skeleton/Cards';
import { getCourses } from 'services/course';

const RecommendedCourses: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [recommendedCourses, setRecommendedCourses] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const recommendedCoursesFilters = {};

        const localCourses = await getCourses(recommendedCoursesFilters);

        if (localCourses && localCourses.length) {
          setRecommendedCourses(
            localCourses.filter(trail => trail.isRecommended),
          );
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos recentes.');
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    recommendedCourses && recommendedCourses.length ? (
      <Container>
        <h2>Cursos recomendados</h2>
        <Slider
          {...coursesSliderSettings}
          prevArrow={<SliderPrevButton />}
          nextArrow={<SliderNextButton />}
        >
          {isLoading ? (
            <ModalSkeleton />
          ) : ( 
            recommendedCourses
              .slice(0, 10)
              .map(course => <CourseCardItem key={course.id} course={course} />)
          )}
        </Slider>
      </Container>
    )
    : (
      <Container style={ { display: "none" } } />
    )
  );
};

export default RecommendedCourses;
