import React from 'react';
import CertificateSkeleton from '../../../../../../components/CertificateSkeleton';

import { Container } from './style';

const CertificatesShimmer: React.FC = () => {
  return (
    <Container>
      <CertificateSkeleton />
      <CertificateSkeleton />
      <CertificateSkeleton />
    </Container>
  );
};

export default CertificatesShimmer;
