import styled from 'styled-components';

export const Container = styled.form`
  width: 390px;
  height: 44px;
  display: flex;
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding-right: 18px;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: transparent;
    padding: 16px 20px;
    font-size: var(--font-large);
    font-weight: 400;
  }

  button {
    cursor: pointer;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    svg {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
    
  }
`;
