import { format } from 'date-fns';

const secondsToTimeText = (timeInSeconds: number): string => {
  const hours = (timeInSeconds / 3600).toFixed(0);
  const minutes = (+(timeInSeconds % 3600) / 60).toFixed(0);

  let timeText = '';
  if (+hours) {
    timeText += `${hours}h `;
  }

  if (+minutes) {
    timeText += `${minutes}min`;
  }

  return timeText || '0min';
};

const timestampToDate = (time: string): string => {
  const timeFormat = format(new Date(time), 'dd/MM/yyyy');

  return timeFormat;
};

export { secondsToTimeText, timestampToDate };
