import httpClient from '../../http-client';
import ExamFromApi from '../../models/from-api/exam';
import ExamAttemptFromApi from '../../models/from-api/exam-attempt';
import QuestionFromApi from '../../models/from-api/question';
import QuestionAnswerFromApi from '../../models/from-api/question-answer';

export const getExamData = async (examId: string): Promise<ExamFromApi> => {
  const { data } = await httpClient.get(`/exams/${examId}`);
  return data;
};

export const startExam = async (exam_id: string, courseId: string) => {
  const { data } = await httpClient.post('exam-users/start', {
    exam_id,
    trail_id: courseId,
  });

  return data;
};

export const getExamAttemptData = async (
  courseId: string,
  examId: string,
): Promise<ExamAttemptFromApi[]> => {
  const { data } = await httpClient.get(
    `/exam-users/${examId}/${courseId}/attempts`,
  );

  return data;
};

export const submitAnswer = async (
  exam_answer_user_id: string,
  answer_id: string,
) => {
  try {
    const { data } = await httpClient.put(`/exam-users/answer`, {
      exam_answer_user_id,
      answer_id,
    });

    return data;
  } catch (err) {}
};

export const getPreviousAnswers = async (exam_user_id: string) => {
  const { data: answers } = await httpClient.get(
    `exam-users/${exam_user_id}/answers`,
  );

  return answers;
};

export const getQuestion = async (questionId: string) => {
  const { data: question } = await httpClient.get(`/questions/${questionId}`);

  return question;
};

export const submitExam = async (exam_user_id: string) => {
  const { data } = await httpClient.put('exam-users/finish', {
    exam_user_id,
  });

  return data;
};

export const getQuestionWithCorrectAnswers = async (questionId: string) => {
  const { data } = await httpClient.get<QuestionFromApi>(
    `/questions/${questionId}/correct-alternative`,
  );

  return data;
};
