import { AiTwotoneStar } from 'react-icons/ai';
import StarRatingComponent from 'react-star-rating-component';

interface StarRatingComponentProps{
  rating: number
  handleChange: (value: number) => void;
}

const StarComponent = ({rating, handleChange}: StarRatingComponentProps) => {
  return (
    <>
        <StarRatingComponent
          name="Stars"
          starCount={5}
          value={rating}
          onStarClick={handleChange}
          starColor={'#fcaf17'}
          emptyStarColor={'#e1e3e6'}
          renderStarIcon={() => <AiTwotoneStar size={40}/>}
          editing={true}
        />
    </>
  )
}

export default StarComponent;

