import styled from 'styled-components';

const ModuleLessonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModuleLessonContainer = styled.div`
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &.locked {
    cursor: not-allowed;
  }

  display: flex;
  align-items: stretch;
`;

const LessonFlux = styled.div`
  padding: 0 5px;
  min-height: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const fluxStyle = `&.locked {
  color: var(--gray-darker);
  border: solid 1px var(--gray-darker);
}

&.unlocked {
  color: #0054A6;
  background: #1565C0aa;
}

&.finished {
  background: var(--third-color);
  color: white;
}`;

const LessonFluxCircle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${fluxStyle}
`;

const LessonFluxUpperBar = styled.span`
  border: solid 1px var(--gray-darker);
  flex-grow: 1;

  ${fluxStyle}

  &.first {
    visibility: hidden;
  }
`;

const LessonFluxLowerBar = styled.span`
  border: solid 1px var(--gray-darker);
  flex-grow: 1;

  ${fluxStyle}

  &.last {
    visibility: hidden;
  }
`;

const LessonContent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
`;

const LessonDuration = styled.div`
  background: var(--leve-primary);
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
`;

const LessonTitle = styled.p`
  &.selected {
    font-weight: bold;
  }
`;

export {
  ModuleLessonsContainer,
  ModuleLessonContainer,
  LessonFlux,
  LessonContent,
  LessonDuration,
  LessonTitle,
  LessonFluxCircle,
  LessonFluxLowerBar,
  LessonFluxUpperBar,
};
