import httpClient from '../../http-client';

const getTrailContentReview = async (trailId: string) => {
  const response = (await httpClient.get(`/trail-user-reviews/${trailId}/user`))
    .data;

  return response;
};

const reviewTrailContent = async (trailId: string, description: string, stars?: number) => {
  await httpClient.post(`/trail-user-reviews`, {
    trail_id: trailId,
    description: description,
    stars,
  });
};

export { getTrailContentReview, reviewTrailContent };
