import React, { useEffect, useState } from 'react';
import QuestionAndAnswer from './components/QuestionAndAnswer';
import Form from './components/Form';
import { getQuestions } from 'services/faq';
import Header from 'components/Header';
import { Container, MainHeader, Body, Title, SubTitle } from './style';
import Loading from 'components/Loading';

const FAQ: React.FC = () => {
  const [listQuestions, setListQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasSigned = localStorage.getItem('dorconsultoria-api-token');

  const getQuestionsList = async () => {
    setIsLoading(true);
    setListQuestions(await getQuestions());
    setIsLoading(false);
  };

  useEffect(() => {
    getQuestionsList();
  }, []);

  return (
    <Container >
      {!!hasSigned && <Header />}
      <div className="content" style={{ display: 'none' }}>
        <MainHeader>
          <Title>Perguntas frequentes</Title>
          <SubTitle>
            Tem alguma dúvida? Não se preocupe, vamos tentar respondê-las.
          </SubTitle>
        </MainHeader>
        <Body>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!!listQuestions && listQuestions?.length ? (
                listQuestions.map((item: any) => (
                  <QuestionAndAnswer
                    id={item.id}
                    question={item.question}
                    answer={item.answer}
                  />
                ))
              ) : (
                <span>Nenhuma pergunta encontrada.</span>
              )}
            </>
          )}
        </Body>
      </div>
      <Form />
    </Container>
  );
};

export default FAQ;
