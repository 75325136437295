import { BrowserRouter as Router } from 'react-router-dom';
import Content from './components/Content';
import { SearchProvider } from './context/search';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './slick.css';

function App() {
  return (
    <Router>
      <ToastContainer />
      <SearchProvider>
        <Content />
      </SearchProvider>
    </Router>
  );
}

export default App;
