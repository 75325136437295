import React, { useRef } from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import getErrorMessage from '../../helpers/get-error-message';
import ExamFromApi from '../../models/from-api/exam';
import ExamAttemptFromApi from '../../models/from-api/exam-attempt';
import QuestionFromApi from '../../models/from-api/question';
import QuestionAlternativeFromApi from '../../models/from-api/question-alternative';
import QuestionAnswerFromApi from '../../models/from-api/question-answer';
import {
  getExamAttemptData,
  getExamData,
  getPreviousAnswers,
  startExam,
  getQuestion,
  submitAnswer,
  submitExam,
  getQuestionWithCorrectAnswers,
} from '../../services/exam';
import {
  CourseExamContainer,
  ExamInstructions,
  ExamTitle,
  LoadingContainer,
  ExamSubmitButton,
  ExamSubmitButtonContainer,
  QuestionAlternativeContainer,
  QuestionAlternatives,
  QuestionCardContainer,
  QuestionEnunciated,
  QuestionList,
  QuestionNumber,
  ExamResultContainer,
  QuestionNumberAndIsCorrectTag,
  IsCorrectTag,
  CourseTitle,
} from './style';
import Course from '../../models/course';
import { getCourse as getCourseService } from '../../services/course';
import { toast } from 'react-toastify';
import {
  CertificateData,
  createCertificate,
  getAllCertificates,
  setTrailCertificate,
} from 'services/certificate';

interface CourseExamParams {
  courseId: string;
  moduleId: string;
  examId: string;
}

interface QuestionCardProps {
  questionNumber: number;
  question: QuestionFromApi;
  isCorrect?: boolean;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  questionNumber,
  question,
  isCorrect,
}) => {
  const { answers, addAnsweredQuestion, examIsFinished } =
    useContext(ExamContext);
  const [isAnswered, setIsAnswered] = useState(false);
  const [examAnswerUserId, setExamAnswerUserId] = useState('');
  const [questionWithCorrectAnswer, setQuestionWithCorrectAnswer] = useState(
    {} as QuestionFromApi,
  );

  const getCorrectAnswer = async () => {
    const questionWithCorrectAnswer = await getQuestionWithCorrectAnswers(
      question.question_id,
    );
    setQuestionWithCorrectAnswer(questionWithCorrectAnswer);
  };

  const answerQuestion = async (altId: string) => {
    if (!examIsFinished) {
      try {
        await submitAnswer(examAnswerUserId, altId);
        setIsAnswered(true);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    if (question) {
      const foundAnswer = answers.find(
        ans => ans.question_id === question.question_id,
      );
      if (foundAnswer) {
        setExamAnswerUserId(foundAnswer.exam_answer_user_id);
      }

      if (
        answers &&
        answers.length &&
        answers.some(
          ans => ans.answer_id && ans.question_id === question.question_id,
        )
      ) {
        setIsAnswered(true);
      }
    }
  }, [question]);

  useEffect(() => {
    if (isAnswered) {
      addAnsweredQuestion(question.question_id);
    }
  }, [isAnswered]);

  useEffect(() => {
    if (question.question_id) {
      getCorrectAnswer();
    }
  }, [question]);

  return (
    <QuestionCardContainer>
      <QuestionNumberAndIsCorrectTag>
        <QuestionNumber>Questão {questionNumber}:</QuestionNumber>
        {examIsFinished && (
          <IsCorrectTag className={isCorrect ? 'correct' : 'incorrect'}>
            Resposta {isCorrect ? 'Correta' : 'Incorreta'}
          </IsCorrectTag>
        )}
      </QuestionNumberAndIsCorrectTag>
      <QuestionEnunciated
        dangerouslySetInnerHTML={{ __html: question.enunciated }}
      ></QuestionEnunciated>
      <QuestionAlternatives>
        {question.alternative?.length ? (
          question.alternative.map(alt => (
            <QuestionAlternative
              key={alt.alternative_id}
              questionId={question.question_id}
              alternative={alt}
              answerQuestion={answerQuestion}
              isCorrect={
                questionWithCorrectAnswer?.correct_alternative
                  ?.alternative_id === alt.alternative_id
              }
            />
          ))
        ) : (
          <LoadingContainer>
            <div className="spinner"></div>
          </LoadingContainer>
        )}
      </QuestionAlternatives>
    </QuestionCardContainer>
  );
};

interface QuestionAlternativeProps {
  alternative: QuestionAlternativeFromApi;
  answerQuestion: (alternativeId: string) => void;
  questionId: string;
  isCorrect?: boolean;
}

const QuestionAlternative: React.FC<QuestionAlternativeProps> = ({
  answerQuestion,
  questionId,
  alternative,
  isCorrect,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const { answers, examIsFinished } = useContext(ExamContext);
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (
      answers &&
      answers.length &&
      answers.some(ans => ans.answer_id === alternative.alternative_id)
    ) {
      setIsSelected(true);
    }
  }, [questionId, answers]);

  useEffect(() => {
    if (input.current) {
      input.current.checked = isSelected;
    }
  }, [input, isSelected]);

  return (
    <QuestionAlternativeContainer
      className={
        isSelected && examIsFinished
          ? isCorrect !== undefined
            ? isCorrect
              ? 'correct'
              : 'incorrect'
            : ''
          : ''
      }
    >
      <input
        type="radio"
        name={`${questionId}`}
        id={`${questionId}_${alternative.alternative_id}`}
        ref={input}
        disabled={examIsFinished}
        onChange={e =>
          e.target.checked
            ? answerQuestion(alternative.alternative_id)
            : undefined
        }
      />
      <label htmlFor={`${questionId}_${alternative.alternative_id}`}>
        {alternative.value}
      </label>
    </QuestionAlternativeContainer>
  );
};

interface IExamContext {
  answers: QuestionAnswerFromApi[];
  examUserId: string;
  addAnsweredQuestion: (questionId: string) => void;
  examIsFinished: boolean;
}

const ExamContext = React.createContext<IExamContext>({
  answers: [] as QuestionAnswerFromApi[],
  examUserId: '',
  addAnsweredQuestion: () => {},
  examIsFinished: false,
});

const Exam: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { examId, courseId } = useParams<CourseExamParams>();
  const [exam, setExam] = useState({} as ExamFromApi);
  const [attempts, setAttempts] = useState(
    undefined as ExamAttemptFromApi[] | undefined,
  );
  const [actualAttempt, setActualAttempt] = useState({} as ExamAttemptFromApi);
  const [lastAttempt, setLastAttempt] = useState(
    {} as ExamAttemptFromApi | undefined,
  );
  const [questions, setQuestions] = useState([] as QuestionFromApi[]);
  const [answers, setAnswers] = useState([] as QuestionAnswerFromApi[]);
  const [answeredQuestions, setAnsweredQuestions] = useState([] as string[]);
  const [hasAttempts, setHasAttempts] = useState(false);
  const [shouldShowLoading, showLoading] = useState(true);
  const [course, setCourse] = useState({} as Course);

  const getExam = async () => {
    showLoading(true);

    const localExam = await getExamData(examId);
    setExam(localExam);

    showLoading(false);
  };

  const getCourse = async () => {
    const localCourse = await getCourseService(courseId);
    setCourse(localCourse);
  };

  const getAttempts = async () => {
    showLoading(true);

    const localAttempts = await getExamAttemptData(courseId, examId);
    setAttempts(localAttempts);
    showLoading(false);
  };

  const getQuestions = async () => {
    showLoading(true);

    const localAnswers = await getPreviousAnswers(
      actualAttempt.exam_user_id || lastAttempt!.exam_user_id,
    );
    setAnswers(localAnswers);

    setQuestions([]);
    const localQuestions = [] as QuestionFromApi[];
    for (let answer of localAnswers) {
      const localQuestion = await getQuestion(answer.question_id);
      localQuestions.push(localQuestion);
    }
    setQuestions(localQuestions);

    showLoading(false);
  };

  const startAttempt = async (forceStart?: boolean) => {
    showLoading(true);

    const localHasAttempts = !!attempts!.length;
    setHasAttempts(localHasAttempts);
    const unfinishedAttempt = attempts!.find(att => !att.final_date);

    if (localHasAttempts) {
      const orderedAttempts = attempts!
        .filter(att => !!att.final_date)
        .sort((att1, att2) =>
          new Date(att1.final_date).getTime() >
          new Date(att2.final_date).getTime()
            ? 1
            : -1,
        );

      const localLastAttempt = orderedAttempts.pop()!;
      setLastAttempt(localLastAttempt);
    }

    if (unfinishedAttempt && unfinishedAttempt.exam_id) {
      setActualAttempt(unfinishedAttempt);
    } else if (forceStart || !localHasAttempts) {
      try {
        const localActualAttempt = await startExam(examId, courseId);
        setActualAttempt(localActualAttempt);
        setHasAttempts(true);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error(errorMessage);
      }
    }

    showLoading(false);
  };

  const addAnsweredQuestion = (questionId: string) => {
    if (!answeredQuestions.includes(questionId)) {
      setAnsweredQuestions([...answeredQuestions, questionId]);
    }
  };

  const finishExam = async () => {
    showLoading(true);

    try {
      const data = await submitExam(actualAttempt.exam_user_id);
      if (data && questions.length) {
        if ((data!.result / questions.length) * 100 >= exam.average) {
          tryToCreateCertificate();
        }
      }
      await getAttempts();
      setActualAttempt({} as ExamAttemptFromApi);
      toast.success('Prova finalizada!');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(errorMessage);
    }

    showLoading(false);
  };

  const goToCourse = () => {
    history.push(`/course/${course.id}`);
  };

  const allQuestionsAnswered = useMemo(() => {
    return questions.every(question =>
      answeredQuestions.includes(question.question_id),
    );
  }, [answeredQuestions, questions]);

  useEffect(() => {
    if (examId) {
      getExam();
    }
  }, [examId, location]);

  useEffect(() => {
    if (courseId) {
      getCourse();
    }
  }, [courseId, location]);

  useEffect(() => {
    if (exam && exam.exam_id) {
      getAttempts();
    }
  }, [exam]);

  useEffect(() => {
    if (attempts && attempts instanceof Array) {
      startAttempt();
    }
  }, [attempts]);

  useEffect(() => {
    if (
      (actualAttempt && actualAttempt.exam_user_id) ||
      (lastAttempt && lastAttempt.exam_user_id)
    ) {
      getQuestions();
    }
  }, [actualAttempt, lastAttempt]);

  useEffect(() => {
    if (answers && answers.length) {
      const localAnsweredQuestions = answers.map(ans => ans.question_id);
      setAnsweredQuestions(localAnsweredQuestions);
    }
  }, [answers]);

  const userApprovedOnLastAttempt = useMemo(() => {
    if (lastAttempt && questions.length) {
      return (lastAttempt!.result / questions.length) * 100 >= exam.average;
    }
    return 0;
  }, [lastAttempt, questions, exam]);

  const tryToCreateCertificate = async () => {
    let certificateData = undefined as CertificateData | undefined;
    try {
      certificateData = await setTrailCertificate(courseId);
    } catch (e) {}

    if (!certificateData) {
      try {
        certificateData = await createCertificate(courseId);
      } catch (e) {}
    }
  };

  const getCertificates = async () => {
    const localCertificate = await getAllCertificates();
    const existCertificate = localCertificate.filter(
      cert => cert.reference_id === courseId,
    ).length;
    if (!existCertificate) {
      tryToCreateCertificate();
    }
  };

  useEffect(() => {
    if (userApprovedOnLastAttempt) {
      getCertificates();
    }
  }, [lastAttempt, questions, exam]);

  const numberOfRemainingAttempts = useMemo(() => {
    const localNumberOfRemainingAttempts =
      exam.attempts -
      ((attempts || []).length +
        (!!actualAttempt && !!actualAttempt.exam_user_id ? 1 : 0));

    return localNumberOfRemainingAttempts > 0
      ? localNumberOfRemainingAttempts
      : 0;
  }, [exam, attempts, actualAttempt]);
  return (
    <ExamContext.Provider
      value={{
        answers,
        examUserId: actualAttempt.exam_user_id,
        addAnsweredQuestion,
        examIsFinished: !actualAttempt || !actualAttempt.exam_user_id,
      }}
    >
      <div className="content no-loading">
        {!shouldShowLoading ? (
          <CourseExamContainer>
            {hasAttempts && (!actualAttempt || !actualAttempt.exam_user_id) ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '8px',
                }}
              >
                <ExamResultContainer>
                  <h3>
                    {userApprovedOnLastAttempt ? 'Parabéns você' : 'Você'} teve{' '}
                    <span style={{ color: '#0054A6' }}>
                      {((lastAttempt!.result / questions.length) * 100).toFixed(
                        2,
                      )}
                      % de acerto
                    </span>{' '}
                    na Avaliação "{exam.title}" do curso "{course.title}"
                  </h3>
                  {numberOfRemainingAttempts > 0 ? (
                    <p>
                      Verifique seus acertos e erros na avaliação e tente
                      novamente! Você tem mais {numberOfRemainingAttempts}{' '}
                      tentativas.
                    </p>
                  ) : (
                    <p>Não há novas tentativas disponíveis para este exame.</p>
                  )}
                </ExamResultContainer>
              </div>
            ) : (
              <div>
                <div>
                  <CourseTitle>{course.title}</CourseTitle>
                  <ExamTitle>{exam.title}</ExamTitle>
                  <ExamInstructions>{exam.instructions}</ExamInstructions>
                </div>
              </div>
            )}

            <QuestionList>
              {questions && questions.length ? (
                questions.map((question, index) => (
                  <QuestionCard
                    key={question.question_id}
                    questionNumber={index + 1}
                    question={question}
                    isCorrect={
                      answers && answers.length
                        ? !!answers.find(
                            a =>
                              a.correct_answer &&
                              a.question_id === question.question_id,
                          )
                        : undefined
                    }
                  />
                ))
              ) : (
                <></>
              )}
            </QuestionList>

            {actualAttempt && actualAttempt.exam_user_id ? (
              <ExamSubmitButtonContainer>
                <ExamSubmitButton
                  disabled={!allQuestionsAnswered}
                  onClick={finishExam}
                >
                  Entregar Avaliação
                </ExamSubmitButton>
              </ExamSubmitButtonContainer>
            ) : hasAttempts &&
              (!actualAttempt || !actualAttempt.exam_user_id) ? (
              <ExamSubmitButtonContainer>
                <ExamSubmitButton type="button" onClick={goToCourse}>
                  Voltar a página do curso
                </ExamSubmitButton>
                {numberOfRemainingAttempts > 0 && (
                  <ExamSubmitButton
                    type="button"
                    style={{ marginLeft: '8px' }}
                    onClick={() => startAttempt(true)}
                  >
                    Refazer Avaliação
                  </ExamSubmitButton>
                )}
              </ExamSubmitButtonContainer>
            ) : (
              <></>
            )}
          </CourseExamContainer>
        ) : (
          <LoadingContainer>
            <div className="spinner"></div>
          </LoadingContainer>
        )}
      </div>
    </ExamContext.Provider>
  );
};

export default Exam;
