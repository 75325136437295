import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  margin-top: 56px;
  padding: 0 32px;
  .content {
    > div {
      position: relative;
    }
  }
  h2 {
    font-size: var(--font-largest);
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin: 72px 0px 92px;

  h3 {
    font-size: 21px;
  }
`;

export const TrailsContent = styled.div`
  width: 80%;
  height: 100%;
  margin-left: 80px;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0 32px 56px 0;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 0;
    > div {
      width: 100%;
      margin-bottom: 25px;
      img {
        width: 100%;
        height: 172px;
      }
    }
  }
`;

export const ButtonTrail = styled.button`
  display: none;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 24px;
  background: var(--backgroundWhite);
  padding: 4px 10px 4px 10px;
  color: #25282b;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 6px;
  font-size: var(--font-default);
  line-height: var(--font-default);
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
  }
`;
