import httpClient from 'http-client';
import User from 'models/user';

const getAllAcceptanceTerm = async () => {
  const allAcceptanceTermResponse = (await httpClient.get<any[]>('terms')).data;

  return allAcceptanceTermResponse;
};

const getPrivacyTerm = async () => {
  const privacyTermResponse = (await httpClient.get<any[]>('terms/privacy-term')).data;

  return privacyTermResponse;
};

const getResponsibilityTerm = async () => {
  const responsibilityTermResponse = (await httpClient.get<any[]>('terms/responsibility-term')).data;

  return responsibilityTermResponse;
};

const getCheckAcceptTerm = async (trailId: string, termId: string) => {
  const acceptTermResponse = (
    await httpClient.get<any>(
      `terms/search/user?trail_id=${trailId}&term_id=${termId}`,
    )
  ).data;

  return acceptTermResponse;
};

const getAcceptTermById = async (acceptTermId: string) => {
  const acceptTermResponse = (
    await httpClient.get<any>(`terms/${acceptTermId}`)
  ).data;

  return acceptTermResponse;
};

const updateAcceptTermInUser = async (userId: string): Promise<User> => {
  const updateUser: User = (
    await httpClient.patch('/users/accept-term', {
      user_id: userId,
    })
  ).data;

  return updateUser;
};

const updatePrivacyTermInUser = async (userId: string): Promise<User> => {
  const updateUser: User = (
    await httpClient.patch('/users/accept-statement', {
      user_id: userId,
    })
  ).data;

  return updateUser;
};

interface acceptTermFormProps {
  company_department: string;
  bond: string;
  indication_name: string;
  unit: string;
  relationship: string;
  indication_company: string;
  position: string;
  description: string;
  date: string;
  has_sign: boolean;
}

const createAcceptTermForm = async (content: acceptTermFormProps) => {
  const createAcceptanceTermResponse = (
    await httpClient.post<any>(`terms/form`, content)
  ).data;

  return createAcceptanceTermResponse;
};

interface acceptTermProps {
  trail_id: string;
  has_conflict: boolean;
}

const acceptTerm = async (termId: string, content: acceptTermProps) => {
  const createAcceptanceTermResponse = (
    await httpClient.post<any>(`terms/accept?term_id=${termId}`, content)
  ).data;

  return createAcceptanceTermResponse;
};

export {
  getCheckAcceptTerm,
  acceptTerm,
  createAcceptTermForm,
  getAcceptTermById,
  updateAcceptTermInUser,
  updatePrivacyTermInUser,
  getAllAcceptanceTerm,
  getPrivacyTerm,
  getResponsibilityTerm
};
