import styled from 'styled-components';

export const StyledContainer = styled.section`
  width: 80%;

  button {
    margin: 20px 0 40px;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    button {
      justify-content: left;
    }
  }
`;

export const StyledContent = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  h1 {
    font-size: var(--font-largest);
    color: var(--dark-gray);
    margin-bottom: 15px;
  }

  p {
    font-size: 20px;
    color: #657787;
    max-width: 600px;
    margin-bottom: 18px;
  }

  span {
    display: none;
  }

  textarea {
    width: 700px;
    border: 0;
    padding: 16px;
    border-radius: 6px;
    background: var(--light-gray);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
    resize: vertical;
    min-height: 140px;
    font-size: (--font-regular);
    color: var(--dark-gray);
  }

  button {
    width: 266px;
  }

  @media (max-width: 768px) {
    width: 100%;

    h1 {
      font-size: 24px;
    }
    p {
      font-size: 16px;

      strong {
        font-weight: normal;

        br {
          display: none;
        }
      }
    }

    div {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      display: block;
      color: var(--dark-gray);
    }

    textarea {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }
`;

export const ModalContainer = styled.div`
  h1 {
    margin-bottom: 15px;
  }

  p {
    color: #657787;
    margin-bottom: 15px;
  }
`;
