import React, { useEffect } from 'react';
import { useContext } from 'react';
import CourseContext from '../../../../Context';
import { PlayScormContainer } from './style';
import 'scorm-again';
import {ButtonFullScreen, IframeScormContainer} from "../../../../../../components/PlayScorm/style";
import {BsFullscreen} from "react-icons/bs";

declare let window: any;


const PlayScorm: React.FC = () => {

  const {
    startLesson,
    finishLesson,
    goToNextLesson,
    selectedLesson,
    startCourse,
  } = useContext(CourseContext);
    
    useEffect(() => {
  if (selectedLesson.scorm_version === '2004') {
    window.API_1484_11 = new window.Scorm2004API({});

    window.API_1484_11.on("Initialize", async function() {
      await startCourse();
      await startLesson();
    });
    
    let completionStatus = "";
    let successStatus = "";
    window.API_1484_11.on(
      'SetValue.cmi.*',
      async (CMIElement: string, value: string) => {
        if (CMIElement === 'cmi.completion_status' && value === 'completed'){
          completionStatus=value;
          if(successStatus === 'passed'){
            try {
              await finishLesson();
            } catch (error) {}
            goToNextLesson();
          }
        }
        if(CMIElement === 'cmi.success_status' && value === 'passed') {
          successStatus=value;
          if(completionStatus === 'completed'){
            try {
              await finishLesson();
            } catch (error) {}
            goToNextLesson();
          }
        }
          
      },
    );
  } else {
    window.API = new window.Scorm12API({});

    window.API.on('LMSInitialize', async function () {
      await startCourse();
      await startLesson();
    });

    window.API.on(
      'LMSSetValue.cmi.core.lesson_status',
      async (CMIElement: any, value: string) => {
        if (value === 'completed' || value === 'passed') {
          try {
            await finishLesson();
          } catch (error) {}
          goToNextLesson()
        }
      },
    );
  }
}, [startLesson, finishLesson, goToNextLesson, selectedLesson]);

  useEffect(() => {
    return () => {
      if (selectedLesson.info.scorm_version === '2004') {
        window.API_1484_11.clear('Initialize');
        window.API_1484_11.clear('SetValue.cmi.*');
        window.API_1484_11 = undefined;
      } else {
        window.API.clear('LMSInitialize');
        window.API.clear('LMSSetValue.cmi.core.lesson_status');
        window.API = undefined;
      }
    };
  }, [selectedLesson]);

  const handleFullScreen = () => {
    if (document.fullscreenEnabled) {
      var iframe = document.getElementById('iframe-scorm');

      if (iframe) {
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        }
      }
    }
  };

  return <IframeScormContainer>
    <PlayScormContainer>
      <iframe
        id="iframe-scorm"
        title="referenced-scorm"
        src={selectedLesson.reference}
        frameBorder={0}
        allowFullScreen
        className="iframe-scorm"
      ></iframe>
    </PlayScormContainer>
    <ButtonFullScreen onClick={handleFullScreen}>
      <BsFullscreen/>
      Tela Cheia
    </ButtonFullScreen>
  </IframeScormContainer>;
};

export default PlayScorm;
