import axios from 'axios';
import { toast } from 'react-toastify';

interface IVerifyCode {
  code: string;
  email: string;
}

interface IResendCode {
  email: string;
}

const verifyCode = async ({ code, email }: IVerifyCode): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/verify-code`, {
        email,
        code,
      })
      .then(() => {
        toast.success(
          'Cadastro validado com sucesso, Você já pode realizar seu login!',
        );
      });
    return Promise.resolve();
  } catch (err) {
    toast.error(
      'O código informado está incorreto. Por favor, informe um código válido.',
    );

    return Promise.reject();
  }
};

const resendCode = async ({ email }: IResendCode): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/resend-code`, {
        email,
      })
      .then(() => {
        toast.success('Código reenviado com sucesso!');
      });
  } catch (err) {
    toast.error('Erro ao reenviar o código. Por favor, tente novamente.');

    return Promise.reject();
  }
};

export { verifyCode, resendCode };
