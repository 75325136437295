import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0;
  background-color: #fff;
  margin: 4px 0;

  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  svg {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-left: 1rem;
`;

export const Question = styled.h3`
  color: var(--gray-darkest);
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: var(--font-large);
    margin-top: 3px;
  }
`;

export const Answer = styled.p`
  max-width: 80%;
  font-size: 20px;
  color: #555;
  margin-top: 1rem;

  @media (max-width: 768px) {
    max-width: 95%;
    font-size: var(--font-default);
  }
`;
