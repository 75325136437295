import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { hoursToMinutes, minutesToHours } from 'date-fns';
import { HiOutlinePencil } from 'react-icons/hi';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import ProfileMenu from '../../components/ProfileMenu';

import DefaultButton from 'components/Buttons/DefaultButton';
import DefaultInput from 'components/Inputs/DefaultInput/DefaultInput';
import InputPassword from 'components/Inputs/InputPassword';

import {
  Button,
  Container,
  Header,
  ContentContainer,
  AvatarInput,
  DataWrapper,
  ChangeDataForm,
  ContentWrapper,
  ModalWrapper,
  ChangePasswordContent,
  LineColor,
  ContainerProfileMobile,
} from './style';

import User from 'models/user';
import InputWithMask from 'components/Inputs/InputWithMask';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import getValidationErrors from 'helpers/getValidationErrors';
import { BiUserCircle } from 'react-icons/bi';
import { changeAvatar } from 'services/avatar';
import { getWatchTime } from 'services/user';
import {
  forgotPassword,
  forgotPasswordConfirm,
} from 'services/forgot-password';
import { editUserInfo } from 'services/user';

import useWindowSize from 'hooks/useWindowSize';

import { FiAward, FiBookmark, FiPlayCircle, FiUser } from 'react-icons/fi';

import { IoArrowBackOutline } from 'react-icons/io5';
import { AiOutlineRight } from 'react-icons/ai';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.'),
  newPasswordConfirm: Yup.string()
    .oneOf(
      [Yup.ref('newPasswordConfirm'), null],
      'As senhas informadas não correspondem. Por favor, verifique.',
    )
    .trim()
    .required('Preencha corretamente este campo.'),
});

const validSchema = Yup.object().shape({
  name: Yup.string().trim().min(4, 'O nome deve conter pelo menos 4 letras'),
})

interface IChangePassword {
  newPassword: string;
  newPasswordConfirm: string;
}

interface LocationProps {
  menuData: boolean;
}

const UserData: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [userTimeWatched, setUserTimeWatched] = useState(0);
  const inputRef = useRef(null);
  const [editUser, setEditUser] = useState(true);
  const [editPassword, setEditPassword] = useState('step-0');
  const [code, setCode] = useState('');
  const [isProfileMobileOpen, setIsProfileMobileOpen] = useState(true);

  const [user, setUser] = useState<User>(
    JSON.parse(localStorage.getItem('dorconsultoria-user') || ''),
  );

  const { state } = useLocation<LocationProps>();

  const handleCloseModal = () => {
    setCode('');
    setEditPassword('step-0');
  };

  const sendCode = async () => {
    await forgotPassword({ email: user.email }).then(() => {
      toast.success('Código de verificação enviado para seu email');
      setEditPassword('step-1');
    });
  };

  const handleSendCode = () => {
    setEditPassword('step-2');
  };

  const handleSubmit = async (data: IChangePassword) => {
    const { newPassword } = data;

    try {
      formRef.current?.setErrors({});

      await validationSchema.validate(data, { abortEarly: false });

      await forgotPasswordConfirm({
        email: user.email,
        code,
        password: newPassword,
      }).then(() => {
        handleCloseModal();
        setCode('');
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        try {
          const response = await changeAvatar(data);
          toast.success('Avatar atualizado!');

          const userObject = Object.assign(user, {
            info: {
              ...user.info,
              avatar: response
            }
          })

          localStorage.setItem(
            'dorconsultoria-user',
            JSON.stringify(userObject),
          );

          window.location.reload();
        } catch (err) {
          toast.error(
            'Houve um erro ao atualizar o avatar. Por favor, tente novamente!',
          );
        }
      }
    },
    [],
  );

  const handleEditUser = async () => {
    const data = {
      name: user.name,
      info: user.info,
    }

    await validSchema.validate(data, { abortEarly: false }).then(
      async () => {
        await editUserInfo(data, user.user_id)
          .then(response => {
            localStorage.setItem('dorconsultoria-user', JSON.stringify(response));
            toast.success('Dados alterados com sucesso!');
          }).catch(() => toast.error('Erro ao alterar dados!'));

        setEditUser(true)
      }).catch(async err => {
        console.log(err)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          toast.error(`${errors.name}`);
        }
      })
  };

  const getUserWatchTime = async () => {
    const response = await getWatchTime();

    if (response.hours_watched) {
      setUserTimeWatched(response.hours_watched);
    }
  };

  const isSSO = JSON.parse(
    localStorage.getItem('dorconsultoria-isSSO') as string,
  );

  const widthPage: Array<number> = useWindowSize();
  const widthMobile = widthPage[0] <= 768;

  useEffect(() => {
    if (widthPage[0] >= 768 && isProfileMobileOpen === false) {
      setIsProfileMobileOpen(true);
    }
  }, [widthPage[0]]);

  useEffect(() => {
    getUserWatchTime();

    if (state !== undefined && state.menuData) {
      setIsProfileMobileOpen(false);
    }
  }, [getWatchTime]);

  return (
    <Container className="content">
      <ProfileMenu />

      <ContentContainer>
        {isProfileMobileOpen && (
          <Header>
            <div>
              <h2>Olá, {user.name}!</h2>
              <>{widthMobile && <p>{user.email}</p>}</>
              <p>
                Você já realizou{' '}
                {userTimeWatched < 1
                  ? `${hoursToMinutes(userTimeWatched)} minutos`
                  : `${minutesToHours(hoursToMinutes(userTimeWatched))} ${userTimeWatched < 2 ? 'hora' : 'horas'
                  }`}{' '}
                de cursos e treinamentos. Parabéns!
              </p>
            </div>

            <AvatarInput>
              {user.info.avatar && user.info.avatar !== '' ? (
                <img src={user.info.avatar} alt="Avatar" />
              ) : (
                <BiUserCircle color="#BDBDBD" />
              )}
              <label htmlFor="avatar">
                <HiOutlinePencil size={20} color="#fff" />

                <input
                  type="file"
                  name="avatar"
                  id="avatar"
                  onChange={handleAvatarChange}
                  ref={inputRef}
                />
              </label>
            </AvatarInput>
          </Header>
        )}

        {widthMobile && isProfileMobileOpen ? (
          <ContainerProfileMobile>
            <Link
              to={'/profile'}
              onClick={() => setIsProfileMobileOpen(!isProfileMobileOpen)}
            >
              <span>
                <FiUser size={24} />
                Meus dados
              </span>
              <AiOutlineRight size={24} />
            </Link>

            <Link to={'/ongoing-courses'}>
              <span>
                <FiPlayCircle size={24} />
                Em andamento
              </span>
              <AiOutlineRight size={24} />
            </Link>

            <Link to={'/favorite-courses'}>
              <span>
                <FiBookmark size={24} />
                Favoritos
              </span>
              <AiOutlineRight size={24} />
            </Link>

            <Link to={'/certificates'}>
              <span>
                <FiAward size={24} />
                Certificados
              </span>
              <AiOutlineRight size={24} />
            </Link>
          </ContainerProfileMobile>
        ) : null}

        <>
          {!isProfileMobileOpen || !widthMobile ? (
            <>
              <Button onClick={() => setIsProfileMobileOpen(true)}>
                <IoArrowBackOutline size={14} color="#0054A6" />
                <span>Voltar</span>
              </Button>
              {editUser ? (
                <DataWrapper>
                  <h3>Meus dados</h3>
                  <ContentWrapper>
                    <div>
                      <p>Nome</p>
                      <p>{user.name}</p>
                    </div>
                    <div>
                      <p>Telefone</p>
                      <p>
                        {user.info.phone
                          ? user.info.phone
                          : 'Sem numero de telefone cadastrado'}
                      </p>
                    </div>
                  </ContentWrapper>
                  <DefaultButton onClick={() => setEditUser(value => !value)}>
                    Editar Dados
                  </DefaultButton>
                </DataWrapper>
              ) : (
                <ChangeDataForm onSubmit={handleEditUser}>
                  <h3>Meus dados</h3>

                  <div>
                    <DefaultInput
                      name="registration"
                      type="text"
                      label="Name"
                      className="userDataInput"
                      value={user.name}
                      onChange={e =>
                        setUser({ ...user, name: String(e.target.value) })
                      }
                    />
                    <InputWithMask
                      name="cell"
                      type="text"
                      label="Telefone"
                      className="userDataInput"
                      mask={'(99) 99999-9999'}
                      value={user.info.phone}
                      onChange={e =>
                        setUser({
                          ...user,
                          info: { ...user.info, phone: String(e.target.value) },
                        })
                      }
                    />
                  </div>
                  <DefaultButton type="submit">Salvar</DefaultButton>
                </ChangeDataForm>
              )}

              {isSSO === false ? (
                <ChangePasswordContent>
                  <h3>Alterar minha senha</h3>
                  <p>Utilize uma senha forte para a segurança de sua conta!</p>
                  <DefaultButton onClick={sendCode} className="editButton">
                    Editar Senha
                  </DefaultButton>
                </ChangePasswordContent>
              ) : null}
            </>
          ) : (
            ''
          )}
        </>

        <Form onSubmit={handleSubmit} ref={formRef}>
          <ModalWrapper isOpen={editPassword === 'step-1'}>
            <div>
              <h1>Alterar minha senha</h1>
            </div>
            <div className="stepDiv">
              <p>1. Verificação de código</p>
              <p>2. Nova senha</p>
            </div>
            <LineColor size={25} />
            <div className="lineBotton" />
            <p>Verifique o código enviado para seu email e digite abaixo.</p>
            <div className="inputDiv">
              <DefaultInput
                name="coding"
                type="text"
                minLength={6}
                maxLength={6}
                label="Código de verificação"
                value={code}
                onChange={e => setCode(e.target.value)}
              />
            </div>
            <div className="buttonsDiv">
              <DefaultButton
                className="cancelButton"
                type="button"
                onClick={handleCloseModal}
              >
                Cancelar
              </DefaultButton>

              <DefaultButton
                disabled={code.length < 6}
                type="button"
                onClick={handleSendCode}
              >
                Confirmar código
              </DefaultButton>
            </div>
          </ModalWrapper>

          <ModalWrapper isOpen={editPassword === 'step-2'}>
            <div>
              <h1>Alterar minha senha</h1>
            </div>
            <div className="stepDiv">
              <p className="blue">1. Verificação de código</p>
              <p>2. Nova senha</p>
            </div>
            <LineColor size={60} />
            <div className="lineBotton" />
            <p>
              Digite sua nova senha e digite a mesma senha novamente, para
              confirma-lá.
            </p>
            <div className="inputDiv">
              <InputPassword
                name="newPassword"
                type="text"
                label="Nova senha"
              />
              <InputPassword
                name="newPasswordConfirm"
                type="text"
                label="Código de verificação"
              />
            </div>
            <div className="buttonsDiv">
              <DefaultButton
                className="cancelButton"
                type="button"
                onClick={handleCloseModal}
              >
                Cancelar
              </DefaultButton>

              <DefaultButton
                type="submit"
                onClick={() =>
                  handleSubmit({
                    newPassword: formRef.current?.getFieldValue('newPassword'),
                    newPasswordConfirm:
                      formRef.current?.getFieldValue('newPasswordConfirm'),
                  })
                }
              >
                Confirmar código
              </DefaultButton>
            </div>
          </ModalWrapper>
        </Form>
      </ContentContainer>
    </Container>
  );
};

export default UserData;
