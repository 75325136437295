import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { CheckboxContainer } from './style';

interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

const InputCheckbox: React.FC<ICheckbox> = ({ label, name, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <CheckboxContainer>
      <input type="checkbox" id={name} ref={inputRef} {...rest} />

      <label htmlFor="checkbox">{label}</label>
    </CheckboxContainer>
  );
};

export default InputCheckbox;
