import React, { InputHTMLAttributes, useState, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { BsEyeSlash, BsEye } from 'react-icons/bs';

import { Container, InputContent, ShowPassword, Error } from './style';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  className?: string;
}

const InputPassword: React.FC<InputProps> = ({
  label,
  name,
  className,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleToggleType = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <InputContent className={className}>
        <input
          {...rest}
          ref={inputRef}
          id={name}
          name={name}
          type={showPassword ? 'text' : 'password'}
          required
        />

        <ShowPassword type="button" onClick={handleToggleType}>
          {showPassword ? (
            <BsEyeSlash size={22} color="#7D8073" />
          ) : (
            <BsEye size={22} color="#7D8073" />
          )}
        </ShowPassword>
      </InputContent>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default InputPassword;
