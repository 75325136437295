import React, { useState, useEffect } from 'react';
import { BiUserCircle } from 'react-icons/bi';

import Deposition from '../../models/depositions';

import QuotationMarks from '../../assets/svgs/quotation-marks.svg';

import {
  Container,
  ContainerFooter,
  DepositionAuthor,
  DepositionOffice,
} from './style';

interface IDeposition {
  deposition: Deposition;
}

const DepositionCard: React.FC<IDeposition> = ({ deposition }) => {
  const [showAll, setShowAll] = useState(true);

  return (
    <Container onClick={() => setShowAll(!showAll)}>
      <img src={QuotationMarks} alt="Quotation marks" />

      <p className={showAll ? '' : 'showAll'}>{deposition.description}</p>

      <ContainerFooter>
        <BiUserCircle color="#BDBDBD" />
        <div>
          <DepositionAuthor>{deposition.name}</DepositionAuthor>
          <DepositionOffice>{deposition.roles[0]}</DepositionOffice>
        </div>
      </ContainerFooter>
    </Container>
  );
};

export default DepositionCard;
