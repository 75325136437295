import styled from 'styled-components';

export const IframeScormContainer = styled.div`
  position: relative;
`;

export const ButtonFullScreen = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 6px;
  right: 5px;
  bottom: 8px;
  background-color: var(--secondary-color);
  outline: none;
  border: none;
  color: var(--white-font-color);
  padding: 2px 5px;
  border-radius: 2px;
  font-size: var(--font-small);
  cursor: pointer;
`;
