import React from 'react';

import Skeleton from '../Skeleton';

import { Container } from './style';

const CardItemSkeleton: React.FC = () => {
  return (
    <Container>
      <Skeleton className="image" />
      <Skeleton className="title" />
      <Skeleton className="info" />
    </Container>
  );
};

export default CardItemSkeleton;
