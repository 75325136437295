import httpClient from '../../http-client';

export interface TimeWatched {
  hours_watched: number;
}

const getWatchTime = async (): Promise<TimeWatched> => {
  const watchTime = (await httpClient.get(`users/watch-time`)).data;

  return watchTime;
};

const editUserInfo = async (data: any, userId: string): Promise<any> => {
  const updateUser = (await httpClient.put(`users/${userId}`, data)).data;

  return updateUser;
};

const getActualUser = async () => {
  const actualUser = (await httpClient.get('/users/me')).data;

  return actualUser;
};

export { getWatchTime, editUserInfo, getActualUser };
