import httpClient from '../../http-client';

const startModule = async (courseId: string, moduleId: string) => {
  await httpClient.post('/trail-course-users', {
    trail_id: courseId,
    course_id: moduleId,
  });
};

const finishModule = async (courseId: string, moduleId: string) => {
  await httpClient.put('/trail-course-users/finish', {
    trail_id: courseId,
    course_id: moduleId,
  });
};

export { startModule, finishModule };
