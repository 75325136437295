import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PlayerSambaVideos, {
  PlayerEventListener,
} from 'components/PlayerSambaVideos';
import CourseContext from '../../Context';
import LessonAnnotations from './components/LessonAnnotations';
import LessonExtraMaterial from './components/LessonExtraMaterial';
import LessonOverview from './components/LessonOverview';
import {
  CourseContentContainer,
  CourseLikeContainer,
  CourseTabHeaderContainer,
  CourseTabsAndRating,
  CourseTabsContent,
  CourseTabsHeaders,
  PlayerContainer,
} from './styles';
import { CourseExtendedWindow } from '../..';
import { useParams } from 'react-router';
import PlayScorm from './components/PlayScorm';
import CourseImage from '../../../../assets/banner-image.png';
import { AiFillLike, AiOutlineLike } from 'react-icons/ai';
import { toast } from 'react-toastify';
import getErrorMessage from 'helpers/get-error-message';
import { addLike, removeLike } from 'services/like';

declare let window: CourseExtendedWindow;

interface CourseTabHeader {
  id: Tabs;
  name: string;
  shouldShow: boolean;
}

interface CourseTabHeaderProps {
  header: CourseTabHeader;
  selected: boolean;
}

interface CourseParams {
  courseId: string;
  moduleId: string;
  lessonId: string;
}

type Tabs = 'overview' | 'extra-material' | 'annotations';

const CourseContent: React.FC = () => {
  const { courseId, moduleId, lessonId } = useParams<CourseParams>();

  const {
    selectedLesson,
    course,
    startLesson,
    finishLesson,
    goToNextLesson,
    updateContentProgress,
    startCourse,
  } = useContext(CourseContext);
  const [selectedTab, setSelectedTab] = useState('overview' as Tabs);
  const [addedToLike, setAddedToLike] = useState(course.userLiked);
  const [likes, setLikes] = useState(course.numberOfLikes);
  const [contentReference, setContentReference] = useState<string | undefined>(
    '',
  );
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  const getControlsToEnable = () => {
    const controls = [
      'play',
      'pause',
      'quality',
      'fullscreen',
      'time',
      'volume',
      'backward',
    ];

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      controls.push('seekbar');
    }

    if (selectedLesson && selectedLesson.alreadyFinished) {
      controls.push('forward', 'seekbar');
    }

    return controls;
  };

  const changeTab = (headerId: Tabs) => {
    setSelectedTab(headerId);
  };

  const CourseTabHeader: React.FC<CourseTabHeaderProps> = ({
    header,
    selected,
  }) => {
    return (
      <CourseTabHeaderContainer
        className={selected ? 'selected' : ''}
        onClick={() => changeTab(header.id)}
      >
        {header.name}
      </CourseTabHeaderContainer>
    );
  };

  const tabToBeRendered = useMemo((): JSX.Element => {
    switch (selectedTab) {
      case 'overview':
        return <LessonOverview />;
      case 'extra-material':
        return <LessonExtraMaterial />;
      case 'annotations':
        return <LessonAnnotations />;
    }
  }, [selectedTab]);

  const isWatchingLesson = useMemo(() => {
    return !!(selectedLesson && selectedLesson.id);
  }, [selectedLesson]);

  const courseTabHeaders = [
    { id: 'overview', name: 'Visão Geral', shouldShow: true },
    {
      id: 'extra-material',
      name: 'Material de Apoio',
      shouldShow: isWatchingLesson,
    },
    {
      id: 'annotations',
      name: 'Anotações',
      shouldShow: isWatchingLesson,
    },
  ] as CourseTabHeader[];

  const resumeTime = useMemo(() => {
    if (!selectedLesson && !course.previewData) {
      return false;
    }
    if (selectedLesson) {
      setLastWatchedTime(selectedLesson.content_view!);
      return selectedLesson.content_view;
    } else if (!!course.previewData?.contentView) {
      const { contentView } = course.previewData;
      setLastWatchedTime(contentView);
      return contentView ? contentView : false;
    }

    return false;
  }, [selectedLesson]);

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 0.95;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const checkIsCompleted = async (player: PlayerEventListener) => {
    const { event, eventParam, duration } = player;
    if (event !== 'onProgress' || !eventParam) {
      return false;
    }

    if (hasReachedCompletionTime(duration, eventParam)) {
      await finishLesson();
    }
  };

  const updateWatchTime = useCallback(
    (player: PlayerEventListener) => {
      const { event, eventParam } = player;

      if (event === 'onProgress') {
        setProgress(eventParam);
      }
      return null;
    },
    [lastWatchedTime],
  );

  const getEventListeners = useCallback(
    async (player: PlayerEventListener) => {
      if (
        window.actualCourseId === courseId &&
        window.actualModuleId === moduleId &&
        window.actualLessonId === lessonId &&
        (window.location.href.endsWith(
          `/course/${courseId}/module/${moduleId}/lesson/${lessonId}`,
        ) ||
          window.location.href.endsWith(`/course/${courseId}`))
      ) {
        if (player.event === 'onProgress') {
          if (
            (selectedLesson.id && !selectedLesson.alreadyFinished) ||
            (!selectedLesson.id && !course.previewData.finished)
          ) {
            updateWatchTime(player);
            await checkIsCompleted(player);
          }

          return;
        }

        if (selectedLesson && selectedLesson.id) {
          switch (player.event) {
            case 'onStart':
              await startCourse();
              setLastWatchedTime(resumeTime ? resumeTime : 0);
              await startLesson();
              break;

            case 'onFinish':
              await finishLesson();
              goToNextLesson();
              break;
          }
        }
      }
    },
    [selectedLesson.courseId, selectedLesson.moduleId, selectedLesson.id],
  );

  const addToLike = async (event: React.MouseEvent) => {
    if (course && course.id) {
      try {
        if (addedToLike) {
          await removeLike(course.id);
          setLikes(likes - 1);
        } else {
          await addLike(course.id);
          setLikes(likes + 1);
        }
        setAddedToLike(!addedToLike);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error(`Erro ao ${errorMessage}`);
      }
    }
  };

  const courseTabHeadersToShow = useMemo(() => {
    return courseTabHeaders.filter(tab => tab.shouldShow);
  }, [course, selectedLesson]);

  useEffect(() => {
    setContentReference('');

    setTimeout(() => {
      if (
        selectedLesson.reference &&
        selectedLesson.reference !== contentReference
      ) {
        setContentReference('overview');
        setContentReference(selectedLesson.reference);
      }
    }, 100);
  }, [selectedLesson.reference]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress, !selectedLesson.id);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress, !selectedLesson.id);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null, !selectedLesson.id);
    }
  }, [progress]);

  return (
    <CourseContentContainer>
      <PlayerContainer>
        {selectedLesson.type &&
          selectedLesson.type.toUpperCase() === 'SCORM' ? (
          <PlayScorm />
        ) : (
          <PlayerSambaVideos
            contentReference={selectedLesson.reference}
            controlsToEnable={getControlsToEnable()}
            getEventListeners={getEventListeners}
            resume={selectedLesson.content_view || 0}
          />
        )}
      </PlayerContainer>
      <CourseTabsAndRating>
        <CourseTabsHeaders>
          {courseTabHeadersToShow && courseTabHeadersToShow.length ? (
            courseTabHeadersToShow.map(header => (
              <CourseTabHeader
                key={header.id}
                header={header}
                selected={selectedTab === header.id}
              />
            ))
          ) : (
            <></>
          )}

          <CourseLikeContainer>
            <span className={`likes ${addedToLike && 'liked'}`}>
              <button onClick={e => addToLike(e)}>
                {addedToLike ? (
                  <AiFillLike color="var(--primary-color)" size={24} />
                ) : (
                  <AiOutlineLike color="var(--primary-color)" size={24} />
                )}
              </button>{' '}
              <span>
                {likes} {likes === 1 ? 'curtida' : 'curtidas'}
              </span>
            </span>
          </CourseLikeContainer>
        </CourseTabsHeaders>
      </CourseTabsAndRating>
      <CourseTabsContent>{tabToBeRendered}</CourseTabsContent>
    </CourseContentContainer>
  );
};

export default CourseContent;
