import LessonFromApi from '../../models/from-api/lesson';
import LessonUserFromApi from '../../models/from-api/lesson-user';
import Lesson from '../../models/lesson';

const convertToLesson = (
  courseId: string,
  moduleId: string,
  lesson: LessonFromApi,
  isFirst: boolean,
  isLast: boolean,
  lessonUser?: LessonUserFromApi,
  isLastModule?: boolean,
): Lesson => {
  return {
    id: lesson.content_id,
    description: lesson.description,
    duration: lesson.duration,
    position: lesson.position,
    reference: lesson.reference,
    title: lesson.title,
    type: lesson.type,
    thumbnail: lesson.thumbnail || '',
    courseId: courseId,
    moduleId: moduleId,
    alreadyStarted: !!lessonUser && !!lessonUser.start_at,
    alreadyFinished: !!lessonUser && !!lessonUser.finish_at,
    content_view: lessonUser?.content_view || null,
    isFirstOfModule: isFirst,
    isLastOfModule: isLast,
    isLocked: false,
    isLastLesson: isLastModule && isLast,
    scorm_version: lesson.info ? lesson.info.scorm_version : '',
    info: lesson.info,
  };
};

export default convertToLesson;
