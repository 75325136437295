import httpClient from '../../http-client';

const addLike = async (trailId: string) => {
  const addLikeResponse = (
    await httpClient.post(`/trail-users/like`, { trail_id: trailId })
  ).data;
  return addLikeResponse;
};

const removeLike = async (trailId: string) => {
  const removeLikeResponse = (
    await httpClient.delete(`/trail-users/${trailId}/like`)
  ).data;
  return removeLikeResponse;
};

export { addLike, removeLike };
