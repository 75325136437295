import React, { useState, useCallback, useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import CourseCardItem from 'components/CourseCardItem';
import ProfileMenu from '../../components/ProfileMenu';

import { Container, Div, FavoritesList, SelectContainer } from './style';

import TrailCard from 'components/TrailCard';
import { getCourses } from 'services/course';
import GridShimmer from 'components/GridShimmer';

import BackButton from 'components/Buttons/BackButton';
import { useHistory } from 'react-router-dom';
import Course from 'models/course';

const FavoriteCourses: React.FC = () => {
  const [favoriteTrails, setFavoriteTrails] = useState([] as Course[]);
  const [favoriteContents, setFavoriteContents] = useState([] as Course[]);
  const [contentSelected, setContentSelected] = useState('contents');
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const refreshFavoriteTrails = useCallback(async () => {
    setIsLoading(true);
    const favoriteFilters = {
      book_mark: true,
    };

    const localFavoriteTrails = await getCourses(favoriteFilters);

    if (localFavoriteTrails && localFavoriteTrails.length) {
      setFavoriteTrails(localFavoriteTrails);
    }
    setIsLoading(false);
  }, []);

  const refreshFavoriteContents = useCallback(async () => {
    setIsLoading(true);
    const favoriteFilters = {
      book_mark: true,
    };

    const localFavoriteContents = await getCourses(favoriteFilters);

    if (localFavoriteContents && localFavoriteContents.length) {
      setFavoriteContents(localFavoriteContents);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await refreshFavoriteTrails();
      await refreshFavoriteContents();
    })();
  }, [refreshFavoriteTrails, refreshFavoriteContents]);

  return (
    <>
      <Div>
        <BackButton customFunc={() => history.push('/profile')} />
      </Div>
      <Container className="content">
        <ProfileMenu />

        <div>
          <h1>
            {contentSelected === 'contents'
              ? 'Cursos em andamento'
              : 'Trilhas em andamento'}
          </h1>
          <header>
            <h2>
              {contentSelected === 'contents'
                ? 'Cursos Favoritos'
                : 'Trilhas favoritas'}
            </h2>

            <SelectContainer>
              <select onChange={e => setContentSelected(e.target.value)}>
                <option value="contents">Cursos favoritos</option>
                <option value="trails">Trilhas favoritas</option>
              </select>
              <IoIosArrowDown size={20} />
            </SelectContainer>
          </header>
          {isLoading ? (
            <div className="shimmer">
              <GridShimmer />
            </div>
          ) : (
            <>
              {contentSelected === 'contents' && (
                <div>
                  <FavoritesList>
                    {favoriteContents && favoriteContents.length ? (
                      favoriteContents.map(course => (
                        <>
                          <CourseCardItem
                            key={course.id}
                            course={course}
                            onAddToList={refreshFavoriteContents}
                          />
                          <div />
                        </>
                      ))
                    ) : (
                      <h4 style={{ padding: '15px' }}>
                        Não foram encontrados cursos favoritados.
                      </h4>
                    )}
                  </FavoritesList>
                </div>
              )}

              {contentSelected === 'trails' && (
                <div>
                  <FavoritesList>
                    {favoriteTrails && favoriteTrails.length ? (
                      favoriteTrails.map(trail => (
                        <>
                          <TrailCard
                            key={trail.id}
                            trail={trail}
                            onAddToList={refreshFavoriteTrails}
                          />
                          <div />
                        </>
                      ))
                    ) : (
                      <h4 style={{ padding: '15px' }}>
                        Não foram encontradas trilhas favoritadas.
                      </h4>
                    )}
                  </FavoritesList>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default FavoriteCourses;
