import styled from 'styled-components';

interface props {
  display: string;
}

export const MenuContainer = styled.nav<props>`
  width: 50%;

  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 10px;
  overflow: hidden;

  background: var(--primary-color);
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);

  > img.logoMobile {
    width: 50px;
    margin-top: 40px;
    margin-left: 20px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    text-decoration: none;

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: #fff;
      padding-top: 40px;

      svg {
        display: flex;
        align-items: left;
        justify-content: center;
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }

  span.display {
    display: ${props => props.display};

    align-items: center;
    font-size: var(--font-large);
    color: #fff;
    padding-top: 52px;

    svg {
      display: flex;
      align-items: left;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    height: 100vh;
  }
`;

export const LogoutButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  font-size: var(--font-large);
  color: #fff;
  background: transparent !important;
  border: none;
  bottom: 30px;

  svg {
    margin: 0 8px 4px 0;
  }
`;

export const Div = styled.div<props>`
  display: ${props => props.display};
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;

  span {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: var(--font-large);
    color: #fff;
    padding-top: 20px;

    svg {
      display: flex;
      align-items: left;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  span.data {
    font-size: var(--font-default);
    margin-top: 50px;
    padding: 0;
  }
`;
