import React, { useContext, useMemo } from 'react';
import {
  AiOutlineCheck,
  AiOutlineLock,
  AiOutlinePlayCircle,
} from 'react-icons/ai';
import { useHistory } from 'react-router';
import { secondsToTimeText } from '../../../../../../helpers/time';
import Lesson from '../../../../../../models/lesson';
import Module from '../../../../../../models/module';
import CourseContext from '../../../../Context';
import {
  LessonContent,
  LessonDuration,
  LessonFlux,
  LessonFluxCircle,
  LessonFluxLowerBar,
  LessonFluxUpperBar,
  LessonTitle,
  ModuleLessonContainer,
  ModuleLessonsContainer,
} from './styles';

interface ModuleLessonsProps {
  module: Module;
}

interface ModuleLessonProps {
  lesson: Lesson;
}

const ModuleLesson: React.FC<ModuleLessonProps> = ({ lesson }) => {
  const history = useHistory();
  const { selectedLesson, course } = useContext(CourseContext);

  const goToLesson = () => {
    if (!lesson.isLocked) {
      history.push(
        `/course/${lesson.courseId}/module/${lesson.moduleId}/lesson/${lesson.id}`,
      );
    }
  };

  const lessonFluxIcon = useMemo(() => {
    if (lesson.alreadyFinished) {
      return <AiOutlineCheck size={24} />;
    } else if (lesson.isLocked) {
      return <AiOutlineLock size={24} />;
    } else {
      return <AiOutlinePlayCircle size={24} />;
    }
  }, [lesson, course]);

  const fluxClassName = useMemo(() => {
    return lesson.isLocked
      ? 'locked'
      : lesson.alreadyFinished
      ? 'finished'
      : 'unlocked';
  }, [lesson, course]);

  const isSelected = useMemo(() => {
    return (
      lesson.id === selectedLesson.id &&
      lesson.moduleId === selectedLesson.moduleId &&
      selectedLesson.courseId === lesson.courseId
    );
  }, [lesson, selectedLesson]);

  return (
    <ModuleLessonContainer
      className={lesson.isLocked ? 'locked' : ''}
      onClick={goToLesson}
    >
      <LessonFlux>
        <LessonFluxUpperBar
          className={`${fluxClassName} ${
            lesson.isFirstOfModule ? 'first' : ''
          }`}
        />
        <LessonFluxCircle className={fluxClassName}>
          {lessonFluxIcon}
        </LessonFluxCircle>
        <LessonFluxLowerBar
          className={`${fluxClassName} ${lesson.isLastOfModule ? 'last' : ''}`}
        />
      </LessonFlux>
      <LessonContent>
        <LessonTitle className={isSelected ? 'selected' : ''}>
          {lesson.title}
        </LessonTitle>
        <LessonDuration>{secondsToTimeText(lesson.duration)}</LessonDuration>
      </LessonContent>
    </ModuleLessonContainer>
  );
};

const ModuleLessons: React.FC<ModuleLessonsProps> = ({ module }) => {
  return (
    <ModuleLessonsContainer>
      {module.lessons && module.lessons.length ? (
        module.lessons.map(lesson => (
          <ModuleLesson key={lesson.id} lesson={lesson} />
        ))
      ) : (
        <></>
      )}
    </ModuleLessonsContainer>
  );
};

export default ModuleLessons;
