import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export const CertificateImage = styled.div`
  min-width: 210px;
  height: 200px;
  background: var(--default-gray);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 580px) {
    width: 116px;
    min-width: 100px;
    height: 110px;

    img {
      width: 36px;
      height: 32px;
    }
  }
`;

export const ContentContainer = styled.div`
  margin: 16px 0 0 20px;

  h1 {
    font-size: 20px;
    margin-bottom: 1em;
  }

  p {
    font-size: var(--font-default);
    color: var(--dark-gray);
    margin: 8px 0 36px;
  }

  @media (max-width: 580px) {
    margin: 12px 0 0 8px;

    h1 {
      font-size: var(--font-small);
      margin-bottom: 0.4em;
    }

    p {
      font-size: var(--font-min);
      margin-bottom: 24px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;

  button {
    cursor: pointer;
    border: none;
    background: none;
    display: flex;
    align-items: initial;
    font-size: var(--font-default);
    color: var(--primary-color);

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    width: 1px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 12px 0 8px;
  }

  @media (max-width: 1440px) {
    span {
      height: 20px;
      margin: 0 12px 0 9px;
    }
  }
  @media (max-width: 580px) {
    button {
      flex-direction: row;
      align-items: center;
      font-size: var(--font-min);

      svg {
        width: 12px;
        height: 12px;
      }
    }

    span {
      height: 20px;
      margin: 0 12px 0 5px;
    }
  }
`;
