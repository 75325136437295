import styled from 'styled-components';

export const Container = styled.aside`
  min-width: 250px;
  margin: 72px 24px 0 0;

  a {
    text-decoration: none;
    font-size: 20px;
    color: var(--dark-gray);
    display: flex;
    align-items: stretch;

    & + a {
      margin-top: 48px;
    }

    svg {
      margin-right: 16px;
    }

    &:after {
      content: '';
      width: 6px;
      height: 0px;
      opacity: 0;
      left: 0px;
      position: absolute;
      transition: all 0.2s ease-in-out;
      background: var(--secondary-color);
    }

    &:hover,
    &.active {
      font-weight: 500;
      color: var(--secondary-color);

      &:after {
        height: 24px;
        opacity: 1;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 1400px) {
    position: relative;

    a {
      &:after {
        left: -32px;
      }
    }
  }
`;
