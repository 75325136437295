import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiFillLike,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import useDragDetection from 'hooks/useDragDetection';
import { addBookMark, removeBookMark } from 'services/bookmark';
import CourseImage from 'assets/banner-image.png';

import { toast } from 'react-toastify';

import {
  Container,
  BookmarkButton,
  CourseDetails,
  CourseTime,
  CourseLikes,
  ContainerFlex,
} from './style';
import { secondsToTimeText } from 'helpers/time';
import Course from 'models/course';

interface ICourseProps {
  course: Course;
  style?: any;
}

const BannerCard: React.FC<ICourseProps> = ({ course, style }) => {
  const history = useHistory();
  const [addedToList, setAddedToList] = useState(course.addedToList);
  const { handleMouseDown, dragging } = useDragDetection();

  const goToCourse = () => {
    history.push(`/course/${course.id}`);
  };

  function handleChildClick(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void {
    if (dragging) {
      e.stopPropagation();
    }
  }

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToList = !course.addedToList;

        if (shouldAddToList) {
          await addBookMark(course.id);
        } else {
          await removeBookMark(course.id);
        }

        course.addedToList = shouldAddToList;
        setAddedToList(course.addedToList);
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  return (
    <Container
      style={style}
      onClick={goToCourse}
      onMouseDownCapture={handleMouseDown}
      onClickCapture={handleChildClick}
    >
      <ContainerFlex>
        <img
          src={course.thumbnailUrl ? course.thumbnailUrl : CourseImage}
          alt="Course"
        />

        <CourseDetails>
          <BookmarkButton onClick={addToList}>
            <BsBookmark
              color="#FFF"
              className={`${addedToList && 'favorite'}`}
            />
          </BookmarkButton>

          <h3>{course.title}</h3>

          <div>
            <CourseTime>
              <AiOutlineClockCircle />
              <span>{`${secondsToTimeText(course.duration)}`}</span>
            </CourseTime>
            <span className="divider" />
            <CourseLikes>
              {course.userLiked ? (
                <AiFillLike color="#fff" />
              ) : (
                <AiOutlineLike color="#fff" />
              )}

              <span>
                {course.numberOfLikes &&
                  `${course.numberOfLikes} ${
                    course.numberOfLikes === 1 ? 'curtida' : 'curtidas'
                  }`}
              </span>
            </CourseLikes>
          </div>
        </CourseDetails>
      </ContainerFlex>
    </Container>
  );
};

export default BannerCard;
