import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 78px;
  padding-top: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);

  h2 {
    font-size: var(--font-largest);
    margin-bottom: 66px;
  }

  div.slick-list {
    max-width: 90%;
    margin: 0 auto;
    padding-left: 0px;
  }

  button {
    width: 56px;
    height: 56px;
  }

  @media (max-width: 768px) {
    margin: 24px 0 32px;

    button.slick-arrow {
      width: 24px;
      height: 24px;
      top: -38px;

      &.slick-prev {
        right: 60px;
      }

      svg {
        width: 10px;
      }
    }

    h2 {
      font-size: var(--font-default);
      margin-bottom: 16px;
    }
  }
`;
