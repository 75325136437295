import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultButton from '../../components/Buttons/DefaultButton';

import Logo from 'assets/logo.svg';
import Footer from 'components/Footer';

import {
  DefaultCreationForm,
  DefaultCreationFormGroup,
  DefaultInput,
  Cert,
  Container,
  Content,
  BrandContent,
  OptionsContent,
  Title,
  Description,
} from './style';
import { ValideteId } from '../../services/certificate';
import CertificateCard from '../../components/CertificateCard';
import { toast } from 'react-toastify';
import { DefaultModal } from 'components/DefaultModal';

const Certificate: React.FC = () => {
  const [certificateId, setCertificateId] = useState('');
  const [validCertificate, setValidCertificate] = useState([] as any[]);
  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const validateId = async (certificate: string, event: any) => {
    event.preventDefault();
    try {
      const certificateData = await ValideteId(certificate.trim());
      setValidCertificate([certificateData]);
    } catch {
      setCertificateId('');
      setValidCertificate([]);
      setIsOpen(true);
    }
  };

  const inputCheck = (certificate: string) => {
    if (certificate.length === 36) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <Container>
        <Content>
          <BrandContent className="logo">
            <img src={Logo} alt="Logo" />
          </BrandContent>

          <OptionsContent>
            <Title>Certificados</Title>

            <DefaultCreationForm>
              <DefaultCreationFormGroup>
                <DefaultInput
                  placeholder="Código do certificado"
                  name={'test'}
                  maxLength={40}
                  type="string"
                  value={certificateId.trim()}
                  onChange={e => {
                    setCertificateId(e.target.value.trim());
                  }}
                  id="Certificate-id"
                  required
                />

                <DefaultButton
                  disabled={inputCheck(certificateId.trim())}
                  typeof="submitt"
                  className={`${
                    inputCheck(certificateId.trim()) ? 'disabled' : 'success'
                  }`}
                  onClick={e => validateId(certificateId, e)}
                >
                  Verificar Certificado
                </DefaultButton>
              </DefaultCreationFormGroup>
            </DefaultCreationForm>
            <Cert>
              {validCertificate && validCertificate.length !== 0 && (
                <CertificateCard course={validCertificate[0]} verify={true} />
              )}
            </Cert>
          </OptionsContent>
        </Content>
        <DefaultModal
        modalIsOpen={modalIsOpen}
        setIsOpen={() => setIsOpen(modalIsOpen)}
        closeModal={closeModal}
      >
        <h1>Certificado não encontrado</h1>
        <p>
          Favor verificar o código inserido
        </p>
        <DefaultButton onClick={closeModal}>OK, entendi</DefaultButton>
      </DefaultModal>
      </Container>
      <Footer />
    </>
  );
};

export default Certificate;
