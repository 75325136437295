import React from 'react';
import { CgImage } from 'react-icons/cg';
import { FiDownload, FiAward } from 'react-icons/fi';

import {
  downloadCertificate as downloadCertificateService,
  showCertificate as showCertificateService
} from '../../services/certificate';
import Certificado from '../../assets/certificado.svg';

import { toast } from 'react-toastify';

import {
  Container,
  CertificateImage,
  ContentContainer,
  ButtonsContainer,
} from './style';

interface ICertificateCardProps {
  course: any;
}

const CourseCertificate: React.FC<ICertificateCardProps> = ({ course }) => {
  const downloadCertificate = async (courseId: string) => {
    try {
      await downloadCertificateService(courseId);
    } catch (e) {
      toast.error('Erro ao baixar certificado.');
    }
  };
  const showViewCertificate = async (courseId: string) => {
    const certificate = await showCertificateService(courseId);
    const openCertificate = window.open() as Window;
    openCertificate.location.href = URL.createObjectURL(certificate);
  };
  return (
    <Container>
      <CertificateImage>
        <img src={Certificado} alt="Certificate" />
      </CertificateImage>

      <ContentContainer>
        <h1>{course.title}</h1>

        <ButtonsContainer>
          <button onClick={() => showViewCertificate(course.id)}>
            <CgImage />
            Visualizar
          </button>
          <span />
          <button onClick={() => downloadCertificate(course.id)}>
            <FiDownload />
            Baixar
          </button>
        </ButtonsContainer>
      </ContentContainer>
    </Container>
  );
};

export default CourseCertificate;
