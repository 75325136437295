import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: var(--third-color);
  position: absolute;
  top: 40%;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &.slick-disabled {
    background: var(--default-gray);
  }
`;
