import styled from 'styled-components';

export const Container = styled.div`
  width: 360px;
  min-height: 239px;
  padding: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;

  > img {
    margin-bottom: 16px;
  }
  p {
    color: var(--regular-gray);
    display: block;
    display: -webkit-box;
    line-height: 20px;
    min-height: 100px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
  }
  .showAll {
    display: inline;
    text-overflow: auto;
  }

  @media (max-width: 768px) {
    width: 258px;
    height: 182px;
    padding: 18px 20px;

    > img {
      width: 20px;
    }

    p {
      font-size: var(--font-small);
      line-height: 16px;
    }
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  svg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 0px 8px 4px 0px;
    object-fit: cover;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    margin-top: 14px;

    img {
      width: 32px;
      height: 32px;
    }
  }
`;

export const DepositionAuthor = styled.span`
  font-weight: bold;
  font-size: var(--font-regular);
  display: block;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: var(--font-min);
  }
`;

export const DepositionOffice = styled.span`
  font-size: var(--font-small);
  color: var(--dark-gray);

  @media (max-width: 768px) {
    font-size: var(--font-smallest);
  }
`;
