import styled from 'styled-components';

const CourseCategoriesContainer = styled.div`
  width: 100vw;
  margin: 12px 0px;
  padding: 0 16px;
  h1 {
    font-size: var(--font-max2);
    color: var(--dark-gray);
  }
  h2 {
    font-size: var(--font-largest);
    color: var(--dark-gray);
    margin: 16px 0;
  }
  @media (max-width: 780px) {
    margin: 0px;
    padding: 0px;
    h1 {
      font-size: 24px;
      margin-bottom: 44px;
      position: relative;
      &:after {
        content: '';
        width: 116px;
        opacity: 1;
        height: 7px;
        bottom: -12px;
        left: 0;
        position: absolute;
        transition: all 0.3s ease-in-out;
        background: var(--secondary-color);
      }
    }
    h2 {
      font-size: 20px;
    }
  }
  .slick-arrow {
    display: flex;
    flex-direction: row;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 5;
    &.slick-prev {
      cursor: pointer;
      bottom: 100px;
      left: -80px;
      background-color: var(--gray-darker);
      border-radius: 100px;
      padding: 10px;
      @media (max-width: 780px) {
        visibility: hidden;
      }
      :hover {
        background-color: #0054a6;
      }
    }
    &.slick-next {
      cursor: pointer;
      top: 100px;
      right: -80px;
      background-color: var(--gray-darker);
      border-radius: 100px;
      padding: 10px;
      @media (max-width: 780px) {
        visibility: hidden;
      }
      :hover {
        background-color: #0054a6;
      }
    }
  }
`;

const CourseCategoriesContent = styled.div`
  padding-left: 80px;
  margin-right: 100px;
  @media (max-width: 768px) {
    padding: 0px;
    margin: 25px 0px;
  }
`;

const CategoryCardContainer = styled.div`
  display: flex;
  width: 210px;
  height: 270px;
  padding: 8px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  background-color: ${props => props.color || '#1d2680'};
  cursor: pointer;
  & img {
    width: 100%;
  }
  & h3 {
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 1em;
    max-width: 16ch;
    overflow: scroll;
    text-overflow: ellipsis;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;

const Slider = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;
  }
`;

export {
  CourseCategoriesContainer,
  CourseCategoriesContent,
  CategoryCardContainer,
  Slider,
};
