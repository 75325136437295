import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import SliderNextButton from 'components/SliderButtons/SliderNextButton';
import SliderPrevButton from 'components/SliderButtons/SliderPrevButton';
import CourseCardWithProgress from 'components/CourseCardWithProgress';

import { progressCoursesSettings } from 'config/sliderSettings';

import { Container } from './style';
import { toast } from 'react-toastify';
import ModalSkeleton from '../Skeleton/Cards';
import { getOngoingCourses } from 'services/course';

const SuggestedCourses: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedCourses, setSuggestedCoursesCourses] = useState([] as any[]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const localCourses = await getOngoingCourses();
        if (localCourses && localCourses.length) {
          setSuggestedCoursesCourses(localCourses);
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos em andamento.');
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <Container>
      <h2>Cursos em andamento</h2>
      <Slider
        {...progressCoursesSettings}
        prevArrow={<SliderPrevButton />}
        nextArrow={<SliderNextButton />}
      >
        {isLoading ? (
          <ModalSkeleton />
        ) : suggestedCourses && suggestedCourses.length ? (
          suggestedCourses.map(course => (
            <CourseCardWithProgress key={course.trail_id} course={course} />
          ))
        ) : (
          <h4 style={{ width: '100%', padding: '15px' }}>
            Não foram encontrados cursos sugeridos.
          </h4>
        )}
      </Slider>
    </Container>
  );
};

export default SuggestedCourses;
