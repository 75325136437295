import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { Container, InputContent, Error } from './style';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  label?: string;
  description?: string;
  className?: string;
}

const DefaultInput: React.FC<InputProps> = ({
  label,
  description,
  name,
  className,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <p>{description}</p>
      <InputContent className={className}>
        <input {...rest} id={name} ref={inputRef} />
      </InputContent>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default DefaultInput;
