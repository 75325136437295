import download from 'downloadjs';
import httpClient from '../../http-client';
import { generateCertificate } from '../../pdf/generateCertificate';

export interface CertificateData {
  trail_duration: number;
  trail_name: string;
  user_name: string;
  certificate_id: string;
  emitted_at: string;
  reference_duration: number;
  trail_finish_at: string;
  trail_id: string;
  reference_name: string;
  trail_category: string;
  reference_id: string;
}

export interface ICheckApproved {
  isApproved: boolean,
  exam_id: string
}

const ValideteId = async (certificate_id: string) => {
  const certificate = (
    await httpClient.get(`certificates/verify/${certificate_id}`)
  ).data;
  return certificate;
};

const downloadCertificate = async (trailId: string, certificate?: any) => {
  if (certificate) {
    const certificateB = await generateCertificate(certificate);

    download(certificateB, 'certificado');
  } else {
    const certificateData = (
      await httpClient.get<CertificateData>(`certificates/${trailId}`)
    ).data;

    const certificateB = await generateCertificate(certificateData);

    download(certificateB, 'certificado');
  }
};

const showCertificate = async (trailId: string, certificate?: any) => {
  if (certificate) {
    return await generateCertificate(certificate);
  } else {
    const certificateData = (
      await httpClient.get<CertificateData>(`certificates/${trailId}`)
    ).data;

    return await generateCertificate(certificateData);
  }
};

const getAllCertificates = async () => {
  const certificates = (
    await httpClient.get<CertificateData[]>('/certificates')
  ).data;

  return certificates;
};

const setTrailCertificate = async (trailId: string) => {
  const certificateResponse = (
    await httpClient.post<CertificateData>(`certificates`, {
      trail_id: trailId,
    })
  ).data;
  return certificateResponse;
};

const setContentCertificate = async (contentId: string) => {
  const certificateResponse = (
    await httpClient.post<CertificateData>(`certificates`, {
      content_id: contentId,
    })
  ).data;

  return certificateResponse;
};
const createCertificate = async (trailId: string) => {
  return (await httpClient.post<CertificateData>(`/certificates/${trailId}`))
    .data;
};

const findOne = async (certificate_id: string) => {
  const certificate = (
    await httpClient.get<CertificateData[]>('/certificates', {
      params: certificate_id,
    })
  ).data;

  return certificate;
};

const getCourseApproval = async (courseId: string): Promise<ICheckApproved[]> => {
  const exams = (await httpClient.get(`/exam-users/is-approved/${courseId}`)).data;

  return exams;
};

export {
  findOne,
  createCertificate,
  downloadCertificate,
  getAllCertificates,
  setTrailCertificate,
  setContentCertificate,
  showCertificate,
  ValideteId,
  getCourseApproval,
};
