import httpClient from '../../http-client';

const addContentBookmark = async (courseId: string) => {
  const response = (
    await httpClient.post(`/content-book-marks`, {
      content_id: courseId,
    })
  ).data;

  return response;
};

const removeContentBookmark = async (courseId: string) => {
  const response = (await httpClient.delete(`/content-book-marks/${courseId}`))
    .data;

  return response;
};

const addBookMark = async (trailId: string) => {
  const addBookMarkResponse = (
    await httpClient.post(`/book-marks`, {
      trail_id: trailId,
    })
  ).data;

  return addBookMarkResponse;
};

const removeBookMark = async (trailId: string) => {
  const removeBookMarkResponse = (
    await httpClient.delete(`/book-marks/${trailId}`)
  ).data;

  return removeBookMarkResponse;
};

const addBookMarkLive = async (liveId: string) => {
  const addBookMarkResponse = (
    await httpClient.post(`/live-book-marks`, {
      live_id: liveId,
    })
  ).data;

  return addBookMarkResponse;
};

const removeBookMarkLive = async (liveId: string) => {
  const removeBookMarkResponse = (
    await httpClient.delete(`/live-book-marks/${liveId}`)
  ).data;

  return removeBookMarkResponse;
};

export {
  addContentBookmark,
  removeContentBookmark,
  addBookMark,
  removeBookMark,
  addBookMarkLive,
  removeBookMarkLive
};
