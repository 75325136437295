import styled from 'styled-components';

export const CourseCardWithProgressWrapper = styled.div`
  border: 1px solid var(--default-gray);
  width: 320px;
  height: 200px;
  margin-right: 32px;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  button.grey-background {
    background-color: var(--light-gray);
  }

  div.progress {
    display: flex;
    gap: 10px;

    div.bar {
      width: 100%;
    }

    h6 {
      color: var(--medium-gray);
      font-weight: 200;
      font-size: var(--font-regular);
      float: right;
      margin-top: 9px;
    }
  }

  h1 {
    font-size: var(--font-large-medium);
    font-weight: 700;
    line-height: 22px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  span {
    color: var(--secondary-color);
    font-weight: 700;
    font-size: var(--font-regular);
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const CourseInfo = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;

  div.info {
    display: flex;
  }

  div.divider {
    margin: 0 10px;
    height: 18px;
    background-color: var(--medium-gray);
    width: 1px;
  }

  h3 {
    color: var(--medium-gray);
    font-weight: 200;
    font-size: var(--font-regular);
  }

  div.align {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 9px;
  }
`;

export const BookmarkButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  top: 13px;
  right: 14px;
  z-index: 1;
  padding: 5px;
  border-radius: 6px;

  svg.favorite {
    border: var(--default-gray);
  }
`;
