import httpClient from '../../http-client';

const startLesson = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
) => {
  await httpClient.post('/trail-course-content-users', {
    trail_id: courseId,
    course_id: moduleId,
    content_id: lessonId,
  });
};

const finishLesson = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
) => {
  await httpClient.put('/trail-course-content-users/finish', {
    trail_id: courseId,
    course_id: moduleId,
    content_id: lessonId,
  });
};

export { startLesson, finishLesson };
