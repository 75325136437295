import styled, { keyframes } from 'styled-components';

const transition = keyframes`
  from {
    margin-left: -200px;
  }
  to {
     margin-left: 0;
  }
`;

export const ContainerMobile = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;

  nav {
    animation: ${transition} 0.4s ease;
  }
`;

export const HeaderContainer = styled.header`
  background-color: var(--backgroundWhite);
  width: 100vw;
  height: 96px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;

export const MenuContainerLinks = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 56px;
  justify-content: space-evenly;
  background: transparent;

  > img.logoMobile {
    display: none;
  }

  div.profile {
    display: none;

    @media (max-width: 768px) {
      display: initial;
    }
  }
  a {
    padding: 0;
    position: relative;
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-large);
    color: #000;

    &:after {
      content: '';
      width: 0%;
      opacity: 0;
      height: 7px;
      bottom: 0;
      top: 50px;
      left: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;
      background: var(--secondary-color);

      @media (max-width: 992px) {
        top: 42px;
      }
    }
  }

  a:hover,
  a.active {
    font-weight: 500;
    max-width: 100%;
    color: var(--secondary-color);

    &:after {
      width: 100%;
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  img {
    display: flex;
    align-items: center;
    width: 80px;
  }

  img.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    padding: 0;

    a.logo {
      padding: 0 10px;

      img.logoImg {
        width: 48px;
        height: 48px;
      }
    }
  }

  @media (max-width: 992px) {
    a {
      height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const HeaderUtilities = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 100%;
  width: 40%;

  svg.menu-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;

    svg.menu-mobile {
      display: initial;
      color: var(--primary-color);
      margin-right: 10px;
    }

    form.search-input {
      width: 80%;
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    width: 50%;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  margin-left: 48px;
  transition: all 0.3s ease-in-out;
  border-width: 0%;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    border: none;
  }

  &:after {
    content: '';
    width: 0%;
    opacity: 0;
    height: 7px;
    bottom: 0;
    left: 0;
    position: absolute;

    background: var(--secondary-color);
  }

  &.active {
    span {
      font-weight: 500;
    }

    &:after {
      width: 100%;
      opacity: 1;
    }
  }

  span {
    font-size: var(--font-large);
    margin-right: 10px;
  }

  svg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  nav {
    visibility: hidden;
    transition: visibility 0.1s;
  }

  &:hover {
    nav {
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    display: none;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
    }

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: var(--font-smallest);
      color: var(--regular-gray);
      font-weight: 400;
      margin: 12px auto 0;
    }

    &.active {
      span {
        color: #000;
      }

      &:after {
        height: 4px;
        top: 0;
      }
    }
  }
`;

export const CloseMenu = styled.button`
  position: absolute;
  bottom: 20px;
  left: 70%;
  border-radius: 50%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  border: none;
  background: var(--primary-color);
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  z-index: 10;

  @media (max-width: 768px) {
    display: initial;
  }
`;

export const ContainerInput = styled.div`
  img {
    display: none;
  }

  svg {
    display: none;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;

    img {
      display: initial;
      position: absolute;
      bottom: 0;
    }

    svg {
      display: initial;
      position: absolute;
      bottom: 0;
    }
  }
`;
