import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback 
} from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { secondsToTimeText } from '../../../../helpers/time';
import Module from '../../../../models/module';
import CourseContext from '../../Context';
import ModuleLessons from '../../components/CourseModules/components/ModuleLessons';
import {
  CourseModuleContainer,
  CourseModuleLowerContent,
  CourseModulesContainer,
  CourseModuleUpperContent,
  ModuleDuration,
  ModuleTitle,
} from './styles';

interface CourseModuleProps {
  module: Module;
}

const CourseModule: React.FC<CourseModuleProps> = ({ module }) => {
  const { selectedLesson, course, selectedLessonModule } = useContext(CourseContext);

  const [opened, setOpened] = useState(selectedLessonModule.isFirstOfCourse);
  const [moduleOpen, setModuleOpen] = useState(false);

  const handleOpenAccordion = useCallback(() => {
    course.modules.map((lesson) => {
      if (lesson.id === selectedLessonModule.id) {
        setModuleOpen(true);
      }
      return [];
    });
  }, []);

  const moduleDuration = useMemo(() => {
    return module.lessons
      .map(lesson => lesson.duration)
      .reduce(
        (prevLessonDuration, currLessonDuration) =>
          prevLessonDuration + currLessonDuration,
      );
  }, [module]);

  const toogleOpened = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    if (
      module.lessons &&
      module.lessons.some(
        lesson =>
          lesson.id === selectedLesson.id &&
          lesson.moduleId === selectedLesson.moduleId &&
          lesson.courseId === selectedLesson.courseId,
      )
    ) {
      setOpened(true);
    }
  }, [module, selectedLesson]);

  useEffect(() => {
    handleOpenAccordion();

    if (!moduleOpen) {
      setOpened(false);
    }
  }, [moduleOpen, handleOpenAccordion]);

  return (
    <CourseModuleContainer>
      <CourseModuleUpperContent
        onClick={toogleOpened}
        className={opened ? 'opened' : 'closed'}
      >
        <ModuleTitle>
          Módulo {module.position} - {module.title}
        </ModuleTitle>
        <ModuleDuration>{secondsToTimeText(moduleDuration)}</ModuleDuration>
        {!opened ? <AiOutlineDown /> : <AiOutlineUp />}
      </CourseModuleUpperContent>
      {opened && (
        <CourseModuleLowerContent>
          <ModuleLessons module={module} />
        </CourseModuleLowerContent>
      )}
    </CourseModuleContainer>
  );
};

const CourseModules: React.FC = () => {
  const { course } = useContext(CourseContext);
  return (
    <CourseModulesContainer>
      {course.modules && course.modules.length ? (
        course.modules.map(module => (
          <CourseModule key={module.id} module={module} />
        ))
      ) : (
        <></>
      )}
    </CourseModulesContainer>
  );
};

export { CourseModules };
