import ExtraMaterial from '../../models/extraMaterials';
import ExtraMaterialFromResponse from '../../models/from-api/extraMaterials';

const convertToExtraMaterial = (
  extraMaterial: ExtraMaterialFromResponse,
): ExtraMaterial => {
  return {
    name: extraMaterial.name,
    reference: extraMaterial.reference,
    id: extraMaterial.extra_material_id,
  };
};

export default convertToExtraMaterial;
