import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ConfirmData from './Routes/ConfirmData';
import ResetPassword from './Routes/ResetPassword';

const ForgotPassword: React.FC = () => {
  return (
    <Switch>
      <Route path="/forgot-password" exact component={ConfirmData} />
      <Route path="/forgot-password/reset-password" component={ResetPassword} />
    </Switch>
  );
};

export default ForgotPassword;
