import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiUser, FiPlayCircle, FiBookmark, FiAward } from 'react-icons/fi';

import { Container } from './style';

const ProfileMenu: React.FC = () => {
  const { pathname, state } = useLocation();

  const routes = [
    {
      path: '/profile',
      description: 'Meus dados',
      Icon: () => <FiUser size={24} />,
    },
    {
      path: '/ongoing-courses',
      description: 'Em andamento',
      Icon: () => <FiPlayCircle size={24} />,
    },
    {
      path: '/favorite-courses',
      description: 'Favoritos',
      Icon: () => <FiBookmark size={24} />,
    },
    {
      path: '/certificates',
      description: 'Certificados',
      Icon: () => <FiAward size={24} />,
    },
  ];

  return (
    <Container>
      {routes.map(route => (
        <Link
          key={route.path}
          to={{
            pathname: route.path,
            state: {
              menuData: false,
            },
          }}
          className={`${pathname === route.path && 'active'}`}
        >
          <route.Icon />
          {route.description}
        </Link>
      ))}
    </Container>
  );
};

export default ProfileMenu;
