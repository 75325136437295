import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import User from 'models/user';

import { Container } from './style';

import { signIn as SignInService } from 'services/signIn';

import HeaderLogo from 'assets/logo.svg';
import DefaultInput from 'components/Inputs/DefaultInput/DefaultInput';
import DefaultButton from 'components/Buttons/DefaultButton';
import InputPassword from 'components/Inputs/InputPassword';
import BackButton from 'components/Buttons/BackButton';
import InputCheckbox from 'components/Inputs/InputCheckbox';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'helpers/getValidationErrors';
import { getResponsibilityTerm, getPrivacyTerm } from 'services/acceptTerms';

type TermType = {
  id?: string;
  title?: string;
  description: string;
  created_at?: string;
  updated_at?: string;
  type?: string | null;
  filename?: string | null;
  reference?: string;
};

const schema = Yup.object().shape({
  email: Yup.string().trim().required('Preencha corretamente este campo.'),
  password: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.')
    .min(8, 'A senha deve conter no mínimo 8 caracteres.'),
  remember: Yup.boolean(),
});

interface ISignInProps {
  email: string;
  password: string;
}

interface ISignInState {
  isSSO: boolean;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { state } = useLocation<ISignInState>();

  const handleSubmit = async (data: ISignInProps) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      if (state && state.isSSO) {
        //implement SSO
      } else {
        await SignInService(data).then(async () => {
          const user: User = JSON.parse(
            localStorage.getItem('dorconsultoria-user') as string,
          );
          const responsibilityTerm: TermType[] | [] =
            await getResponsibilityTerm();
          const privacyTerm: TermType[] | [] = await getPrivacyTerm();
          if (
            (!!user.privacy_statement === false && privacyTerm[0]) ||
            (!!user.term_of_responsibility === false && responsibilityTerm[0])
          ) {
            history.push('/accept-terms', { user });
          } else {
            history.push('/home');
          }
        });
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Container>
      <div className="return-btn">
        <BackButton />
      </div>

      <img src={HeaderLogo} alt="logo" />

      <h3>Entrar</h3>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <DefaultInput name="email" type="text" label="E-mail" required />
        <InputPassword
          name="password"
          label="Senha"
          onKeyPress={event => {
            if (event.key === 'Enter') {
              formRef.current?.submitForm();
            }
          }}
        />

        <div className="container-select">
          <InputCheckbox label="Lembrar login" name="remember" />

          <Link to="/forgot-password">Esqueceu sua senha?</Link>
        </div>

        <DefaultButton type="submit">Entrar</DefaultButton>
      </Form>
    </Container>
  );
};

export default SignIn;
