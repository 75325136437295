import React from 'react';

import ProgressBar from 'components/ProgressBar';
import { getProgressPercentage } from 'helpers/getProgress';
import { ContainerWrapper } from './style';

interface ICourseProps {
  contentView: number;
  duration: number;
}

const ProgressBarToCardItem: React.FC<ICourseProps> = ({
  contentView,
  duration,
}) => {
  return (
    <ContainerWrapper>
      <div>
        <ProgressBar
          completionPercentage={Number(
            getProgressPercentage(Number(contentView), Number(duration)),
          )}
        />
      </div>
      <h6>{getProgressPercentage(Number(contentView), Number(duration))}%</h6>
    </ContainerWrapper>
  );
};

export default ProgressBarToCardItem;
