import React from 'react';
import CardItemSkeleton from '../CardItemSkeleton';

import { Container } from './style';

const GridShimmer: React.FC = () => {
  return (
    <Container>
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
    </Container>
  );
};

export default GridShimmer;
