import { CertificateData } from 'services/certificate';
import CertificateTemplate from '../templates/CertificateTemplate';
import { pdf } from '@react-pdf/renderer';

const generateCertificate = (CertificateData: CertificateData) => {
  const DorCertificateTemplate = (
    <CertificateTemplate certificateData={CertificateData} />
  );

  return pdf(DorCertificateTemplate).toBlob();
};

export { generateCertificate };
