import styled from 'styled-components';

export const Container = styled.div``;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  input,
  textarea {
    font-size: var(--default-font);
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    outline: none;
    border: solid 1px var(--dark-gray);
    background: #f2f2f2;
    color: var(--dark-gray);
  }

  *:not(:first-child) {
    margin: 10px 0 32px auto;
  }
`;

export const AnnotationList = styled.div`
  margin: 35px 0 40px;
  border-top: 1px solid #d9d9d9;
  padding-top: 32px;
`;

export const FormTitle = styled.input`
  height: 40px;
`;

export const FormTextArea = styled.textarea`
  height: 104px;
  resize: none;
`;

export const Label = styled.span`
  font-size: 1.6rem;
`;

export const SubmitButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  background-color: var(--secondary-color);
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: var(--white-font-color);
  cursor: pointer;
`;

export const Annotation = styled.div`
  &:not(:first-child) {
    border-top: 1px solid var(--neutralLight40);
    padding-top: 30px;
    margin-top: 30px;
  }
`;

export const AnnotationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnnotationTitle = styled.h3`
  font-size: 18px;
  color: #000;
`;

export const AnnotationDate = styled.p`
  font-size: 14px;
  margin-top: 3px;
  color: #444;
`;

export const AnnotationContent = styled.p`
  font-size: 16px;
  margin-top: 15px;
  color: #000;
`;

export const AnnotationButtonContainer = styled.div``;

export const AnnotationButton = styled.button`
  width: 36px;
  height: 36px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #f0f3f6;
  padding: 2px;
  border-radius: 2px;
  color: #97a0af;

  &:not(:first-child) {
    margin-left: 5px;
  }
`;
