import React from 'react';
import Course from '../../../models/course';
import Lesson from '../../../models/lesson';
import Module from '../../../models/module';

interface CourseContextProps {
  course: Course;
  setCourse: (course: Course) => void;
  selectedLessonModule: Module;
  selectedLesson: Lesson;
  startCourse: () => Promise<void>;
  startLesson: (isPreview?: boolean) => Promise<void>;
  finishLesson: (
    justModuleAndLesson?: boolean,
    isPreview?: boolean,
  ) => Promise<void>;
  goToNextLesson: () => void;
  updateContentProgress: (
    time: number | null,
    isPreview?: boolean,
  ) => Promise<void>;
  userIsApproved?: boolean;
}

const CourseContext = React.createContext<CourseContextProps>({
  course: {} as Course,
  setCourse: () => {},
  selectedLessonModule: {} as Module,
  selectedLesson: {} as Lesson,
  startCourse: async () => {},
  startLesson: async () => {},
  finishLesson: async () => {},
  goToNextLesson: () => {},
  updateContentProgress: async () => {},
});

export default CourseContext;
