import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 8px;

  div {
    width: 100%;
  }

  & .course-progress-bar {
    border: 1px solid var(--default-gray);
    background-color: var(--light-gray);
  }

  h6 {
    color: var(--medium-gray);
    font-weight: 200;
    font-size: var(--font-regular);
    float: right;
    margin-top: 9px;
  }
`;
