import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.alert('Você está desconectado, faça login novamente.');
      window.localStorage.removeItem('dorconsultoria-api-token');
      window.localStorage.removeItem('dorconsultoria-user');
      
      window.location.href = `${window.location.origin}`;
    } else {
      return Promise.reject(error);
    }
  },
);

httpClient.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('dorconsultoria-api-token');
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default httpClient;
