import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import SliderNextButton from 'components/SliderButtons/SliderNextButton';
import SliderPrevButton from 'components/SliderButtons/SliderPrevButton';
import CourseCardItem from 'components/CourseCardItem';

import { coursesSliderSettings } from 'config/sliderSettings';

import { Container } from './style';
import { toast } from 'react-toastify';
import ModalSkeleton from '../Skeleton/Cards';
import { getCourses } from 'services/course';

const MoreWatchedCourses: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mostWatchedCourses, setMostWatchedCourses] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const mostViewedFilters = { most_viewed: false };
        const localCourses = await getCourses(mostViewedFilters);
        if (localCourses) {
          setMostWatchedCourses(localCourses);
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos mais assistidos.');
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <Container>
      <h2>Cursos mais assistidos</h2>

      <Slider
        {...coursesSliderSettings}
        prevArrow={<SliderPrevButton />}
        nextArrow={<SliderNextButton />}
      >
        {isLoading ? (
          <ModalSkeleton />
        ) : mostWatchedCourses && mostWatchedCourses.length ? (
          mostWatchedCourses
            .slice(0, 5)
            .map(course => <CourseCardItem key={course.id} course={course} />)
        ) : (
          <h4>Não foram encontrados os cursos mais assistidos.</h4>
        )}
      </Slider>
    </Container>
  );
};

export default MoreWatchedCourses;
