import httpClient from '../../http-client';

const getReviews = async () => {
  const depositions = (
    await httpClient.get('/trails/review/trail?show_to_all_users=true')
  ).data;

  return depositions;
};

const reviewContent = async (
  contentId: string,
  description: string,
  stars: number,
) => {
  await httpClient.post(`/content-user-reviews`, {
    content_id: contentId,
    description: description,
    stars,
  });
};

export { reviewContent, getReviews };
