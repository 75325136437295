import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { useField } from '@unform/core';

import { Container, InputContent, Error } from './style';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  label?: string;
  description?: string;
  className?: string;
  mask: string;
  default_value?: string;
}

const InputWithMask: React.FC<InputProps> = ({
  label,
  description,
  name,
  className,
  mask,
  default_value,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error, defaultValue } = useField(name);

  const maskProps = {
    maskChar: '',
    alwaysShowMask: false,
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <p>{description}</p>
      <InputContent className={className}>
        <InputMask
          {...maskProps}
          {...rest}
          id={name}
          ref={inputRef}
          mask={mask}
          defaultValue={default_value}
        />
      </InputContent>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default InputWithMask;
