import styled from 'styled-components';

export const Container = styled.div`
  font-weight: 500;

  p {
    font-size: var(--font-small);
    color: var(--regular-gray);
    margin-bottom: 4px;
  }

  @media (max-width: 768px) {
    label {
      font-size: var(--font-regular);
    }

    p {
      font-size: var(--font-min);
    }
  }
`;

export const InputContent = styled.div`
  width: 100%;
  height: 48px;
  padding-right: 16px;
  border-radius: 6px;
  border: none;
  background-color: var(--light-gray);
  display: flex;
  align-items: center;
  margin-top: 4px;

  input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding: 16px;
    font-size: var(--font-default);
  }

  @media (max-width: 768px) {
    input {
      font-size: var(--font-regular);
    }
  }
`;

export const Error = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ff3030;
  margin-top: 4px;
  display: block;
`;
