import styled from 'styled-components';

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 40px;

  h1 {
    font-size: var(--font-largest);
    color: #25282b;
    text-align: center;
  }

  p {
    font-size: var(--font-large);
    text-align: center;
    color: #25282b;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    gap: 12px;

    h1 {
      font-size: 20px;
      text-align: center;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
`;
