import React, { useEffect, useState } from 'react';
import { HomeContainer } from './style';
import Depositions from './components/Depositions';
import OngoingCourses from './components/OngoingCourses';
import RecommendedCourses from './components/RecommendedCourses';
import MoreWatchedCourses from './components/MoreWatchedCourses';
import Banner from './components/Banner';
import PreFooter from 'components/PreFooter';
import { login as loginService } from '../../services/signIn';
import { getActualUser } from '../../services/user';
import { findOne } from 'services/certificate';

const Home: React.FC = () => {
  const [logado, setLogado] = useState(false);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('dorconsultoria-api-token') === null) {
        try {
          await loginService();
          setLogado(true);
          const userData = await getActualUser();
          localStorage.setItem('dorconsultoria-user', JSON.stringify(userData));
        } catch (error) {
          window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`;
        }
      } else {
        setLogado(true);
      }
    })();
  }, []);

  return logado ? (
    <HomeContainer>
      <div className="content">
        <Banner />
        <OngoingCourses />
        <MoreWatchedCourses />
        <RecommendedCourses />
        <Depositions />
      </div>
      <PreFooter />
    </HomeContainer>
  ) : (
    <></>
  );
};

export default Home;
