import convertToCourse from 'helpers/converters/convert-course';
import Course from 'models/course';
import CourseFromApi from 'models/from-api/course';
import React, { createContext, useCallback, useState, useEffect } from 'react';

import { searchCourses } from '../services/course';

interface ISeachProviderProps {
  searchParam: string;
  setSearchParam: React.Dispatch<React.SetStateAction<string>>;
  searchResults: Course[];
  isSearching: boolean;
  trails?: Course[];
}

export const SearchContext = createContext<ISeachProviderProps>(
  {} as ISeachProviderProps,
);

export const SearchProvider: React.FC = ({ children }) => {
  const [searchParam, setSearchParam] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [trails, setTrails] = useState([] as Course[]);

  const searchRequest = useCallback(async () => {
    try {
      setIsSearching(true);

      const response = await searchCourses(searchParam);

      let convertedTrails = response.filter(
        (trail: CourseFromApi) => trail.is_active === true,
      );

      convertedTrails = await Promise.all(
        convertedTrails.map((course: CourseFromApi) => convertToCourse(course)),
      );

      setTrails(convertedTrails);
    } catch (e) {}

    setIsSearching(false);
  }, [searchParam]);

  useEffect(() => {
    if (searchParam !== '') {
      searchRequest();
    }
  }, [searchParam, searchRequest]);

  return (
    <SearchContext.Provider
      value={{
        searchParam,
        setSearchParam,
        isSearching,
        trails,
        searchResults: trails,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
