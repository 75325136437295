import styled from 'styled-components';

const LessonAnnotationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LessonAnnotationsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;

  & .actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }

  & input {
    border: solid 1px var(--default-dark-gray);
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    outline: none;
  }

  & textarea {
    border: solid 1px var(--default-dark-gray);
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    resize: vertical;
    outline: none;
  }

  & button {
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
  }

  & button[type='submit'] {
    background: #0054A6;
    color: white;
  }

  & button[type='button'] {
    background: var(--medium-gray);
    color: var(--darker-gray);
  }

  @media (max-width: 768px){
    max-width: 90%;
  }
`;

const LessonAnnotationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px){
    max-width: 85%;
  }
`;

const AnnotationCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--default-dark-gray);
`;

const AnnotationTitleAndActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnnotationTitle = styled.h4`
  font-size: 1.1em;
  flex-grow: 1;
`;

const AnnotationActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  & button {
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }
`;

const AnnotationDate = styled.div`
  margin-bottom: 15px;
`;

const AnnotationDescription = styled.p``;

export {
  LessonAnnotationsContainer,
  LessonAnnotationsForm,
  LessonAnnotationsList,
  AnnotationCardContainer,
  AnnotationTitleAndActions,
  AnnotationTitle,
  AnnotationDate,
  AnnotationActions,
  AnnotationDescription,
};
