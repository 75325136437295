import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FiDownload } from 'react-icons/fi';
import {
  getPrivacyTerm,
  getResponsibilityTerm,
  updateAcceptTermInUser,
  updatePrivacyTermInUser,
} from 'services/acceptTerms';
import { toast } from 'react-toastify';
import User from 'models/user';

import logoImg from 'assets/logo.svg';
import DefaultButton from 'components/Buttons/DefaultButton';
import BackButton from 'components/Buttons/BackButton';

import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';

import {
  StyledTerms,
  ContainerTerms,
  ContentTerms,
  ButtonNotAcept,
  ContainerButtons,
  StyledPrivacy,
  StyledTitle,
} from './style';

interface ITermsLocation {
  user: User;
}

type TermType = {
  id?: string;
  title?: string;
  description: string;
  created_at?: string;
  updated_at?: string;
  type?: string | null;
  filename?: string | null;
  reference?: string;
};

const Terms = () => {
  const [acceptButtonDisable, setAcceptButtonDisable] = useState(false);

  const [privacyStatement, setPrivacyStatement] = useState(false);
  const [privacyTermState, setPrivacyTermState] = useState<TermType>();
  const [existPrivancyTerm, setExistPrivancyTerm] = useState(false);

  const [responsabilityTermState, setResponsabilityTermState] =
    useState<TermType>();
  const [existResponsabilityTerm, setExistResponsabilityTerm] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const [responsibilityEditor, setResponsibilityEditor] = useState(() =>
    EditorState.createEmpty(),
  );

  const [convertedContent, setConvertedContent] = useState('');
  const [responsabilityContent, setResponsabilityContent] = useState('');

  const history = useHistory();
  const { state } = useLocation<ITermsLocation>();

  const convertContentToHTML = useCallback(() => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }, [editorState]);

  const convertResponsabilityContentToHTML = useCallback(() => {
    let currentResponsabilityAsHTML = convertToHTML(
      responsibilityEditor.getCurrentContent(),
    );
    setResponsabilityContent(currentResponsabilityAsHTML);
  }, [responsibilityEditor]);

  const getPrivacyTermContent = useCallback(async () => {
    const privacyTerm: TermType[] | [] = await getPrivacyTerm();

    if (privacyTerm.length !== 0 && !existPrivancyTerm) {
      setPrivacyTermState(privacyTerm[0]);

      const blocksFromHTML = convertFromHTML(privacyTerm[0].description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      setEditorState(EditorState.createWithContent(state));
      convertContentToHTML();
      setExistPrivancyTerm(true);
    }
  }, [convertContentToHTML, existPrivancyTerm]);

  const getResponsibilityTermContent = useCallback(async () => {
    const responsibilityTerm: TermType[] | [] = await getResponsibilityTerm();

    if (responsibilityTerm.length !== 0 && !existResponsabilityTerm) {
      setResponsabilityTermState(responsibilityTerm[0]);

      const responsabilityFromHTML = convertFromHTML(
        responsibilityTerm[0].description,
      );
      const responsabilityState = ContentState.createFromBlockArray(
        responsabilityFromHTML.contentBlocks,
        responsabilityFromHTML.entityMap,
      );

      setResponsibilityEditor(
        EditorState.createWithContent(responsabilityState),
      );
      convertResponsabilityContentToHTML();
      setExistResponsabilityTerm(true);
    }
  }, [convertResponsabilityContentToHTML, existResponsabilityTerm]);

  useEffect(() => {
    getPrivacyTermContent();
    getResponsibilityTermContent();

    const user: User = JSON.parse(
      localStorage.getItem('dorconsultoria-user') as string,
    );
    if (existPrivancyTerm && existResponsabilityTerm) {
      setPrivacyStatement(false);
    } else if (existPrivancyTerm && !existResponsabilityTerm) {
      setPrivacyStatement(true);
    }
    if (!!user.term_of_responsibility !== false) {
      setPrivacyStatement(true);
    }
  }, [getPrivacyTermContent, getResponsibilityTermContent]);

  async function handleTerms() {
    const user: User = JSON.parse(
      localStorage.getItem('dorconsultoria-user') as string,
    );
    if (existPrivancyTerm === true && !!user.privacy_statement === false) {
      await updateAcceptTermInUser(state.user.user_id)
        .then(user => {
          window.localStorage.setItem(
            'dorconsultoria-user',
            JSON.stringify(user),
          );
          setAcceptButtonDisable(false);

          setPrivacyStatement(true);
        })
        .catch(() => {
          toast.error('Erro ao atualizar termo de compromisso');
        });
    } else {
      await updateAcceptTermInUser(state.user.user_id)
        .then(user => {
          window.localStorage.setItem(
            'dorconsultoria-user',
            JSON.stringify(user),
          );
          setAcceptButtonDisable(false);
          history.push('/home');
        })
        .catch(() => {
          toast.error('Erro ao atualizar termo de compromisso');
        });
    }
  }

  async function handlePrivacyStatement() {
    await updatePrivacyTermInUser(state.user.user_id)
      .then(user => {
        window.localStorage.setItem(
          'dorconsultoria-user',
          JSON.stringify(user),
        );
        history.push('/home');
      })
      .catch(() => {
        toast.error('Erro ao atualizar termos de privacidade');
      });
  }

  function returnLogout() {
    window.localStorage.removeItem('dorconsultoria-api-token');
    window.localStorage.removeItem('dorconsultoria-user');
    history.push('/');
  }

  function downloadTerms() {
    if (privacyStatement === false) {
      window.open(responsabilityTermState?.reference);
    } else {
      window.open(
        privacyTermState?.reference !== ''
          ? privacyTermState?.reference
          : 'https://dorconsultoria-front-dev.s3.amazonaws.com/TERMOS+DE+USO+TESTE.pdf',
      );
    }
  }

  useEffect(() => {
    const end: any = document.querySelector('#end-term');

    const intersectionObserver = new IntersectionObserver(entries => {
      setAcceptButtonDisable(entries[0].isIntersecting);
    });

    intersectionObserver.observe(end);

    return () => intersectionObserver.disconnect();
  }, [
    privacyTermState,
    responsabilityTermState,
    editorState,
    responsibilityEditor,
    privacyStatement,
  ]);

  return (
    <ContainerTerms>
      <BackButton />

      <ContentTerms>
        <img src={logoImg} alt="Logomarca D'or consultoria" />
        <StyledTitle>
          {existResponsabilityTerm === true && privacyStatement === false ? (
            <>
              <h1>Termos de compromisso</h1>
              {responsabilityTermState?.reference !== '' && (
                <button
                  onClick={downloadTerms}
                  style={
                    responsabilityTermState?.reference === '' ||
                    responsabilityTermState?.reference === undefined ||
                    responsabilityTermState?.reference === null
                      ? { display: 'none' }
                      : { display: 'inline' }
                  }
                >
                  <FiDownload />
                  Baixar
                </button>
              )}
            </>
          ) : (
            <>
              <h1>Declaração de privacidade</h1>
              {privacyTermState?.reference !== '' && (
                <button
                  onClick={downloadTerms}
                  style={
                    privacyTermState?.reference === '' ||
                    privacyTermState?.reference === undefined ||
                    privacyTermState?.reference === null
                      ? { display: 'none' }
                      : { display: 'inline' }
                  }
                >
                  <FiDownload />
                  Baixar
                </button>
              )}
            </>
          )}
        </StyledTitle>

        {existResponsabilityTerm === true && privacyStatement === false ? (
          <StyledTerms className="term-container">
            {
              <div
                dangerouslySetInnerHTML={{ __html: responsabilityContent }}
              />
            }
            <div id="end-term" />
          </StyledTerms>
        ) : (
          <StyledPrivacy>
            {<div dangerouslySetInnerHTML={{ __html: convertedContent }} />}
            <div id="end-term" />
          </StyledPrivacy>
        )}

        <ContainerButtons>
          <DefaultButton
            disabled={!acceptButtonDisable}
            onClick={() => {
              if (existResponsabilityTerm && privacyStatement === false) {
                handleTerms();
              } else if (existPrivancyTerm && privacyStatement === true) {
                handlePrivacyStatement();
              } else {
                handleTerms();
              }
            }}
          >
            Eu aceito
          </DefaultButton>

          <ButtonNotAcept onClick={returnLogout}>Não aceito</ButtonNotAcept>
        </ContainerButtons>
      </ContentTerms>
    </ContainerTerms>
  );
};

export default Terms;
