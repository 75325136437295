import axios from 'axios';
import { toast } from 'react-toastify';

interface IFormSignUp {
  email: string;
}

interface IForgotPasswordConfirm {
  code: string;
  email: string;
  password: string;
}

const forgotPassword = async ({ email }: IFormSignUp): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/forgot-password`, {
        email,
      })
      .then(response => {});

    return Promise.resolve();
  } catch (err) {
    toast.error(
      'Algo deu errado ao solicitar a recuperação de senha. Tente novamente.',
    );

    return Promise.reject();
  }
};

const forgotPasswordConfirm = async ({
  email,
  code,
  password,
}: IForgotPasswordConfirm) => {
  try {
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/cognito/confirm-new-password`,
        {
          email,
          code,
          password,
        },
      )
      .then(response => {
        toast.success('Senha redefinida com sucesso!');
      });

    return Promise.resolve();
  } catch (err) {
    toast.error('Algo deu errado ao redefinir a senha. Tente novamente.');

    return Promise.reject();
  }
};

export { forgotPassword, forgotPasswordConfirm };
