import styled from 'styled-components';

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const Container = styled.div`
  cursor: pointer;
  width: 98%;
  margin-top: 48px;
  border: 1px solid #f2f2f2;
  outline: none;

  img {
    width: 70%;
    height: 450px;
  }

  @media (max-width: 1440px) {
    img {
      width: 60%;
      height: 400px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 32px;
    height: 175px;
    img {
      width: 60%;
      height: 250px;
    }
  }

  @media (max-width: 425px) {
    img {
      height: 170px;
    }
  }
`;

export const BookmarkButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  margin-bottom: 32px;

  svg {
    width: 32px;
    height: 32px;

    &.favorite {
      fill: var(--third-color);

      path {
        fill-rule: inherit;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const CourseDetails = styled.div`
  width: 40%;
  height: auto;
  background: rgba(21, 101, 192, 0.85);
  padding: 48px 0px 0px 48px;

  h3 {
    max-width: 85%;
    font-size: var(--font-largest);
    color: var(--white-font-color);
    line-height: 40px;
    margin-bottom: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  div {
    display: flex;
    align-items: center;

    span.divider {
      height: 22px;
      border: 1px solid var(--white-font-color);
      margin: 0 16px;
    }

    svg {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 768px) {
    width: 72%;
    padding: 32px 0px 0px 18px;

    h3 {
      font-size: var(--font-small);
      line-height: 16px;
    }

    div {
      align-items: stretch;

      span.divider {
        height: 12px;
        margin: 0 8px;
      }

      svg {
        margin-right: 6px;
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const CourseTime = styled.div`
  font-size: var(--font-large);
  color: var(--white-font-color);

  span {
    margin-top: 6px;
  }

  @media (max-width: 768px) {
    span {
      margin-top: 0;
    }

    font-size: var(--font-min);
  }
`;

export const CourseLikes = styled.div`
  font-size: var(--font-large);
  color: var(--white-font-color);

  span {
    margin-top: 6px;
  }

  @media (max-width: 768px) {
    span {
      margin-top: 0;
    }

    font-size: var(--font-min);
  }
`;
