import styled from 'styled-components';

export const Container = styled.div`
  margin: 48px 0;
  padding-bottom: 18px;
  padding-top: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);

  h2 {
    font-size: var(--font-largest);
    margin-bottom: 32px;
  }

  button.slick-arrow {
    width: 36px;
    height: 36px;
    top: -74px;

    &.slick-prev {
      right: 78px;
    }

    svg {
      width: 16px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 32px;
    margin-bottom: 24px;
    padding-top: 38px;

    h2 {
      font-size: var(--font-default);
      margin-bottom: 16px;
    }

    button.slick-arrow {
      width: 30px;
      height: 30px;
      top: -50px;

      &.slick-prev {
        right: 65px;
      }
      svg {
        width: 13px;
      }
    }
  }
  @media (max-width: 580px) {
    button.slick-arrow {
      width: 23px;
      height: 23px;
      top: -40px;

      &.slick-prev {
        right: 55px;
      }
      svg {
        width: 10px;
      }
    }
  }
`;
