import React from 'react';
import { GrNext } from 'react-icons/gr';

import { Container } from './style';

interface INextButtonProps {
  onClick?: () => void;
  className?: string;
}

const SliderNextButton: React.FC<INextButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <Container onClick={onClick} className={className}>
      <GrNext size={24} />
    </Container>
  );
};

export default SliderNextButton;
