import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiFillLike,
  AiOutlineUser,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import { addBookMark, removeBookMark } from 'services/bookmark';
import ProgressBar from 'components/ProgressBar';

import CourseImage from 'assets/banner-image.png';

import {
  Container,
  Trailfooter,
  TrailTime,
  TrailLikes,
  BookmarkButton,
} from './style';

import { toast } from 'react-toastify';
import { secondsToTimeText } from 'helpers/time';
import Course from 'models/course';

interface ITrailProps {
  trail: Course;
  onAddToList?: () => void;
  showProgressBar?: boolean;
}

const TrailCard: React.FC<ITrailProps> = ({
  trail,
  onAddToList,
  showProgressBar = false,
}) => {
  const [addedToList, setAddedToList] = useState(trail.addedToList);
  const history = useHistory();

  const goToTrail = () => {
    history.push(`/course/${trail.id}`);
  };

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    try {
      const shouldAddToList = !trail.addedToList;

      if (shouldAddToList) {
        await addBookMark(trail.id);
      } else {
        await removeBookMark(trail.id);
      }

      if (!!onAddToList) {
        onAddToList();
      }

      trail.addedToList = shouldAddToList;
      setAddedToList(trail.addedToList);
    } catch (e) {
      toast.error('Erro ao adicionar curso aos favoritos.');
    }
  };
  return (
    <Container onClick={goToTrail}>
      <div>
        <img src={trail.thumbnailUrl || CourseImage} alt="Trail" />

        <BookmarkButton onClick={e => addToList(e)}>
          <BsBookmark
            size={24}
            color="#FFF"
            className={`${addedToList && 'favorite'}`}
          />
        </BookmarkButton>
      </div>

      <div className="text">
        <span className="category">{trail.categoryName}</span>

        <h4>{trail.title}</h4>

        {trail.authorName && (
          <div className="align">
            <AiOutlineUser />
            <h3>Autor: {trail.authorName}</h3>
          </div>
        )}

        <Trailfooter>
          <TrailTime>
            <AiOutlineClockCircle />
            <span>{`${secondsToTimeText(trail.duration)}`}</span>
          </TrailTime>

          <span className="divider" />

          <TrailLikes>
            {trail.userLiked ? (
              <AiFillLike color="#555" />
            ) : (
              <AiOutlineLike color="#555" />
            )}

            <span>
              {trail.numberOfLikes &&
                `${trail.numberOfLikes} ${
                  trail.numberOfLikes === 1 ? 'curtida' : 'curtidas'
                }`}
            </span>
          </TrailLikes>
        </Trailfooter>
        {showProgressBar && (
          <div className="progress">
            <div className="bar">
              <ProgressBar
                completionPercentage={trail.progress ? trail.progress : 0}
              />
            </div>
            <h6>{(trail.progress ? trail.progress : 0).toFixed(1)}%</h6>
          </div>
        )}
      </div>
    </Container>
  );
};

export default TrailCard;
