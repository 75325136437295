import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  max-width: 270px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--default-gray);
  border-radius: 6px;

  div.text {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  div.align {
    display: flex;
    align-items: center;
    gap: 9px;

    h3 {
      color: var(--medium-gray);
      font-weight: 200;
      font-size: var(--font-regular);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &:after {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 20.31%,
      rgba(255, 255, 255, 0.1) 100%
    );
    position: absolute;
    content: '';
    height: 140px;
    width: 100%;
    top: 0;
    left: 0;
  }

  span.divider {
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 8px 0px 16px;
  }

  img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  span.category {
    color: var(--secondary-color);
    font-weight: 700;
    font-size: var(--font-regular);
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  h4 {
    max-width: 80%;
    height: 45px;
    font-size: var(--font-large);
    line-height: 22px;
    margin-bottom: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 768px) {
    h4 {
      max-width: 90%;
      font-size: var(--font-regular);
    }

    &:after {
      height: 102px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.3) 36.31%,
        rgba(255, 255, 255, 0.1) 100%
      );
    }
  }

  @media (max-width: 345px) {
    width: 300px;
  }
`;

export const Coursefooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  div {
    display: flex;

    svg {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 2.5px;
    }
  }

  @media (max-width: 768px) {
    div {
      svg {
        width: 11px;
        height: 11px;
      }

      span {
        margin-top: 0;
      }
    }
  }
`;

export const CourseTime = styled.div`
  font-size: var(--font-regular);
  color: var(--regular-gray);

  @media (max-width: 768px) {
    font-size: var(--font-min);
  }
`;

export const CourseLikes = styled.div`
  font-size: var(--font-regular);
  color: var(--regular-gray);

  button {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: none;
    background: none;
    margin-right: 6px;
  }

  @media (max-width: 768px) {
    font-size: var(--font-min);

    button {
      width: 11px;
      height: 11px;
    }
  }
`;

export const BookmarkButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  top: 13px;
  right: 14px;
  z-index: 1;

  svg.favorite {
    fill: var(--third-color);

    path {
      fill-rule: inherit;
    }
  }
`;
