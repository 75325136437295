import React, { useState, useCallback, useEffect } from 'react';
import GridShimmer from 'components/GridShimmer';
import ProfileMenu from '../../components/ProfileMenu';
import TrailCard from 'components/TrailCard';
import BackButton from 'components/Buttons/BackButton';

import { getOngoingCourses } from 'services/course';

import { Container, OngoingList, Div } from './style';
import { useHistory } from 'react-router-dom';

const OngoingCourses: React.FC = () => {
  const [ongoingCourses, setOngoingCourses] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const refreshOngoingCourses = useCallback(async () => {
    setIsLoading(true);
    const localOngoingCourses = await getOngoingCourses();
    if (localOngoingCourses && localOngoingCourses.length) {
      setOngoingCourses(localOngoingCourses);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await refreshOngoingCourses();
    })();
  }, [refreshOngoingCourses]);

  return (
    <>
      <Div>
        <BackButton customFunc={() => history.push('/profile')} />
      </Div>
      <Container className="content">
        <ProfileMenu />

        <div>
          <h2>Trilhas em andamento</h2>
          {isLoading ? (
            <div className="shimmer">
              <GridShimmer />
            </div>
          ) : (
            <>
              <OngoingList>
                {ongoingCourses && ongoingCourses.length ? (
                  ongoingCourses.map(course => (
                    <TrailCard
                      key={course.trail_id}
                      trail={course}
                      showProgressBar
                    />
                  ))
                ) : (
                  <h4 style={{ width: '100%', padding: '15px' }}>
                    Não foram encontradas trilhas em andamento.
                  </h4>
                )}
              </OngoingList>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default OngoingCourses;
