import React, { useRef, useState, useCallback, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { Container } from './style';

import { signUp as SignUpService } from 'services/signUp';

import HeaderLogo from 'assets/logo.svg';
import DefaultInput from 'components/Inputs/DefaultInput/DefaultInput';
import DefaultButton from 'components/Buttons/DefaultButton';
import InputPassword from 'components/Inputs/InputPassword';
import BackButton from 'components/Buttons/BackButton';
import InputCheckbox from 'components/Inputs/InputCheckbox';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'helpers/getValidationErrors';
import useWidth from 'hooks/useWindowSize';

const schema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(4, 'O nome deve conter pelo menos 4 letras')
    .required('Preencha corretamente este campo.'),
  email: Yup.string()
    .email('Informe um e-mail com formato válido.')
    .trim()
    .required('Preencha corretamente este campo.'),
  password: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.')
    .min(8, 'A senha deve conter no mínimo 8 caracteres.'),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'As senhas informadas não correspondem. Por favor, verifique.',
    )
    .trim()
    .required('Preencha corretamente este campo.'),
  notifications: Yup.boolean(),
  accessKey: Yup.string()
    .trim()
    .min(
      7,
      'A chave de acesso digitada não possui o numéro minimo de caracteres.',
    )
    .required('Preencha corretamente este campo'),
});

interface ISignUpProps {
  name: string;
  email: string;
  accessKey: string;
  password: string;
  notifications: boolean;
  passwordConfirmation: string;
}

const SignUp: React.FC = () => {
  const [formStepsMobile, setFormStepsMobile] = useState<number>(1);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const width: Array<number> = useWidth();

  const handleSubmit = async (data: ISignUpProps) => {
    const { password, accessKey, email, name } = data;

    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await SignUpService({
        accessKey,
        password,
        name,
        email,
      }).then(() => {
        history.push('/verify-code', {
          email,
        });
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  const showStepsMobile = useCallback(
    (position: number) => {
      return width[0] >= 768 ? true : position === formStepsMobile;
    },
    [width, formStepsMobile],
  );

  const handleNextStep = async (event: FormEvent) => {
    event.preventDefault();

    formRef.current?.setErrors({});
    let formField = '';

    switch (formStepsMobile) {
      case 1:
        formField = 'name';
        break;

      case 2:
        formField = 'email';
        break;

      case 3:
        formField = 'password';
        break;

      case 4:
        formField = 'passwordConfirmation';
        break;

      case 5:
        formField = 'accessKey';
        break;

      default:
        break;
    }

    const data = formRef.current?.getData();

    try {
      await schema.validate(data, { abortEarly: false });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const objError = getValidationErrors(err);

        formRef.current?.setFieldError(formField, objError[formField]);

        if (Object.keys(objError).includes(formField)) {
          return;
        }
      }
    }

    formRef.current?.setErrors({});
    setFormStepsMobile(step => step + 1);
  };

  const replaceAllPassword = (text: string) => {
    const str = text.replace(/\D/g, '●').replace(/[0-9]/g, '●');

    return str;
  };

  return (
    <Container>
      <div className="return-btn">
        {width[0] <= 768 && formStepsMobile > 1 ? (
          <BackButton customFunc={() => setFormStepsMobile(step => step - 1)} />
        ) : (
          <BackButton />
        )}
      </div>

      <img src={HeaderLogo} alt="logo" />

      <h3>Cadastrar</h3>

      <div className="divider"></div>

      <Form onSubmit={handleSubmit} ref={formRef}>
        {formStepsMobile <= 5 ? (
          <div className="step-count">{'0' + formStepsMobile + ' de 05'}</div>
        ) : (
          <div className="step-count">Confirme os dados</div>
        )}

        <DefaultInput
          name="name"
          type="text"
          label={showStepsMobile(1) ? 'Nome' : ''}
          className={showStepsMobile(1) ? '' : 'disappear'}
        />

        <DefaultInput
          name="email"
          type="text"
          label={showStepsMobile(2) ? 'E-mail' : ''}
          className={showStepsMobile(2) ? '' : 'disappear'}
        />

        <InputPassword
          name="password"
          label={showStepsMobile(3) ? 'Senha' : ''}
          className={showStepsMobile(3) ? '' : 'disappear'}
        />

        <InputPassword
          name="passwordConfirmation"
          label={showStepsMobile(4) ? 'Confirmar senha' : ''}
          className={showStepsMobile(4) ? '' : 'disappear'}
        />

        <DefaultInput
          name="accessKey"
          type="text"
          label={showStepsMobile(5) ? 'Chave de acesso' : ''}
          className={showStepsMobile(5) ? '' : 'disappear'}
        />

        {formStepsMobile === 6 && width[0] <= 768 && (
          <>
            <div className="confirm-data">
              <div className="element">
                <h4>Nome</h4>
                <span onClick={() => setFormStepsMobile(1)}>editar</span>
              </div>

              <h2>{formRef.current?.getFieldValue('name')}</h2>
            </div>

            <div className="confirm-data">
              <div className="element">
                <h4>E-mail</h4>
                <span onClick={() => setFormStepsMobile(2)}>editar</span>
              </div>

              <h2>{formRef.current?.getFieldValue('email')}</h2>
            </div>

            <div className="confirm-data">
              <div className="element">
                <h4>Senha</h4>
                <span onClick={() => setFormStepsMobile(3)}>editar</span>
              </div>

              <h2>
                {replaceAllPassword(formRef.current?.getFieldValue('password'))}
              </h2>
            </div>

            <div className="confirm-data" style={{ marginBottom: '30px' }}>
              <div className="element">
                <h4>Chave de acesso</h4>
                <span onClick={() => setFormStepsMobile(5)}>editar</span>
              </div>

              <h2>{formRef.current?.getFieldValue('accessKey')}</h2>
            </div>

            <InputCheckbox
              label="Quero receber notificações por e-mail"
              name="notifications"
            />
          </>
        )}

        {width[0] <= 768 && formStepsMobile !== 6 && (
          <DefaultButton onClick={handleNextStep} type="submit">
            Continuar
          </DefaultButton>
        )}

        {showStepsMobile(6) && (
          <DefaultButton type="submit">
            {width[0] <= 768 ? 'Finalizar cadastro' : 'Cadastrar'}
          </DefaultButton>
        )}
      </Form>
    </Container>
  );
};

export default SignUp;
