const courseCategorySliderSettings = {
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.8,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 3.1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2.65,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2.2,
        arrows: false,
      },
    },
    {
      breakpoint: 540,
      settings: {
        slidesToShow: 2.1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.8,
        arrows: false,
      },
    },
    {
      breakpoint: 410,
      settings: {
        slidesToShow: 1.6,
        arrows: false,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1.4,
        arrows: false,
      },
    },
  ],
};

const bannerSliderSettings = {
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

const depositionsSliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  draggable: false,
  swipeToSlide: false,
  speed: 1000,
  infinite: false,
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 2.8,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1160,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1030,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 880,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 1.6,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 470,
      settings: {
        slidesToShow: 1.3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 410,
      settings: {
        slidesToShow: 1.08,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 340,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const progressCoursesSettings = {
  slidesToShow: 3.6,
  slidesToScroll: 3,
  infinite: false,
  draggable: false,
  swipeToSlide: false,
  speed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.1,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2.3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 500,
        arrows: false,
      },
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 1.4,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 390,
      settings: {
        slidesToShow: 1.04,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const coursesSliderSettings = {
  slidesToShow: 4.5,
  slidesToScroll: 4,
  infinite: false,
  draggable: false,
  swipeToSlide: false,
  speed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
        speed: 500,
        arrows: true,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
        arrows: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 345,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export {
  bannerSliderSettings,
  depositionsSliderSettings,
  coursesSliderSettings,
  progressCoursesSettings,
  courseCategorySliderSettings,
};
