import React, { useState } from 'react';

import { FiDownload, FiImage, FiShare2 } from 'react-icons/fi';

import {
  downloadCertificate as downloadCertificateService,
  showCertificate as showCertificateService,
} from 'services/certificate';
import Certificado from '../../assets/certificado.svg';

import { toast } from 'react-toastify';

import {
  Container,
  CertificateImage,
  Content,
  ButtonsContainer,
} from './style';
import DefaultButton from 'components/Buttons/DefaultButton';
import { DefaultModal } from 'components/DefaultModal';
import { useHistory } from 'react-router-dom';
import Course from 'pages/Course';

interface ICertificateCardProps {
  course: any;
  verify?: boolean;
}

const CertificateCard: React.FC<ICertificateCardProps> = ({
  course,
  verify,
}) => {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const downloadCertificate = async (courseId: string) => {
    if (course.user_name) {
      try {
        await downloadCertificateService(courseId, course);
        setIsOpen(true);
      } catch (e) {
        toast.error('Erro ao baixar certificado.');
      }
    } else {
      try {
        await downloadCertificateService(courseId);
        setIsOpen(true);
      } catch (e) {
        toast.error('Erro ao baixar certificado.');
      }
    }
  };

  const showViewCertificate = async (courseId: string) => {
    if (course.user_name) {
      try {
        const certificate = await showCertificateService(courseId, course);
        const openCertificate = window.open() as Window;
        const url = URL.createObjectURL(certificate);
        openCertificate.location.href = url;
      } catch {
        toast.error('Erro ao visualizar certificado.');
      }
    } else {
      try {
        const certificate = await showCertificateService(courseId);
        const openCertificate = window.open() as Window;
        const url = URL.createObjectURL(certificate);
        openCertificate.location.href = url;
      } catch {
        toast.error('Erro ao visualizar certificado.');
      }
    }
  };

  return (
    <Container>
      <CertificateImage>
        <img src={Certificado} alt="Certificate" />
      </CertificateImage>

      <Content>
        <h1>{course.reference_name}</h1>

        <p>
          Concluído em{' '}
          <span>
            {new Intl.DateTimeFormat('pt-BR').format(
              new Date(course.reference_finish_at),
            )}
          </span>
        </p>

        <ButtonsContainer>
          <button onClick={() => showViewCertificate(course.reference_id)}>
            <FiImage />
            Visualizar
          </button>

          <button onClick={() => downloadCertificate(course.reference_id)}>
            <FiDownload />
            Baixar
          </button>

          <button className="share">
            <FiShare2 />
            Compartilhar
          </button>
        </ButtonsContainer>
        {!verify && (
          <div className="btn-avaliar-curso">
            <DefaultButton
              onClick={() =>
                history.push('/depositions', {
                  type: course.reference_type,
                  id: course.reference_id,
                  title: course.reference_name,
                })
              }
            >
              Avaliar curso
            </DefaultButton>
          </div>
        )}
      </Content>

      {!verify && (
        <DefaultModal
          modalIsOpen={modalIsOpen}
          setIsOpen={() => setIsOpen(modalIsOpen)}
          closeModal={closeModal}
        >
          <h1>O seu Certificado começou a baixar!</h1>
          <p>
            Faça também a avaliação do curso e nos ajude a melhorar cada vez
            mais
          </p>
          <DefaultButton onClick={closeModal}>OK, entendi</DefaultButton>
        </DefaultModal>
      )}
    </Container>
  );
};

export default CertificateCard;
