import styled from 'styled-components';

export const Container = styled.div`
  width: 440px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .container-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: -20px;
  }

  div.return-btn {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    padding-left: 20px;
    margin-top: 20px;
  }

  img {
    margin-top: 20px;
  }

  h3 {
    margin-top: 30px;
    color: var(--primary-color);
    font-size: var(--font-large-medium);
  }

  form {
    width: 100%;

    > div {
      margin-bottom: 20px;

      & + div {
        margin-bottom: 0px;
      }
    }

    a {
      font-weight: 700;
      color: var(--primary-color);
      display: inline-block;
      text-decoration: none;
      margin-top: 4px;

      &:hover {
        text-decoration: underline;
      }
    }

    button[type='submit'] {
      margin-top: 50px;
    }
  }

  @media (max-width: 768px) {
    height: auto;

    img {
      width: 109px;
      margin-top: 10px;
    }

    div.container-select {
      margin-bottom: 10px;
    }

    h3 {
      margin-top: 20px;
      font-size: 24px;
    }

    form {
      width: 70%;
      margin-top: 10px;

      a {
        text-decoration: none;
        font-size: var(--font-small);
        font-weight: bold;
        color: var(--primary-color);
      }
    }
  }

  @media (max-width: 1024px) {
    height: auto;

    img {
      width: 100px;
      margin-top: 10px;
    }
    form {
      margin-top: 0;
      button[type='submit'] {
        margin-top: 24px;
      }
    }
  }

  @media (min-width: 1440px) {
    height: auto;
    padding-top: 10px;
    margin-top: 20px;

    form {
      margin-top: 16px;

      button[type='submit'] {
        margin-top: 24px;
      }
    }
  }
`;
