import { useState } from 'react';

import BackButton from 'components/Buttons/BackButton';
import DefaultButton from 'components/Buttons/DefaultButton';
import { DefaultModal } from 'components/DefaultModal';
import { StyledContainer, StyledContent, ModalContainer } from './styles';

import Trail from 'models/trail';
import StarComponent from './StarComponent';
import { useLocation, useHistory } from 'react-router-dom';
import { reviewTrailContent } from 'services/trail-review';
import { reviewContent } from 'services/content-review';
import { toast } from 'react-toastify';
import checkEmptyString from 'helpers/check-empty-string';

interface DepositionsProps {
  trail: Trail;
}

interface LocationProps {
  id: string;
  type: string;
  title: string;
}

const Depositions = ({ trail }: DepositionsProps) => {
  const [rating, setRating] = useState(3);
  const [description, setDescription] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const { state } = useLocation<LocationProps>();

  function closeModal() {
    setIsOpen(false);
    setDescription('');
    history.push('/home');
  }

  const handleChange = (value: number) => {
    setRating(value);
  };

  const handleDepositions = async () => {
    if (checkEmptyString(description)) {
      toast.error('Preencha o campo de depoimento');
      return;
    }

    if (state.type === 'trail') {
      await reviewTrailContent(state.id, description, rating)
        .then(() => {
          setIsOpen(true);
        })
        .catch(err => {
          toast.error('Erro ao realizar depoimento');
          setIsOpen(false);
        });
    } else {
      await reviewContent(state.id, description, rating)
        .then(() => {
          setIsOpen(true);
        })
        .catch(err => {
          toast.error('Erro ao realizar depoimento');
          setIsOpen(false);
        });
    }
  };

  return (
    <StyledContainer>
      <BackButton />

      <StyledContent>
        <h1>Depoimentos</h1>
        <p>
          Gostou do curso? Deixe sua classificação e opine sobre o curso {''}
          <strong>{state.title}</strong>
        </p>
        <div>
          <StarComponent rating={rating} handleChange={handleChange} />
        </div>
        <span>Escreva seu depoimento</span>
        <textarea
          onChange={event => setDescription(event.target.value)}
          value={description}
          required
        />

        <DefaultButton onClick={handleDepositions}>
          Publicar depoimento
        </DefaultButton>
      </StyledContent>

      <DefaultModal
        modalIsOpen={modalIsOpen}
        setIsOpen={() => setIsOpen(modalIsOpen)}
        closeModal={closeModal}
      >
        <ModalContainer>
          <h1>Muito obrigado por dar seu depoimento!</h1>
          <p>Sua opinião é muito importante para nós.</p>
          <DefaultButton onClick={closeModal}>OK, entendi</DefaultButton>
        </ModalContainer>
      </DefaultModal>
    </StyledContainer>
  );
};

export default Depositions;
