import styled from 'styled-components';

const LessonExtraMaterialContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  @media (max-width: 768px){
    max-width: 90%;
  }
`;

const ExtraMaterialList = styled.div`

`;

const SupportMaterialsContainer = styled.div`

`;

export {
  LessonExtraMaterialContainer,
  ExtraMaterialList,
  SupportMaterialsContainer,
};
