import styled from 'styled-components';

export const Container = styled.div`
  width: 440px;
  height: calc(100vh - 140px);
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 140px;
    height: 140px;
  }

  div.return-btn {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    padding-left: 20px;
    margin-top: -20px;
  }

  h3 {
    margin-top: 20px;
    color: var(--primary-color);
  }

  p {
    font-size: var(--font-regular);
    color: var(--regular-gray);

    button {
      cursor: pointer;
      font-size: var(--font-regular);
      color: var(--regular-gray);
      background: none;
      border: none;
      text-decoration: underline;
    }
  }

  form {
    width: 100%;
    margin: 52px 0px;

    > div {
      margin-bottom: 24px;

      & + div {
        margin-bottom: 0px;
      }
    }

    a {
      font-weight: 700;
      color: var(--primary-color);
      display: inline-block;
      margin-top: 4px;
    }

    button[type='submit'] {
      margin-top: 54px;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 110px;

    img {
      width: 119px;
    }

    h3 {
      margin-top: 40px;
      font-size: 24px;
    }

    form {
      width: 70%;

      a {
        text-decoration: none;
        font-size: var(--font-small);
        font-weight: 400;
        color: var(--regular-gray);
      }
    }
  }

  @media (min-width: 1440px) {
    padding-top: 68px;

    h3 {
      margin-top: 55px;
    }

    form {
      margin-top: 36px;
    }
  }
`;
