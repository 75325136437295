import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiOutlineUser,
  AiOutlineClockCircle,
  AiOutlineLike,
} from 'react-icons/ai';
import ProgressBar from 'components/ProgressBar';
import { BsBookmark } from 'react-icons/bs';
import { secondsToTimeText } from 'helpers/time';
import { addContentBookmark, removeContentBookmark } from 'services/bookmark';
import { toast } from 'react-toastify';

import {
  CourseCardWithProgressWrapper,
  CourseInfo,
  BookmarkButton,
} from './style';
import Course from 'models/course';

interface ICourseProps {
  course: Course;
  onAddToList?: () => void;
}

const CourseCardWithProgress: React.FC<ICourseProps> = ({
  course,
  onAddToList,
}) => {
  const history = useHistory();
  const [addedToList, setAddedToList] = useState(course.addedToList);

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (course) {
      try {
        const shouldAddToList = !course.addedToList;

        if (shouldAddToList) {
          await addContentBookmark(course.id);
        } else {
          await removeContentBookmark(course.id);
        }

        course.addedToList = shouldAddToList;
        setAddedToList(course.addedToList);

        if (!!onAddToList) {
          onAddToList();
        }
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  return (
    <CourseCardWithProgressWrapper
      onClick={() => history.push(`/course/${course.id}`)}
    >
      <span>{course?.categoryName}</span>

      <h1>{course.title}</h1>

      <BookmarkButton
        onClick={e => addToList(e)}
        className={`${addedToList && 'grey-background'}`}
      >
        <BsBookmark
          size={24}
          color="#7d8073"
          className={`${addedToList && 'favorite'}`}
        />
      </BookmarkButton>

      <CourseInfo>
        {course.authorName && (
          <div className="align">
            <AiOutlineUser />
            <h3>Autor: {course.authorName}</h3>
          </div>
        )}

        <div className="info">
          <div className="align">
            <AiOutlineClockCircle />
            <h3>Duração: {secondsToTimeText(Number(course.duration))}</h3>
          </div>

          <div className="divider"></div>

          <div className="align">
            <AiOutlineLike />
            <h3>
              {`${course.numberOfLikes || 0} ${
                course.numberOfLikes === 1 ? 'curtida' : 'curtidas'
              }`}
            </h3>
          </div>
        </div>
      </CourseInfo>

      <div className="progress">
        <div className="bar">
          <ProgressBar
            completionPercentage={course.progress ? course.progress : 0}
          />
        </div>

        <h6>{(course.progress ? course.progress : 0).toFixed(1)}%</h6>
      </div>
    </CourseCardWithProgressWrapper>
  );
};

export default CourseCardWithProgress;
