function getProgressPercentage(content_view: number, duration: number) {
  const percentage = (content_view * 100) / duration;
  if (isNaN(percentage)) {
    return 0.0;
  } else {
    return percentage.toFixed(1);
  }
}

export { getProgressPercentage };
