import styled from 'styled-components';

export const Container = styled.button`
  color: var(--white-font-color);
  cursor: pointer;
  display: block;
  width: 100%;
  height: 48px;
  font-size: var(--font-large);
  font-weight: bold;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 6px;
  transition: background-color 0.2s;
  color:var(  --white-font-color);
  &:hover {
    background-color: var(--secondary-color-hover);
  }

  &:disabled {
    filter:brightness(0.4);
   
  }
`;
