import React, { useState, useEffect } from 'react';

import ProfileMenu from '../../components/ProfileMenu';
import MobileMenuDropdown from '../../components/MobileMenuDropdown';

import { Container, CertificatesList, Div } from './style';
import CertificateCard from 'components/CertificateCard';
import CertificatesShimmer from './components/Skeleton';
import { getAllCertificates, setTrailCertificate } from 'services/certificate';
import BackButton from 'components/Buttons/BackButton';
import { useHistory } from 'react-router-dom';

const Certificates: React.FC = () => {
  const [certificates, setCertificates] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const getCertificates = async () => {
    setIsLoading(true);
    const localCertificates = await getAllCertificates();

    setCertificates(localCertificates);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getCertificates();
    })();
  }, []);

  return (
    <>
      <Div>
        <BackButton customFunc={() => history.push('/profile')} />
      </Div>
      <Container className="content">
        <ProfileMenu />

        <div>
          <h2>Certificados</h2>
          {isLoading ? (
            <CertificatesShimmer />
          ) : (
            <CertificatesList>
              {certificates && certificates.length ? (
                certificates.map(course => (
                  <CertificateCard key={course.id} course={course} />
                ))
              ) : (
                <h4 style={{ padding: '15px' }}>
                  Não foram encontrados cursos concluídos.
                </h4>
              )}
            </CertificatesList>
          )}
        </div>
      </Container>
    </>
  );
};

export default Certificates;
