import { ReactNode } from 'react';
import { ContentModal } from './styles';
import Modal from 'react-modal';

interface DefaultModalProps {
  children: ReactNode;
  modalIsOpen: boolean;
  closeModal: () => void;
  setIsOpen: (value: boolean) => void;
}

export function DefaultModal(props: DefaultModalProps) {
  function closeModal() {
    props.setIsOpen(false);
  }
  Modal.setAppElement('*');

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Certificado"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: '#fff',
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <ContentModal>{props.children}</ContentModal>
    </Modal>
  );
}
