import styled from 'styled-components';

import { Container } from '../../Container';

export const LayoutContainer = styled(Container)``;

export const SearchInputContent = styled.div`
  width: 100%;
  padding: 24px 15px 0;

  @media (min-width: 769px) {
    display: none;
  }
`;
