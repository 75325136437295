import React, { useContext } from 'react';
import CourseContext from '../../../../Context';
import { LessonOverviewContainer } from './styles';

const LessonOverview: React.FC = () => {
  const { selectedLesson, course } = useContext(CourseContext);

  return (
    <LessonOverviewContainer>
      {course.description || selectedLesson.description}
    </LessonOverviewContainer>
  );
};

export default LessonOverview;
