import styled from 'styled-components';

const CourseContainer = styled.div`
  flex-grow: 1;
  margin-bottom: 15px;
`;

const CourseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100vw;
  height: 70vh;
`;

const CourseLowerContent = styled.div`
  display: grid;
  grid-template-columns: calc(70% - 15px) 30%;
  gap: 15px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const CourseInfoAndProgress = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 69% 30%;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const CourseAuthorDurationAndBookmarkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

const CourseTitle = styled.h3``;

const CourseTag = styled.p``;

const CourseAuthor = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseDuration = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const BookmarkButton = styled.button`
  background: var(--light-gray);
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: #555555;
  cursor: pointer;

  &.added {
    background: var(--third-color);
    color: white;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourseProgress = styled.div`
  height: 15px;
  flex-grow: 1;
  background: var(--border-gray);
  border-radius: 3px;
  overflow: hidden;

  & > span {
    background: var(--third-color);
  }

  display: flex;
  align-items: stretch;
`;

const CourseProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseModulesAndUserReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: stretch;
  justify-content: flex-start;
`;

const AuthorAndCourseDuration = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
`;

const CourseExamButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

const CourseExamButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--primary-color);
  color: var(--white-font-color);
  font-size: 1em;
  outline: none;
  border: none;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0.85;
  transition: all 0.3s;

  :hover {
    opacity: 1;
  }
`;

const DepositionsContent = styled.div`
  background: var(--primary-color);
  color: #fff;
  padding: 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 580px) {
    flex-direction: column;

    div.buttons {
      margin-top: 28px;

      button {
        max-width: 280px;
      }
    }
  }

  strong {
    font-size: 20px;
    margin-bottom: 8px;
    text-align: center;
  }

  span {
    font-size: 18px;
    margin-top: 16px;
    display: block;
  }

  div.buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    cursor: pointer;
    border: none;
    background: var(--secondary-color);
    font-size: 18px;
    font-weight: 500;
    padding: 18px 0;
    border-radius: 6px;
    transition: 0.2s;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }

    & + button {
      width: unset;
      background: none;
      margin-top: 12px;
      color: #fff;
    }

    &:hover {
      filter: opacity(0.9);
    }
  }
`;

const ModalContainer = styled.div<{ termIsChecked: boolean }>`
  max-width: 720px;
  max-height: 560px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  position: relative;

  .buttons-wrapper {
    width: calc(100% + 58px);

    padding-right: 24px;
    margin-left: -24px;
    padding-bottom: ${props => (props.termIsChecked ? '32px' : '0')};

    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 16px;

    .submit-button {
      min-width: 112px;
      width: fit-content;
    }

    .back-button {
      min-width: 112px;
      width: fit-content;

      background: #808080 !important;
    }
  }
`;

const TextContainer = styled.div`
  width: calc(100% + 58px);

  margin: 0 0 32px;
  padding-right: 24px;
  margin-left: -24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  > .modal-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  > .modal-description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;

    margin: 32px 0;
  }

  > .modal-question {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  > .radio-group {
    width: 100%;

    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: start;
    grid-gap: 32px;

    label {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 8px;

      cursor: pointer;
    }
  }
`;

const FormContainer = styled.div`
  width: calc(100% + 58px);

  margin: 0 0 32px;
  padding-right: 24px;
  margin-left: -24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;

  position: relative;
`;

const TextWrapper = styled.div`
  width: 100%;

  margin: 32px 0 16px;

  display: flex;
  align-items: flex-start;
  justify-content: start;
  grid-gap: 16px;

  &.no-margin-top {
    margin: 16px 0 16px;
  }

  > .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #1565c0;
  }

  > .description {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
  }
`;

const FormGrid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

const FormFlex = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 16px;

  &.column {
    flex-direction: column;
  }
`;

const FormWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  grid-gap: 8px;

  label {
    font-size: 12px;
    font-weight: 600;
  }

  textarea {
    width: 100%;
    min-height: 64px;

    border: 1px solid;
    border-color: #e2e8f0;
    border-radius: 4px;

    padding: 16px;
    color: #2f2f2f;

    background: white;

    transition: box-shadow 0.3s, border 0.3s;

    outline: none;

    :focus {
      box-shadow: 0px 0px 6px #1565c0;
      border: 1px solid #1565c0;
    }

    :read-only {
      background-color: #f5f6f7;

      &[value=''] {
        &::placeholder {
          color: transparent;
        }
      }
    }

    &::placeholder {
      font-size: 12px;
      color: #6b6b6b;
    }
  }

  input {
    width: 100%;
    height: 32px;

    border: 1px solid;
    border-color: #e2e8f0;
    border-radius: 4px;

    padding: 16px;
    color: #2f2f2f;

    background: white;

    transition: box-shadow 0.3s, border 0.3s;

    outline: none;

    :focus {
      box-shadow: 0px 0px 6px #1565c0;
      border: 1px solid #1565c0;
    }

    :read-only {
      background-color: #f5f6f7;

      &[value=''] {
        &::placeholder {
          color: transparent;
        }
      }
    }

    &::placeholder {
      font-size: 12px;
      color: #6b6b6b;
    }
  }
`;

export {
  CourseContainer,
  CourseContentContainer,
  LoadingContainer,
  CourseTag,
  CourseLowerContent,
  CourseAuthorDurationAndBookmarkContainer,
  CourseTitle,
  CourseAuthor,
  CourseDuration,
  BookmarkButton,
  CourseInfoAndProgress,
  CourseProgressContainer,
  CourseProgress,
  CourseProgressBarContainer,
  CourseModulesAndUserReviewContainer,
  AuthorAndCourseDuration,
  CourseExamButtonContainer,
  CourseExamButton,
  DepositionsContent,
  ModalContainer,
  FormContainer,
  FormGrid,
  FormWrapper,
  TextWrapper,
  FormFlex,
  TextContainer,
};
