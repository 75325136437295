import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';

import { Container } from './style';

import {
  verifyCode as verifyCodeService,
  resendCode as resendCodeService,
} from 'services/verifyCode';

import HeaderLogo from 'assets/logo.svg';
import DefaultInput from 'components/Inputs/DefaultInput/DefaultInput';
import DefaultButton from 'components/Buttons/DefaultButton';
import BackButton from 'components/Buttons/BackButton';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'helpers/getValidationErrors';

const schema = Yup.object().shape({
  code: Yup.string().trim().required('Preencha corretamente este campo.'),
});

interface IVerifyProps {
  code: string;
}

interface ILocationProps {
  email: string;
}

const VerifyCode: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { state } = useLocation<ILocationProps>();
  const history = useHistory();

  const resendCode = async () => {
    await resendCodeService({ email: state.email });
  };

  const handleSubmit = async (data: IVerifyProps) => {
    const { code } = data;
    const { email } = state;

    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await verifyCodeService({ code, email }).then(() => {
        localStorage.setItem('dorconsultoria-isSSO', JSON.stringify(false));
        history.push('/signIn');
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Container>
      <div className="return-btn">
        <BackButton />
      </div>

      <img src={HeaderLogo} alt="logo" />

      <h3>Verificar código</h3>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <DefaultInput
          name="code"
          type="text"
          maxLength={6}
          label="Código de verificação"
          description="Enviamos o código de verificação para o email cadastrado. Informe o código no campo abaixo."
          required
        />
        <p>
          Não recebeu?{' '}
          <button type="button" onClick={resendCode}>
            Reenviar código
          </button>
        </p>

        <DefaultButton type="submit">Confirmar</DefaultButton>
      </Form>
    </Container>
  );
};

export default VerifyCode;
