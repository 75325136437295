import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Logo from 'assets/logo.svg';
import Footer from 'components/Footer';

import {
  Container,
  Content,
  BrandContent,
  OptionsContent,
  Title,
  Description,
} from './style';
import DefaultButton from 'components/Buttons/DefaultButton';

const Initial: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Container>
        <Content>
          <BrandContent>
            <img src={Logo} alt="Logo" />
          </BrandContent>

          <OptionsContent>
            <Title>Que bom te ver aqui!</Title>
            <Description>
              Bem vindo a plataforma de educação e treinamentos da Academia D'Or
              Consultoria.
            </Description>
            <DefaultButton
              onClick={() => {
                localStorage.setItem(
                  'dorconsultoria-isSSO',
                  JSON.stringify(false),
                );
                history.push('/pre-login');
              }}
            >
              JÁ TENHO CONTA
            </DefaultButton>
            <p>
              Ainda não é cadastrado? <Link to="/signUp">Crie sua conta.</Link>
            </p>
          </OptionsContent>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default Initial;
