import Course from '../models/course';

const checkIfContentsIsLocked = (course: Course) => {
  if (course.modules && course.modules.length) {
    const allLessons = course.modules
      .map(module => module.lessons || [])
      .flat();

    let index = 0;
    for (let lesson of allLessons) {
      if (index === 0) {
        lesson.isLocked = false;
      } else {
        const previousLessons = [...allLessons].splice(0, index);

        if (
          !lesson.alreadyFinished &&
          (!previousLessons.length ||
            previousLessons.some(
              previousLesson => !previousLesson.alreadyFinished,
            ))
        ) {
          lesson.isLocked = true;
        }
      }

      index++;
    }
  }
};

export default checkIfContentsIsLocked;
