import styled from 'styled-components';

export const DefaultInput = styled.input`
  background: var(--default-gray);
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
  font-size: var(--font-small);
  width: 400px;
  height: 40px;
  padding: 0 10px;
  color: var(--default-font-color);
  margin-top: 8px;
`;

const DefaultCreationForm = styled.form`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  & .react-datepicker-wrapper {
    flex-grow: 1;
  }
`;

const DefaultCreationFormGroup = styled.div`
  & + div {
    margin-top: 16px;
  }

  button {
    height: 35px;

    margin-top: 8px;
  }
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Cert = styled.div`
 

`;
const Container = styled.div`
  width: 100vw;

  @media (max-width: 768px) {
    footer {
      display: none;
    }
  }
`;

const Content = styled.div`
  width: 80vw;
  margin: 0 auto;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: 80vw;
    height: calc(100vh - 100px);
    flex-direction: column;
    margin-bottom: 40px;
    overflow: hidden;
  }
`;
const BrandContent = styled.div`
  width: 50%;
  height: 84%;

  img {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 36px;

    img {
      width: 100px;
      height: 100px;
      margin-bottom: -20px;
    }
  }
`;
const OptionsContent = styled.div`
  height: 85%;
  width: 50%;
  flex-direction: column;
  margin-left: 80px;
  margin-top: 50px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    margin-left: 0;
  }
`;

const Title = styled.h1`
  font-size: var(--font-title);
  color: var(--primary-color);
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
const Description = styled.h3``;

export {
  DefaultCreationForm,
  DefaultCreationFormGroup,
  Cert,
  Container,
  Content,
  BrandContent,
  OptionsContent,
  Title,
  Description,
};
