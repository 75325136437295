import httpClient from '../../http-client';
import { uploadFile } from '../files';

const changeAvatar = async (data: any) => {
  const uploadedFile = await uploadFile(data);
  await httpClient.patch(`/users/avatar`, { avatar: uploadedFile.reference });

  return uploadedFile.reference;
};

export { changeAvatar };
