import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

import logoImg from 'assets/logo.svg';

import { Div, LogoutButton, MenuContainer } from './style';

interface MenuProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const Menu: React.FC<MenuProps> = ({ isOpen, setIsOpen }) => {
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState('none');
  const [showProfileData, setShowProfileData] = useState('flex');

  const history = useHistory();

  function isCloseMenuMobile() {
    setIsOpen(false);
  }

  function handleIsOpenProfileMenu() {
    setIsOpenProfileMenu('none');
    setShowProfileData('flex');
  }

  function handleShowProfileData() {
    setShowProfileData('none');
    setIsOpenProfileMenu('flex');
  }

  const logout = () => {
    window.localStorage.removeItem('dorconsultoria-api-token');
    window.localStorage.removeItem('dorconsultoria-user');
    history.push('/');
    document.location.reload();
  };

  return (
    <>
      {isOpen === true ? (
        <MenuContainer display={showProfileData}>
          <img
            className="logoMobile"
            src={logoImg}
            alt="Logo D'or consultoria"
          />

          <Div display={isOpenProfileMenu}>
            <span onClick={() => handleIsOpenProfileMenu()}>
              <AiOutlineLeft size={16} /> Perfil
            </span>

            <Link
              to={{
                pathname: '/profile',
                state: {
                  menuData: true,
                },
              }}
            >
              <span className="data" onClick={() => isCloseMenuMobile()}>
                Meus dados <AiOutlineRight size={16} />
              </span>
            </Link>
          </Div>

          {isOpenProfileMenu === 'none' && (
            <Link to="/home">
              <span onClick={() => isCloseMenuMobile()}>
                Início <AiOutlineRight size={16} />
              </span>
            </Link>
          )}

          <Link to="/courses">
            <span onClick={() => isCloseMenuMobile()}>
              Cursos <AiOutlineRight size={16} />
            </span>
          </Link>

          <Link to="/trails">
            <span onClick={() => isCloseMenuMobile()}>
              Trilhas <AiOutlineRight size={16} />
            </span>
          </Link>

          <span className="display" onClick={() => handleShowProfileData()}>
            Perfil <AiOutlineRight size={16} />
          </span>

          <LogoutButton type="button" onClick={logout}>
            <FiLogOut size={16} color="#fff" /> Sair
          </LogoutButton>
        </MenuContainer>
      ) : (
        ''
      )}
    </>
  );
};

export default Menu;
