import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/Buttons/BackButton';
import DefaultButton from 'components/Buttons/DefaultButton';

import { Container, SelectTypeButton } from './style';

import HeaderLogo from 'assets/logo.svg';

const PreLogin: React.FC = () => {
  const [selectedType, setSelectedType] = useState<string>('');
  const history = useHistory();

  const handleLoginTypeAccount = () => {
    switch (selectedType) {
      case 'fisica':
        localStorage.setItem('dorconsultoria-isSSO', JSON.stringify(false));
        history.push('/signIn', {
          isSSO: false,
        });
        break;

      case 'juridica':
        localStorage.setItem('dorconsultoria-isSSO', JSON.stringify(true));
        window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`;
        break;

      default:
        break;
    }
  };

  return (
    <Container>
      <div className="return-btn">
        <BackButton />
      </div>
      <img src={HeaderLogo} alt="logo" />

      <h3>Qual o tipo de sua conta?</h3>

      <p>Antes de fazer seu login, selecione o seu tipo de sua conta.</p>

      <div className="container-btn">
        <SelectTypeButton
          onClick={() => setSelectedType('fisica')}
          style={{
            border:
              selectedType === 'fisica'
                ? '3px solid var(--secondary-color)'
                : '1px solid var(--secondary-color)',
          }}
        >
          Convidado
        </SelectTypeButton>

        <SelectTypeButton
          onClick={() => setSelectedType('juridica')}
          style={{
            border:
              selectedType === 'juridica'
                ? '3px solid var(--secondary-color)'
                : '1px solid var(--secondary-color)',
          }}
        >
          The D’Ors
        </SelectTypeButton>
      </div>

      <DefaultButton
        disabled={selectedType === ''}
        onClick={handleLoginTypeAccount}
      >
        continuar
      </DefaultButton>
    </Container>
  );
};

export default PreLogin;
