import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  margin: 58px 0 auto;

  > div {
    h1 {
      display: none;
      font-size: var(--font-large-medium);
      margin-bottom: 20px;

      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  div.shimmer {
    margin-top: 32px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 980px;
    padding-right: 84px;
    margin-bottom: 30px;

    h2 {
      font-size: 32px;
    }

    div.dropdown {
      display: none;
    }

    @media (max-width: 768px) {
      margin-top: 42px;

      h2 {
        display: none;
      }

      aside {
        display: none;
      }

      > div {
        width: 100%;
      }

      div.dropdown {
        display: block;
      }
    }
  }
`;

export const FavoritesList = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 32px 30px 25px 0;
  }

  @media (max-width: 768px) {
    column-gap: 32px;
    justify-content: center;
    max-width: 320px;

    > div {
      margin: 0 0 25px 0;
      width: 100%;
      max-width: 320px;

      h4 {
        font-size: var(--font-large);
      }

      div:last-child {
        align-items: center;

        span {
          margin-top: 2px;
          font-size: var(--font-regular);
        }

        svg {
          margin-right: 6px;
          width: 20px;
          height: 20px;
        }

        button {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  select {
    cursor: pointer;
    background-color: #e6e6e6;
    padding: 12px;
    width: 250px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    appearance: none;
    position: relative;
    outline: none;

    -moz-appearance: none;
    -webkit-appearance: none;

    &:-ms-expand {
      display: none;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    right: 12px;
  }
  @media (max-width: 768px) {
    svg {
      right: 0;
      left: 220px;
    }
  }
`;
export const Div = styled.div`
  width: 100%;
  display: none;
  align-items: left;

  @media (max-width: 768px) {
    display: flex;
  }
`;
