import styled from 'styled-components';

export const Container = styled.div`
  width: 440px;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 140px;
    height: 140px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .disappear {
    visibility: hidden;
    position: absolute;
  }

  div.confirm-data {
    h4 {
      font-weight: 100;
      margin-right: 8px;
      font-size: var(--font-medium);
    }

    div.element {
      display: flex;
      margin-bottom: 8px;

      span {
        margin-top: 3px;
        font-size: var(--font-min);
        color: var(--secondary-color);
        align-self: center;
        cursor: pointer;
      }
    }

    h2 {
      font-size: var(--font-large);
      font-weight: 200;
      color: #657787;
    }
  }

  div.return-btn {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    padding-left: 20px;
    margin-top: -20px;
  }

  div.divider {
    display: none;
    width: 100%;
    height: 1px;
    background: #f5f8fa;
    margin: 18px 0;
  }

  h3 {
    color: var(--secondary-color);
    font-size: var(--font-larger);
  }

  form {
    width: 100%;
    margin: 52px 0px;

    div.step-count {
      display: none;
    }

    > div {
      margin-bottom: 14px;
    }

    button[type='submit'] {
      margin-top: 40px;
    }
  }

  @media (max-width: 768px) {
    div.return-btn {
      margin-top: 30px;
      margin-left: -20px;
    }

    div.divider {
      display: initial;
    }

    img {
      display: none;
    }

    h3 {
      margin-top: -25px;
      font-size: 24px;
    }

    form {
      width: 70%;
      margin-top: 10px;

      div.step-count {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-size: var(--font-large);
        color: var(--secondary-color);
        font-weight: 800;
      }

      a {
        text-decoration: none;
        font-size: var(--font-small);
        font-weight: 400;
        color: var(--regular-gray);
      }

      label {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1440px) {
    div.return-btn {
      margin-top: 40px;
    }
  }

  @media (min-width: 1440px) {
    padding-top: 68px;

    h3 {
      margin-top: 35px;
    }

    form {
      margin-top: 36px;
    }
  }
`;
