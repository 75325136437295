import styled from 'styled-components';

const CourseExamContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 12px -4px;
`;

const ExamTitle = styled.div`
  font-size: 2em;
  font-weight: bold;
`;

const ExamInstructions = styled.div`
  font-size: 1em;
  color: var(--default-dark-gray);
`;

const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const QuestionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 5px;
  padding: 25px 10px 10px;
  border-top: solid 1px var(--medium-gray);
`;

const QuestionNumber = styled.div`
  font-size: 1em;
  font-weight: bold;
`;

const QuestionAlternatives = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const QuestionEnunciated = styled.div`
  font-size: 1em;
  color: var(--default-dark-gray);
  margin-bottom: 15px;
`;

const QuestionAlternativeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 8px 5px;

  &.correct {
    background: #f0f3f6;
  }

  &.incorrect {
    background: #ffe2e8;
  }

  & input[type='radio'] {
    margin-right: 8px;
    cursor: pointer;
    min-height: 23px;
    min-width: 20px;

    &:checked::after {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      top: 10px;
      left: 10px;
      transform: translate(-50%, -50%);
      position: relative;
      background-color: #0054A6;
      content: '';
      display: inline-block;
      visibility: visible;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  & label {
    font-size: 1em;
    color: var(--default-dark-gray);
  }
`;

const ExamSubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
`;

const ExamSubmitButton = styled.button`
  background: #0054A6;
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 15px;
  opacity: 0.9;
  transition: all 0.3s;

  :hover {
    opacity: 1;
  }
`;

const LoadingContainer = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExamResultContainer = styled.div`
  background: var(--course-result-color);
  padding: 0 16px;

  h3 {
    font-size: 1.6em;
    margin-bottom: 8px;
  }

  p {
    font-size: 1.2em;
  }
`;

const ExamResultActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  button {
    background: none;
    border: none;
    outline: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  button.go-next {
    background: transparent;
    color: #0054A6;
    transition: all 0.3s;
    font-size: 16px;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  button.restart {
    background: #0054A6;
    font-size: 16px;
    color: white;

    &:disabled {
      background: #cdcdcd;
    }
  }
`;

const QuestionNumberAndIsCorrectTag = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const IsCorrectTag = styled.span`
  padding: 8px;
  border-radius: 15px;
  color: white;

  &.correct {
    background: var(--default-dark-gray);
  }

  &.incorrect {
    background: var(--danger-color);
  }
`;

const CourseTitle = styled.h4`
  color: #0054A6;
  font-size: 1em;
`;

export {
  CourseExamContainer,
  ExamInstructions,
  QuestionList,
  ExamTitle,
  QuestionCardContainer,
  QuestionAlternatives,
  QuestionNumber,
  QuestionEnunciated,
  QuestionAlternativeContainer,
  ExamSubmitButton,
  ExamSubmitButtonContainer,
  LoadingContainer,
  ExamResultContainer,
  ExamResultActions,
  QuestionNumberAndIsCorrectTag,
  IsCorrectTag,
  CourseTitle,
};
