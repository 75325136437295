import styled from 'styled-components';

import DefaultButton from '../../components/Buttons/DefaultButton';

export const ContainerTerms = styled.div`
  width: 100%;

  span {
    margin-top: 20px;
    justify-content: center;
  }
  svg {
    margin-top: 20px;
  }
`;

export const ContentTerms = styled.div`
  width: 80%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }

  @media (max-width: 915px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const StyledTitle = styled.div`
  width: 892px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  position: relative;

  h1 {
    font-size: 22px;
    color: var(--dark-gray);
  }

  button {
    position: absolute;
    right: 0;
    padding-left: 200px;
    color: var(--primary-color);
    cursor: pointer;
    margin-bottom: 5px;
    display:none;
    border: 0;
    background: transparent;
    svg {
      margin-right: 10px;
    }
  }

  @media (max-width: 915px) {
    flex-direction:column;
    align-items:center;
    width:100%;
    h1 {
      width: 100%;
      font-size: 23px;
      text-align:center;
    }
    button{
      position:relative;
      padding-left:0px;
    }
    a {
      width: 100%;
      padding: 0;
    }
  }
  @media (max-width: 360px) {
    h1{
      font-size:19px;
    }
  }
`;

export const StyledTerms = styled.section`
  overflow-y: scroll;
  width: 892px;
  max-height: 380px;
  background: var(--light-gray);
  border-radius: 4px;
  padding: 16px;

  &::-webkit-scrollbar {
    width: 18px;
    border: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color);
  }

  h4,
  p {
    width: 80%;
    margin: 0 auto;
    text-align: left;
  }

  h4 {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  @media (max-width: 915px) {
    width: 100%;
    margin: 0 auto;

    &::-webkit-scrollbar {
      width: 10px;
      border: 0;
    }
  }
`;

export const StyledPrivacy = styled(StyledTerms)``;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 892px;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 915px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ButtonNotAcept = styled(DefaultButton)`
  color: var(--primary-color);
  background: var(--backgroundWhite);
  border: 1px solid var(--primary-color);
`;
