import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  background: #f2f2f2;
  text-align: center;
  padding: 96px;

  h1 {
    margin-bottom: 8px;
  }

  a {
    text-decoration: none;
    color: var(--secondary-color);
    font-size: var(--font-largest);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    padding: 32px 0px;

    h1,
    a {
      font-size: var(--font-regular);
    }
  }
`;
