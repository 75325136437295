import React from 'react';

import Skeleton from '../../../../../components/Skeleton';

import { Container } from './style';

const Shimmer: React.FC = () => {
  return (
    <Container>
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Container>
  );
};

export default Shimmer;
