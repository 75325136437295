import TagCategory from '../models/tagsCategory';
import TagCategoryFromResponse from '../models/from-api/tagCategory';

const convertCategory = (category: TagCategoryFromResponse): TagCategory => {
  return {
    id: category.tag_category_id,
    title: category.title,
    description: category.description,
    info: {
      color: category.info.color,
    },
    createdAt:
      category.created_at && typeof category.created_at === 'string'
        ? new Date(category.created_at)
        : new Date(),
  };
};

export default convertCategory;
