import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin-bottom: 20px;
  
  @media (max-width: 580px) {
    justify-content: left;
    align-items: center;
    border: 1px solid var(--default-gray);
    border-radius: 6px;
   
   
  }
`;

export const CertificateImage = styled.div`
  width: 210px;
  height: 200px;
  background: var(--default-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  

  @media (max-width: 580px) {
    justify-content: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-top: 12px;
    background: transparent;

    img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const Content = styled.div`
  margin: 16px 0 0 20px;

  h1 {
    font-size: 24px;
  }

  p {
    font-size: var(--font-default);
    color: var(--dark-gray);
    margin: 8px 0 36px;
  }

  > div {
    margin-top: 16px;
    min-width: 164px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-regular);
      height: 33px;
    }
  }

  @media (max-width: 580px) {
    margin: 12px 0 0 8px;

    h1 {
      font-size: 18px;
    }

    p {
      font-size: var(--font-min);
      margin-bottom: 24px;
    }

   > div{
       width: 100%;
      button{
        width:296px;
        
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: initial;
  gap: 5px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    height: 15px !important;
    cursor: pointer;
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid;
    border-color: rgba(0, 0, 0, 0.15);

    background: none;
    padding-right: 10px;
    font-size: var(--font-default);
    color: var(--primary-color);
    margin-bottom: 20px;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      text-decoration: underline;
    }

    &:nth-child(3) {
      border: none;
    }

    & + button {
      padding-left: 5px;
    }
  }

  span {
    width: 1px;
    height: 18px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 12px 0 8px;
  }

  @media (max-width: 580px) {
   width:80%;
 
  }
`;
