import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getTrailsCategories } from 'services/category';
import { Container } from './style';
import { useLocation } from 'react-router-dom';
import { listTagsCategories } from 'services/TagsCategories';
import TagCategory from 'models/tagsCategory';

interface MenuCategoriesMobileProps {
  dropdown: boolean;
  onClick: (categoryId: string) => void;
  selectedCategory: string;
  onClose: () => void;
}

const MenuCategoriesMobile = ({
  dropdown,
  onClick,
  selectedCategory,
  onClose,
}: MenuCategoriesMobileProps) => {
  const [categories, setCategories] = useState([] as TagCategory[]);

  const { pathname } = useLocation();

  const getCategories = async () => {
    if (pathname === '/trails') {
      const categories = await listTagsCategories({ is_active: true });
      if (categories && categories.length) {
        setCategories(categories);
      }
    } else {
      const categories = await getTrailsCategories();
      if (categories && categories.length) {
        setCategories(categories);
      }
    }
  };

  useEffect(() => {
    try {
      getCategories();
    } catch (e) {
      toast.error('Erro ao carregar as trilhas disponíveis.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <Container>
      {pathname === '/trails' ? (
        <h3>Todas as trilhas</h3>
      ) : (
        <h3>Todos as categorias</h3>
      )}

      {dropdown && (
        <>
          {categories &&
            categories.map(category => (
              <p
                onClick={() => {
                  onClick(category.id);
                  onClose();
                }}
                style={{
                  color:
                    selectedCategory === category.id
                      ? 'var(--primary-color)'
                      : '',
                }}
                key={category.id}
              >
                {category.title}
              </p>
            ))}
        </>
      )}
    </Container>
  );
};

export default MenuCategoriesMobile;
