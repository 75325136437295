import convertToExtraMaterial from '../../helpers/converters/convert-extra-materials';
import httpClient from '../../http-client';
import ExtraMaterial from '../../models/extraMaterials';
import ExtraMaterialFromResponse from '../../models/from-api/extraMaterials';

const getExtraMaterials = async (contentId: string) => {
  const response = (
    await httpClient.get<{ extra_material: ExtraMaterialFromResponse[] }>(
      `/extra-materials/${contentId}`,
    )
  ).data;

  let convertedExtraMaterials = [] as ExtraMaterial[];
  if (response && response.extra_material && response.extra_material.length) {
    convertedExtraMaterials = response.extra_material.map(
      convertToExtraMaterial,
    );
  }

  return convertedExtraMaterials;
};

export { getExtraMaterials };
