import { Link } from 'react-router-dom';

import { Container } from './style';

const FooterWhiteMobile = () => {
  return (
    <Container>
      <div>
        <Link to="/faq">FAQ</Link>

        <Link to="/faq">Fale Conosco</Link>
      </div>

      <span>
        © D'Or Consultoria, {new Date().getFullYear()}, todos os
        <br /> direitos reservados
      </span>
    </Container>
  );
};

export default FooterWhiteMobile;
