import React, { useRef, useState } from 'react';
import Textarea from '../../../../components/Inputs/Textarea';
import * as Yup from 'yup';

import {
  Container,
  Content,
  Title,
  ContentForm,
  InputGroup,
  Input,
  Button,
} from './style';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../../helpers/getValidationErrors';
import { sendEmail } from '../../../../services/faq';
import { toast } from 'react-toastify';
import { DefaultModal } from 'components/DefaultModal';
import DefaultButton from 'components/Buttons/DefaultButton';

const schema = Yup.object().shape({
  name: Yup.string().trim().required('Preencha corretamente este campo.'),
  email: Yup.string()
    .email()
    .trim()
    .required('Preencha corretamente este campo.'),
  text: Yup.string().trim().required('Preencha corretamente este campo.'),
});

interface IFormData {
  name: string;
  email: string;
  text: string;
}

const Form = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const formRef = useRef<FormHandles>(null);

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = async (data: IFormData, { reset }: any) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await sendEmail(data).then(() => {
        setIsOpen(true);
        reset();
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error('Não foi possível enviar o FAQ.');
      }
    }
  };

  return (
    <Container>
      <Content className="content">
        <div className="container-form" id="fale-conosco">
          <Title>Entre em contato através do formulário.</Title>
          <ContentForm onSubmit={handleSubmit} ref={formRef}>
            <InputGroup>
              <Input type="text" name="name" label="Nome" />
            </InputGroup>

            <InputGroup>
              <Input type="email" name="email" label="Email" />
            </InputGroup>

            <InputGroup>
              <Textarea type="text" name="text" label="Escreva a sua dúvida" />
            </InputGroup>

            <Button>Enviar</Button>
          </ContentForm>
        </div>
      </Content>
      <DefaultModal
        modalIsOpen={modalIsOpen}
        setIsOpen={() => setIsOpen(modalIsOpen)}
        closeModal={closeModal}
      >
        <h1>Seu formulário foi enviado com sucesso!</h1>
        <p>Te responderemos o mais rápido possível.</p>
        <DefaultButton onClick={closeModal}>OK, entendi</DefaultButton>
      </DefaultModal>
    </Container>
  );
};

export default Form;
