import React from 'react';
import CardItemSkeleton from '../../../../../components/CardItemSkeleton';

import { Container } from './style';

const ModalSkeleton: React.FC = () => {
  return (
    <Container>
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
      <CardItemSkeleton />
    </Container>
  );
};

export default ModalSkeleton;
