import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { LessonExtraMaterialContainer, ExtraMaterialList } from './styles';
import ExtraMaterial from 'models/extraMaterials';
import ExtraMaterialCard from './LessonExtraMaterialCard';
import { getExtraMaterials as getExtraMaterialsService } from 'services/course/extraMaterials';

interface SupportMaterialsParams {
  lessonId: string;
}

const LessonExtraMaterial: React.FC = () => {
  const { lessonId } = useParams<SupportMaterialsParams>();
  const [extraMaterials, setExtraMaterials] = useState([] as ExtraMaterial[]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getExtraMaterials = async () => {
    setExtraMaterials([]);
    const localExtraMaterials = await getExtraMaterialsService(lessonId);
    setExtraMaterials(localExtraMaterials);
  };

  useEffect(() => {
    getExtraMaterials();
  }, [lessonId]);

  return (
    <LessonExtraMaterialContainer>
      {extraMaterials && extraMaterials.length ? (
        <ExtraMaterialList>
          {extraMaterials && extraMaterials.length ? (
            extraMaterials.map(em => (
              <ExtraMaterialCard key={em.id} extraMaterial={em} />
            ))
          ) : (
            <></>
          )}
        </ExtraMaterialList>
      ) : (
        <strong>Esta aula não possui material de apoio</strong>
      )}
    </LessonExtraMaterialContainer>
  );
};

export default LessonExtraMaterial;
