import styled from 'styled-components';
import { Form } from '@unform/web';
import DefaultInput from '../../../../components/Inputs/DefaultInput/DefaultInput';

export const Container = styled.div`
  background: #f5f8fa;
  margin-top: 84px;
  padding: 32px;

  @media (max-width: 768px) {
    padding: 0 16px;

    div.content {
      width: 100% !important;
      padding: 0 !important;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 80px 0px;
  margin: 0 auto;

  div.container-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
  }

  @media (max-width: 992px) {
    div.container-form {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    div.container-form {
      width: 100%;
      padding: 0 24px 0 0;
    }
  }
`;

export const Title = styled.h2`
  font-size: var(--font-largest);
  color: var(--primary-color);
  font-weight: 700;
  max-width: 360px;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 36px;
    margin-left: 10px;
  }

  @media (max-width: 992px) {
    margin-bottom: 32px;
  }
`;

export const ContentForm = styled(Form)`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 82px 0 16px;

  @media (max-width: 992px) {
    margin-right: 0;
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  label {
    width: 100%;
    color: var(--gray-darker);
    margin-bottom: 0.5rem;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled(DefaultInput)`
  height: 48px;
  width: 100%;
  background-color: transparent !important;
  border: 1px solid var(--gray-darker);
  border-radius: 6px;

  &.textarea {
    height: 180px;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    background-color: #e1e3e6 !important;
    border: none;
  }
`;

export const Label = styled.label`
  width: 100%;
  color: var(--gray-darker);
  margin-bottom: 0.5rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  background-color: #e1e3e6 !important;
  border: 1px solid var(--gray-darker);
  border-radius: 6px;
  resize: none;
`;

export const Button = styled.button`
  font-weight: bold;
  font-size: var(--font-default);
  background-color: var(--secondary-color);
  border-radius: 4px;
  color: #fff;
  width: 130px;
  height: 48px;
  border: none;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--secondary-color-hover);
  }

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 40px;
  }
`;
