import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Course from 'pages/Course';
import FAQ from 'pages/FAQ';
import Initial from 'pages/Initial';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import ForgotPassword from 'pages/ForgotPassword';
import Courses from 'pages/Trails';
import Trail from 'pages/TrailsTagCategory';
import AuthLayout from 'components/Layouts/AuthLayout';
import DefaultLayout from 'components/Layouts/DefaultLayout';
import UserData from 'pages/Profile/Routes/UserData';
import OngoingCourses from 'pages/Profile/Routes/OngoingCourses';
import FavoriteCourses from 'pages/Profile/Routes/FavoriteCourses';
import Certificates from 'pages/Profile/Routes/Certificates';
import Search from 'pages/Search';
import VerifyCode from 'pages/VerifyCode';
import Terms from 'pages/Terms';
import Depositions from 'pages/Depositions';
import PreLogin from 'pages/PreLogin';
import CourseCategories from 'pages/Category';
import Exam from 'pages/Exam';
import Certificate from 'pages/Certificate';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={'/validar-certificado'} exact component={Certificate} />
      <Route path="/" exact component={Initial} />

      <Route
        path={[
          '/signIn',
          '/accept-terms',
          '/signUp',
          '/forgot-password',
          '/verify-code',
          '/faq',
          '/accept-terms',
          '/pre-login',
        ]}
      >
        <AuthLayout>
          <Route path="/signIn" component={SignIn} />
          <Route path="/accept-terms" component={Terms} />
          <Route path="/signUp" component={SignUp} />
          <Route path="/verify-code" component={VerifyCode} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/pre-login" exact component={PreLogin} />
        </AuthLayout>
      </Route>

      <DefaultLayout>
        <Route path="/home" component={Home} />
        <Route path="/profile" component={UserData} />
        <Route path="/ongoing-courses" component={OngoingCourses} />
        <Route path="/favorite-courses" component={FavoriteCourses} />
        <Route path="/certificates" component={Certificates} />
        <Route path="/depositions" component={Depositions} />
        <Route path="/categories" exact component={CourseCategories} />

        <Route path="/search" component={Search} />

        <Route path="/trails" exact component={Trail} />

        <Route path="/courses" exact component={Courses} />
        <Route path="/courses/:categoryId" exact component={Courses} />
        <Route path="/course/:courseId" exact component={Course} />
        <Route
          path="/course/:courseId/module/:moduleId/lesson/:lessonId"
          component={Course}
        />

        <Route path="/exam/:examId/course/:courseId" component={Exam} />
      </DefaultLayout>
    </Switch>
  );
};

export default Routes;
