import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { Container } from './style';

import DepositionCard from 'components/DepositionCard';
import SliderNextButton from 'components/SliderButtons/SliderNextButton';
import SliderPrevButton from 'components/SliderButtons/SliderPrevButton';

import { depositionsSliderSettings } from 'config/sliderSettings';

import Deposition from 'models/depositions';
import { getDepositions } from 'services/course';

const Depositions: React.FC = () => {
  const [depositions, setDepositions] = useState([] as Deposition[]);

  const getReviews = async () => {
    const filters = { show_to_all_users: true };
    const localDepositions = await getDepositions(filters);

    setDepositions(localDepositions);
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    depositions && depositions.length ? (
      <Container>
        <h2>Depoimentos</h2>

        <Slider
          {...depositionsSliderSettings}
          prevArrow={<SliderPrevButton />}
          nextArrow={<SliderNextButton />}
        >
          {depositions &&
            depositions.map((deposition: Deposition, index: number) => (
              <div key={index}>
                <DepositionCard
                  key={deposition.description}
                  deposition={deposition}
                />
              </div>
            ))
          }
        </Slider>
      </Container>
    )
    : (
      <Container style={ { display: "none" } } />
    )
  );
};

export default Depositions;
