import styled from 'styled-components';

export const Container = styled.footer`
  display: none;
  @media (max-width: 768px) {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    margin-top: 20px;
    > div {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 18px;
      a {
        font-weight: bold;
        font-size: 14px;
        color: var(--primary-color);
        text-decoration: none;
      }
    }

    span {
      color: var(--primary-color);
      text-align: center;
      font-weight: normal;
      font-size: 12px;
      margin-top: 20px;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
  }
`;
