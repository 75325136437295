import httpClient from '../../http-client';
import TagCategoryFromResponse from '../../models/from-api/tagCategory';
import TagCategory from '../../models/tagsCategory';
import convertTagCategory from '../../converters/convert-TagCategory';

const listTagsCategories = async (filters?: Object): Promise<TagCategory[]> => {
  let tagCategories = (
    await httpClient.get<TagCategoryFromResponse[]>('/tag-categories/', {
      params: filters,
    })
  ).data;
  let convertedCategories = [] as TagCategory[];
  convertedCategories = tagCategories.map(convertTagCategory);
  
  return convertedCategories;
};

const getContentsCategories = async (): Promise<TagCategory[]> => {
  let categories = (
    await httpClient.get<TagCategoryFromResponse[]>('/categories/attach-content')
  ).data;

  let convertedCategories = [] as TagCategory[];
  if (categories && categories.length) {
    categories = categories.filter(category => category.is_active);

    convertedCategories = categories.map(convertTagCategory);
  }

  return convertedCategories;
};

const getTrailsCategories = async (): Promise<TagCategory[]> => {
  let categories = (
    await httpClient.get<TagCategoryFromResponse[]>('/categories/attach-trail')
  ).data;

  let convertedCategories = [] as TagCategory[];
  if (categories && categories.length) {
    categories = categories.filter(category => category.is_active);

    convertedCategories = categories.map(convertTagCategory);
  }

  return convertedCategories;
};

const getCategory = async (categoryId: string) => {
  const category = (await httpClient.get(`/categories/${categoryId}`)).data;

  return convertTagCategory(category);
};

export {
  listTagsCategories,
  getCategory,
  getContentsCategories,
  getTrailsCategories,
};
