import styled from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  margin: 48px 0 auto;
`;

export const SearchResultTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;

  svg {
    width: 85px;
    height: 85px;
    margin-right: 72px;
  }

  p {
    font-weight: 500;
    font-size: var(--font-large);
  }

  span {
    font-weight: 700;
    color: var(--primary-color);
    margin: 0 4px;
  }

  @media (max-width: 768px) {
    svg {
      width: 42px;
      height: 72px;
      margin-right: 20px;
    }
  }
`;

export const SearchResultsList = styled.div`
  margin-top: 72px;
  display: flex;
  flex-wrap: wrap;

  > div {
    > div {
      margin-bottom: 48px;
      margin-right: 40px;
    }
  }

  div.list {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }
`;

export const ShimmerContent = styled.div`
  margin-top: 72px;
`;

export const SubtitleContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 768px) {
    display: flex;
  }

  span {
    margin-top: 10px;
    font-size: var(--font-regular);
  }

  img {
    margin-top: 50px;
    width: 95px;
    height: 95px;
  }
`;
