import httpClient from '../../http-client';

interface ISendEmail {
  name: string;
  email: string;
  text: string;
}

const getQuestions = async () => {
  let questions = await httpClient.get('/faqs').then(response => response.data);

  return questions;
};

const sendEmail = async (data: ISendEmail) => {
  const sendEmailResponse = (await httpClient.post('/faqs/mail', data)).data;

  return sendEmailResponse;
};

export { getQuestions, sendEmail };
