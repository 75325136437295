import styled from 'styled-components';

const ExtraMaterialCardContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--light-gray);
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: #0054A6;

    & > * {
      color: white;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExtraMaterialCardName = styled.h3`
  font-size: 18px;
  flex-grow: 1;
`;

export { ExtraMaterialCardContainer, ExtraMaterialCardName };
