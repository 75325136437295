import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin: 0 32px 50px 0;

  .image {
    width: 210px;
    height: 200px;
    margin-bottom: 8px;
    margin-right: 16px;
  }

  .title {
    width: 260px;
    height: 32px;
    margin-bottom: 16px;
    border-radius: 2px;
  }

  .date {
    width: 180px;
    height: 20px;
    border-radius: 2px;
    margin: 8px 0 36px;
  }

  .buttons {
    width: 240px;
    height: 20px;
    border-radius: 2px;
  }
`;
