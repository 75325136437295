import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';

import Logo from '../../assets/DORAvatar.jpg';
import FooterWhiteMobile from './FooterWhiteMobile';

import { Container, Content } from './style';

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  const widthPage: Array<number> = useWindowSize();
  const widthMobile = widthPage[0] <= 768;

  return (
    <>
      {(pathname === '/' && widthMobile) ||
      (pathname === '/signIn' && widthMobile) ||
      (pathname === '/pre-login' && widthMobile) ||
      (pathname === '/signUp' && widthMobile) ||
      (pathname === '/verify-code' && widthMobile) ||
      (pathname === '/forgot-password' && widthMobile) ||
      (pathname === '/accept-terms' && widthMobile) ||
      (pathname === '/forgot-password/reset-password' && widthMobile) ? (
        <FooterWhiteMobile />
      ) : (
        <Container>
          <Content className="content">
            {pathname === '/' ||
            pathname === '/pre-login' ||
            pathname === '/signIn' ? (
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            ) : (
              <Link to="/home">
                <img src={Logo} alt="Logo" />
              </Link>
            )}

            <span>
              © D'Or Consultoria, {new Date().getFullYear()}, todos os direitos
              reservados
            </span>

            <div>
              <Link
                to="/faq"
                onClick={() => window.scrollTo(0, 0)}
                style={{ display: 'none' }}
              >
                FAQ
              </Link>

              <Link to="/faq#fale-conosco">Fale Conosco</Link>
            </div>
          </Content>
        </Container>
      )}
    </>
  );
};

export default Footer;
