import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

import Modal from 'react-modal';
import BackButton from 'components/Buttons/BackButton';

interface test {
  size: number;
}

export const LineColor = styled.div<test>`
  ${({ size }: test) => css`
  width: ${size}%;
  height: 3px;
  background-color: var(--secondary-color);
    }
  `}
`;

export const ModalWrapper = styled(Modal)`
  width: 40%;
  height: fit-content;
  margin: auto auto;
  position: absolute;
  inset: 40px;
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 4px;
  padding: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .stepDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 24px;
    height: 25px;
    p {
      color: var(--regular-gray);
    }
  }
  p {
    color: var(--regular-gray);
    margin-bottom: 16px;
  }
  .buttonsDiv {
    margin: 64px 0 0 0;
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 8px;
  }
  .inputDiv {
    margin-top: 32px;
    div {
      margin-bottom: 24px;
    }
    input {
      width: 100%;
    }
  }
  .cancelButton {
    background-color: white;
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
  }
  .lineBottom {
    margin: 0;
    background-color: var(--regular-gray);
    height: 1px;
    width: 90%;
    margin-bottom: 16px;
  }
  .blue {
    color: var(--secondary-color) !important;
  }
  @media (max-width: 1024px) {
    width: 60%;
    height: fit-content;
    inset: 10px;
    padding: 20px;
  }
  @media (max-width: 600px) {
    width: 80%;
    height: fit-content;
    .stepDiv {
      height: 38px;
    }
    h1 {
      font-size: 1.3em;
    }
    p {
      font-size: 0.8em;
    }
    .buttonsDiv {
      grid-template-columns: 1fr;
      margin-top: 32px;
    }
  }
`;

export const Container = styled.div`
  width: 100vw;
  display: flex;
  margin-top: 42px;
  h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  div.userDataInput {
    background: var(--light-gray);
  }
  div.readOnly {
    background: #f2f2f2;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    aside {
      display: none;
    }
    div.userDataInput {
      height: 44px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  h2 {
    font-size: var(--font-largest);
    color: var(--primary-color);
  }
  p {
    margin-top: 8px;
    font-size: 24px;
    color: var(--regular-gray);
  }
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    h2 {
      font-size: var(--font-largest);
      text-align: center;
      margin-top: 0;
    }
    p {
      max-width: 327px;
      font-size: var(--font-large);
      text-align: center;
      line-height: 30px;
      color: var(--gray-darker);
      font-weight: 300;
    }
  }
`;

export const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  div.dropdown {
    display: none;
  }
  @media (max-width: 768px) {
    div.dropdown {
      display: block;
    }
  }
`;

export const AvatarInput = styled.div`
  position: relative;
  width: 140px;
  margin-left: 48px;
  img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
  > svg {
    width: 140px;
    height: 140px;
  }
  label {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--primary-color);
    border: none;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    input {
      display: none;
    }
    &:hover {
      background: var(--primary-color-hover);
    }
  }
  @media (max-width: 992px) {
    align-self: center;
    margin: 38px 0 42px;
  }
`;

export const RecommendedCourses = styled.div`
  padding-bottom: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 40px;
  margin-top: 40px;
  > div {
    display: flex;
  }
  @media (max-width: 768px) {
    padding-bottom: 0px;
    margin-bottom: 24px;
    h3 {
      font-size: var(--font-default);
      margin-bottom: 16px;
    }
    > div {
      display: flex;
      overflow-y: hidden;
      padding-bottom: 24px;
      > div {
        min-width: 190px;
      }
    }
  }
`;

export const ChangeDataForm = styled(Form)`
  padding-bottom: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  > div {
    display: flex;
    align-items: flex-end;
  }
  div {
    min-width: 300px;
    & + div {
      margin-left: 97px;
    }
  }
  button {
    margin-top: 30px;
    max-width: 130px;
  }
  @media (max-width: 1148px) {
    > div {
      flex-direction: column;
      align-items: flex-start;
    }
    div + div {
      margin: 48px 0 0 0;
    }
    button {
      margin: 48px 0 0 0;
    }
  }
  @media (max-width: 992px) {
    div + div {
      margin-top: 16px;
    }
    button {
      margin-top: 32px;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    h3 {
      font-size: var(--font-large);
      margin-bottom: 16px;
    }
    > div {
      div {
        max-width: 350px;
        width: 100%;
      }
    }
    button {
      max-width: 350px;
      width: 100%;
      font-size: var(--font-default);
    }
  }
`;

export const ChangePasswordForm = styled(Form)``;

export const ChangePasswordContent = styled.div`
  margin: 32px 0 64px 0;
  h3 {
    margin-bottom: 20px;
  }
  p {
    color: var(--regular-gray);
    margin: 8px 0 32px 0;
  }
  button {
    max-width: 230px;
  }
  @media (max-width: 992px) {
    button {
      max-width: 70%;
    }
  }
  @media (max-width: 768px) {
    button {
      max-width: 100%;
    }
    p {
      color: var(--gray-darker);
    }
  }
`;

export const DataWrapper = styled.div`
  padding-bottom: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  > div {
    display: flex;
    align-items: flex-end;
  }
  div {
    min-width: 300px;
    & + div {
      margin-left: 97px;
    }
  }
  button {
    margin-top: 30px;
    max-width: 20%;
  }
  @media (max-width: 1148px) {
    > div {
      flex-direction: column;
      align-items: flex-start;
    }
    div + div {
      margin: 48px 0 0 0;
    }
    button {
      margin: 48px 0 0 0;
    }
  }
  @media (max-width: 768px) {
    button {
      max-width: 100%;
    }
  }
`;

export const ContentWrapper = styled.div`
  font-size: small;
  div {
    p {
      &:last-child {
        color: var(--gray-darker);
      }
    }
  }
  p {
    margin-bottom: 15px;
  }
`;

export const ContainerProfileMobile = styled.section`
  min-width: 250px;
  margin-top: 50px;
  margin-bottom: 50px;
  a {
    text-decoration: none;
    font-size: 20px;
    color: var(--dark-gray);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid #e1e3e6;

    &:last-child {
      border-bottom: none;
    }

    & + a {
      margin-top: 18px;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      margin-right: 16px;
      color: var(--primary-color);
    }
  }
`;

export const Button = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    background: transparent;
    border: none;
    color: var(--primary-color);
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    span {
      margin-left: 5px;
    }
  }
`;
