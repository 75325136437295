import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';

import HeaderLogo from 'assets/logo.svg';

import SearchInput from '../Inputs/SearchInput';
import Menu from '../Menu';

import ProfileDropdown from './components/ProfileDropdown';

import {
  HeaderContainer,
  HeaderContent,
  ProfileContainer,
  HeaderUtilities,
  CloseMenu,
  MenuContainerLinks,
  ContainerMobile,
} from './style';
import User from 'models/user';
import { FiMenu } from 'react-icons/fi';

const Header: React.FC = () => {
  const [user, setUser] = useState<User>(
    JSON.parse(localStorage.getItem('dorconsultoria-user') || 'null'),
  );

  const [menuMobileIsOpen, setMenuMobileIsOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const handleProfileMenuMobile = () => {
    const width = window.innerWidth;
    if (width <= 768) {
      history.push('/profile');
    }
  };

  function unloadScrollBars() {
    document.documentElement.style.overflow = 'hidden';
    setMenuMobileIsOpen(true);
  }

  function reloadScrollBars() {
    document.documentElement.style.overflow = 'auto';
    setMenuMobileIsOpen(false);
  }

  return (
    <>
      {menuMobileIsOpen && (
        <ContainerMobile>
          <Menu
            isOpen={menuMobileIsOpen}
            setIsOpen={() => reloadScrollBars()}
          />

          <CloseMenu
            onClick={() => {
              reloadScrollBars();
            }}
          >
            X
          </CloseMenu>
        </ContainerMobile>
      )}

      <HeaderContainer>
        <HeaderContent className="content">
          <Link to="/home" className="logo">
            <img className="logoImg" src={HeaderLogo} alt="logo" />
          </Link>

          {menuMobileIsOpen ? (
            ''
          ) : (
            <>
              <MenuContainerLinks>
                <Link
                  to="/home"
                  className={`
          ${!location.pathname || location.pathname === '/home' ? 'active' : ''}
        `}
                >
                  <span>Início</span>
                </Link>
                <Link
                  to="/categories"
                  className={`
          ${location.pathname === '/courses' ? 'active' : ''}
        `}
                >
                  <span>Cursos</span>
                </Link>
                <Link
                  to="/trails"
                  className={`
          ${location.pathname === '/trails' ? 'active' : ''}
        `}
                >
                  <span>Trilhas</span>
                </Link>
              </MenuContainerLinks>
            </>
          )}

          <HeaderUtilities>
            <SearchInput />
            <FiMenu
              className="menu-mobile"
              size={20}
              onClick={() => {
                setMenuMobileIsOpen(true);
                unloadScrollBars();
              }}
            />

            <ProfileContainer
              className={`${
                location.pathname === '/profile' ||
                location.pathname === '/favorite-courses' ||
                location.pathname === '/ongoing-courses' ||
                location.pathname === '/certificates'
                  ? 'active'
                  : ''
              }
              `}
            >
              <button onClick={handleProfileMenuMobile}>
                <span>Perfil</span>

                {user?.info.avatar && user.info.avatar !== '' ? (
                  <img src={user.info.avatar} alt="Avatar" className="avatar" />
                ) : (
                  <BiUserCircle color="#BDBDBD" />
                )}
              </button>

              <ProfileDropdown />
            </ProfileContainer>
          </HeaderUtilities>
        </HeaderContent>
      </HeaderContainer>
    </>
  );
};

export default Header;
