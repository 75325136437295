import React, { useContext, useEffect, useState } from 'react';
import Course from '../../../../models/course';
import {
  RecommendedCoursesContainer,
  RecommendedCoursesTitle,
  RecommendedCoursesCardContainer,
} from '../../../../pages/Course/components/RecommendedCourses/styles';
import { getCourses as getCoursesService } from '../../../../services/course';
import CourseContext from '../../Context';
import Slider from 'react-slick';
import SliderNextButton from '../../../../components/SliderButtons/SliderNextButton';
import SliderPrevButton from '../../../../components/SliderButtons/SliderPrevButton';
import TrailCard from 'components/TrailCard';
import { coursesSliderSettings } from 'config/sliderSettings';

const RecommendedCourses: React.FC = () => {
  const { course } = useContext(CourseContext);
  const [courses, setCourses] = useState([] as Course[]);

  const getCourses = async () => {
    const localCourses = await getCoursesService({
      category: course.categoryId,
    });

    if (
      localCourses &&
      localCourses.length &&
      localCourses.some(c => c.id === course.id)
    ) {
      const foundCourse = localCourses.find(c => c.id === course.id);
      if (foundCourse) {
        const indexOfCourse = localCourses.indexOf(foundCourse);
        if (indexOfCourse > -1) {
          localCourses.splice(indexOfCourse, 1);
        }
      }
    }

    setCourses(localCourses);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <RecommendedCoursesContainer>
      <RecommendedCoursesTitle>Cursos recomendados</RecommendedCoursesTitle>
      <RecommendedCoursesCardContainer>
        <Slider
          prevArrow={<SliderPrevButton />}
          nextArrow={<SliderNextButton />}
          className="slider"
          {...coursesSliderSettings}
        >
          {courses && courses.length ? (
            courses.map(course => <TrailCard key={course.id} trail={course} />)
          ) : (
            <></>
          )}
        </Slider>
      </RecommendedCoursesCardContainer>
    </RecommendedCoursesContainer>
  );
};

export default RecommendedCourses;
