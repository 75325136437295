import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { getDate, getYear, format, minutesToHours } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Background from 'pdf/assets/images/certi.jpg';
import { CertificateData } from 'services/certificate';
import { secondsToTimeText } from 'helpers/time';

const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  page: {},
  namePerson: {
    top: '314pt',
    left: '417pt',
    fontSize: '12pt',
  },
  dateDay: {
    top: '323.5pt',
    left: '234pt',
    fontSize: '12pt',
  },
  dateMonth: {
    top: '310pt',
    left: '274pt',
    fontSize: '12pt',
  },
  dateYear: {
    top: '299pt',
    left: '377pt',
    fontSize: '12pt',
  },
  courseName: {
    top: '284pt',
    left: '444pt',
    fontSize: '12pt',
  },
  duration: {
    top: '293pt',
    left: '487pt',
    fontSize: '12pt',
  },
  id: {
    top: '460pt',
    left: '50pt',
    fontSize: '8pt',
  },
});

interface CertificateTemplateProps {
  certificateData: CertificateData;
}

const CertificateTemplate: React.FC<CertificateTemplateProps> = ({
  certificateData,
}) => {
  // @ts-ignore

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Image src={Background} style={styles.pageBackground} />

        <View style={styles.namePerson}>
          <Text>{certificateData.user_name}</Text>
        </View>

        <View style={styles.dateDay}>
          <Text>{getDate(new Date(certificateData.emitted_at))}</Text>
        </View>

        <View style={styles.dateMonth}>
          <Text>
            {format(new Date(certificateData.emitted_at), 'MMMM', {
              locale: ptBR,
            })}
          </Text>
        </View>

        <View style={styles.dateYear}>
          <Text>
            {getYear(new Date(certificateData.emitted_at)).toString().charAt(3)}
          </Text>
        </View>

        <View style={styles.courseName}>
          <Text>{certificateData.reference_name}</Text>
        </View>

        <View style={styles.duration}>
          <Text>{secondsToTimeText(certificateData.reference_duration)}</Text>
        </View>
        <View style={styles.id}>
          <Text>{`Cod: ${certificateData.certificate_id}`}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CertificateTemplate;
